import { connect } from 'react-redux';
import SearchBar from '../../ui/components/SearchBar.js';
import * as search from '../actions/search.js'

const mapDispatchToProps = dispatch => ({
  search() {
    dispatch(search.search())
  }
})

const mapStateToProps = state => ({
  searching: state.searchbar.searching
})

const ConnectSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar)

export default ConnectSearch;
