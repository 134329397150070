import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Guest from '../components/guests/Guest.js';
import GuestsList from '../components/guests/GuestsList.js';
import "./Employees.css";


export default class Guests extends Component {
    render() {
        return (
            <Switch>
                <Route path="/admin/guests/:id" component={Guest} />
                <Route path="/admin/guests" component={GuestsList} />
            </Switch>
        );
    }
}
