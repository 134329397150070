import axios from 'axios';
import url from '../../url.js';

const requestProductsFailure = (message) => {
    return {
    type: 'REQUEST_PRODUCTS_FAILURE',
    message,
  }
};

const requestProductsSuccess = (products) => {
    return {
    type: 'REQUEST_PRODUCTS_SUCCESS',
    products,
  }
};

const requestProducts = () => {
  return {
    type: 'REQUEST_PRODUCTS',
  }
};

const fetchProducts = (token, search = '') => {
  console.log('asd');
  return dispatch => {
    dispatch(requestProducts())
    return axios.get(url + 'admin/products?search='+ search, {
      headers: {Authorization: token},
    }).then(response => {
      dispatch(requestProductsSuccess(response.data.products));
    }
      )
      .catch(error => {
        dispatch(requestProductsFailure(error.response.data));
      })
  }
}

export const getProducts = (searchString) => {
  return (dispatch, getState) => {
    return dispatch(fetchProducts(window.localStorage.getItem('token'), searchString));
  }
}

const requestProductFailure = (message) => {
    return {
    type: 'REQUEST_PRODUCT_FAILURE',
    message,
  }
};

const requestProductSuccess = (product) => {
  console.log('runs');
    return {
    type: 'REQUEST_PRODUCT_SUCCESS',
    product,
  }
};

const requestProduct = () => {
  return {
    type: 'REQUEST_PRODUCT',
  }
};

const fetchProduct = (token, id) => {
  console.log('asdsss');
  return dispatch => {
    dispatch(requestProduct())
    return axios.get(url + 'admin/products/'+ id, {
      headers: {Authorization: token},
    }).then(response => {
      console.log('asdsss');
      console.log(response);
      console.log('asd');
      dispatch(requestProductSuccess(response.data));
    }
      )
      .catch(error => {
        dispatch(requestProductFailure(error.response.data));
      })
  }
}

export const getProduct = (id) => {
  return (dispatch, getState) => {
    return dispatch(fetchProduct(window.localStorage.getItem('token'), id));
  }
}
