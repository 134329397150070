import axios from "axios"
import url from "../../url.js"

const requestReservationsFailure = message => {
    return {
        type: "REQUEST_RESERVATIONS_FAILURE",
        message,
    }
}

const requestReservationsSuccess = reservations => {
    return {
        type: "REQUEST_RESERVATIONS_SUCCESS",
        reservations,
    }
}

const requestReservations = () => {
    return {
        type: "REQUEST_RESERVATIONS",
    }
}

const fetchReservations = (token, day, month, year) => {
    console.log("fetching reservations...")

    return dispatch => {
        const fetchday = day ? "&day=" + day : ""
        dispatch(requestReservations())
        return axios
            .get(url + `admin/reservations?year=${year}&month=${month}${fetchday}`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(response => {
                dispatch(requestReservationsSuccess(response.data))
            })
            .catch(error => {
                dispatch(requestReservationsFailure(error.response.data))
            })
    }
}

export const getReservations = (day, month, year) => {
    if (day) {
        day = day.length < 2 ? "0" + day : day
    }
    if (month) {
        month = month.length < 2 ? "0" + month : month
    }
    return (dispatch, getState) => {
        return dispatch(fetchReservations(window.localStorage.getItem("token"), day, month, year))
    }
}

const requestReservationFailure = message => {
    return {
        type: "REQUEST_RESERVATION_FAILURE",
        message,
    }
}

const requestReservationSuccess = reservation => {
    return {
        type: "REQUEST_RESERVATION_SUCCESS",
        reservation,
    }
}

const requestReservation = () => {
    return {
        type: "REQUEST_RESERVATION",
    }
}

const fetchReservation = (token, id) => {
    return dispatch => {
        dispatch(requestReservation())
        return axios
            .get(url + "admin/reservations/" + id, {
                headers: {
                    Authorization: token,
                },
            })
            .then(response => {
                dispatch(requestReservationSuccess(response.data))
            })
            .catch(error => {
                dispatch(requestReservationFailure(error.response.data))
            })
    }
}

export const getReservation = id => {
    return dispatch => {
        return dispatch(fetchReservation(window.localStorage.getItem("token"), id))
    }
}
