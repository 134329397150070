import React, { Component }from 'react';
import { Route, Switch } from 'react-router-dom';
import Product from '../components/products/Product.js'
import ProductList from '../components/products/ProductList.js';
import './Products.css'


export default class Products extends Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/products/:id" component={Product} />
        <Route path="/admin/products" component={ProductList} />
      </Switch>
    );
  }
}
