import initialState from '../initialState.js';

const employees = (state = initialState.employees, action) => {
  switch(action.type) {
    case 'REQUEST_EMPLOYEES':
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
      })
    case 'REQUEST_EMPLOYEES_SUCCESS':
      return Object.assign({}, state, {
        data: action.employees,
        isFetching: false,
      })
    case 'REQUEST_EMPLOYEES_FAILURE':
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message,
        },
        isFetching: false,
      });
    case 'REQUEST_EMPLOYEE':
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
      })
    case 'REQUEST_EMPLOYEE_SUCCESS':
      return Object.assign({}, state, {
        info: action.employee,
        isFetching: false,
      })
    case 'REQUEST_EMPLOYEE_FAILURE':
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message,
        },
        isFetching: false,
      });
    default: return state;
  }
}

export default employees;
