import axios from 'axios';
import url from '../../url.js';

const requestGroupsFailure = (message) => {
    return {
    type: 'REQUEST_GROUPS_FAILURE',
    message,
  }
};

const requestGroupsSuccess = (groups) => {
    return {
    type: 'REQUEST_GROUPS_SUCCESS',
    groups,
  }
};

const requestGroups = () => {
  return {
    type: 'REQUEST_GROUPS',
  }
};

const fetchGroups = (token, search = "") => {
  return dispatch => {
    dispatch(requestGroups());
    return axios.get(url + 'admin/groups?search='+ search, {
      headers: {Authorization: token},
    }).then(response => {
      dispatch(requestGroupsSuccess(response.data.groups));
    }
      )
      .catch(error => {
        dispatch(requestGroupsFailure(error.response.data));
      })
  }
}

export const getGroups = (searchString = "") => {
  return (dispatch, getState) => {
    return dispatch(fetchGroups(window.localStorage.getItem('token'), searchString));
  }
}

const requestGroupFailure = (message) => {
    return {
    type: 'REQUEST_GROUP_FAILURE',
    message,
  }
};

const requestGroupSuccess = (group) => {
    return {
    type: 'REQUEST_GROUP_SUCCESS',
    group,
  }
};

const requestGroup = () => {
  return {
    type: 'REQUEST_GROUP',
  }
};

const fetchGroup = (token, id) => {
  console.log("fetching group");
  return dispatch => {
    dispatch(requestGroup())
    return axios.get(url + 'admin/groups/'+ id, {
      headers: {Authorization: token},
    }).then(response => {
      dispatch(requestGroupSuccess(response.data));
    }
      )
      .catch(error => {
        dispatch(requestGroupFailure(error.response.data));
      })
  }
}

export const getGroup = (id) => {
  return (dispatch, getState) => {
    return dispatch(fetchGroup(window.localStorage.getItem('token'), id));
  }
}