import React from 'react';
import { Component } from 'react';

export default class ButtonSelectAll extends Component {
  constructor() {
    super();
    this.state = {isToggled: !true};
    this.handleClick.bind(this);
  }

  async handleClick() {
    let items;
    try {
      items = await document.querySelectorAll('.list-item');
    } catch(err) {
      throw err;
    }
    for (let i = 0; i < items.length; i++) items[i].checked = (document.querySelector('#listCheckAll').checked);
  }

  render() {
    return(
      <label htmlFor="listCheckAll">
        <input id="listCheckAll" onClick={this.handleClick} type="checkbox"></input>
        <span className="checkmark"></span>
        <span>Velg alle</span>
      </label>
    )
  }

}
