import React, { Component } from "react";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

export default class Mail extends Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
    this.toolbar = React.createRef();
  }

  messageChange(html, text) {
    this.props.messageChange({
      bodyHTML: html,
      bodyText: text,
      subject: this.props.subject
    });
  }

  subjectChange(event) {
    event.preventDefault();
    this.props.subjectChange(event.target.value);
  }

  componentDidMount() {
    this.editor = new Quill(this.editor.current, {
      modules: { toolbar: this.toolbar.current },
      theme: "snow"
    });
    this.editor.root.innerHTML = this.props.message;
    this.editor.on(
      "text-change",
      ((delta, oldDelta, source) => {
        this.messageChange(this.editor.root.innerHTML, this.editor.getText(0));
      }).bind(this)
    );
  }

  componentDidUpdate(oldProps, oldState) {
    this.editor.enable(this.props.edit);
    if (this.props.message !== oldProps.message && !this.props.edit) {
      this.editor.root.innerHTML = this.props.message;
    }
  }

  render() {
    return (
      <div>
        <p>Emnefelt:</p>
        <input
          disabled={!this.props.edit}
          type="text"
          onChange={this.subjectChange.bind(this)}
          value={this.props.subject}
        />
        <p>Mailtekst:</p>
        <div style={!this.props.edit ? { display: "none" } : {}}>
          <div ref={this.toolbar}>
            <button className="ql-bold">Bold</button>
            <button className="ql-italic">Italic</button>
          </div>
        </div>
        <div ref={this.editor} />
      </div>
    );
  }
}
