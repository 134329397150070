import initialState from '../initialState.js';

const products = (state = initialState.products, action) => {
  switch(action.type) {
    case 'REQUEST_PRODUCTS':
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
      })
    case 'REQUEST_PRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        data: action.products,
        isFetching: false,
      })
    case 'REQUEST_PRODUCTS_FAILURE':
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message,
        },
        isFetching: false,
      })
      case 'REQUEST_PRODUCT':
        return Object.assign({}, state, {
          isFetching: true,
          error: false,
        })
      case 'REQUEST_PRODUCT_SUCCESS':
        return Object.assign({}, state, {
          info: action.product,
          isFetching: false,
        })
      case 'REQUEST_PRODUCT_FAILURE':
        return Object.assign({}, state, {
          error: {
            active: true,
            message: action.message,
          },
          isFetching: false,
        });
    default: return state;
  }
}

export default products;
