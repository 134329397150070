import initialState from '../initialState.js';

const groups = (state = initialState.groups, action) => {
  switch(action.type) {
    case 'REQUEST_GROUPS':
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
      })
    case 'REQUEST_GROUPS_SUCCESS':
      return Object.assign({}, state, {
        data: action.groups,
        isFetching: false,
      })
    case 'REQUEST_GROUPS_FAILURE':
      return Object.assign({}, state, {
        error: { 
          active: true,
          message: action.message,
        },
        isFetching: false,
      });
    case 'REQUEST_GROUP':
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
      })
    case 'REQUEST_GROUP_SUCCESS':
      return Object.assign({}, state, {
        info: action.group,
        isFetching: false,
      })
    case 'REQUEST_GROUP_FAILURE':
      return Object.assign({}, state, {
        error: { 
          active: true,
          message: action.message,
        },
        isFetching: false,
      });
    default: return state;
  }
}

export default groups;