import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Benefit from "../components/benefits/Benefit.js";
import Benefits from "../components/benefits/Benefits.js";
import "./Benefits.css";

export default class BenefitsPage extends Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/benefits/:id" component={Benefit} />
        <Route path="/admin/benefits" component={Benefits} />
      </Switch>
    );
  }
}
