import React, { Component } from 'react'

export default class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.onKey = this.onKey.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  handleFocus() {
    this.refs.searchbar.placeholder = ''
  }
  handleBlur() {
    this.refs.searchbar.placeholder = 'Søk'
  }
  onKey(e) {
    if(e.key === 'Enter' || e.target.id === 'submit') {
      if (this.checkText(this.refs.searchbar.value)) {
        this.refs.searchbar.blur()
        this.props.search()
        console.log(
          window.location + '?search=' + encodeURI(this.refs.searchbar.value.trim())
        )
      }
    }
  }

  checkText(val) {
    return (/\S+/g.test(val))
  }

  handleClick() {
    if (this.checkText(this.refs.searchbar.value)) {
      this.refs.searchbar.blur()
      this.props.search()
      console.log(
        window.location + '?search=' + encodeURI(this.refs.searchbar.value.trim())
      )
    }
  }

  render() {
    let { handleBlur, handleFocus, handleClick, onKey } = this
    return(
      <div id="search">
        <i className="material-icons">search</i>
        <input
          ref="searchbar"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={onKey}
          id="searchbar"
          type="text/plain"
          placeholder="Søk">
        </input>
        <button
          id="submit"
          onClick={handleClick}>
          Søk
        </button>
        {this.props.searching ? <p id="searching">
          <svg width="116px" height="94px" viewBox="0 0 116 94" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs></defs>
            <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Bordbestilling" transform="translate(-1050.000000, -681.000000)" stroke="currentcolor" stroke-width="12">
                <path d="M1148.55823,770.638674 C1155.50539,761.84196 1159.65181,750.731112 1159.65181,738.651815 C1159.65181,710.125305 1136.52651,687 1108,687 C1079.47349,687 1056.34819,710.125305 1056.34819,738.651815" id="Oval-3"></path>
              </g>
            </g>
          </svg>
        </p> : null}
      </div>
    )
  }
}
