import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../../store/actions/groups';
import {push} from "react-router-redux";
import url from "../../../url";
import FileUploader from "./fileUploader";

class GroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            change: {
                name: '',
                description: '',
            }
        };
        this.onClick = this.onClick.bind(this);
        this.add = this.add.bind(this);
        this.newGroup = this.newGroup.bind(this);
    }
    componentDidMount() {
        this.props.onMount();
    }

    onClick(id, e) {
        e.preventDefault();
        //console.log(id);
        this.props.onGroupClick(id)
    }

    renderGroups() {
        return this.props.groups.map((group) => {
            //console.log(group);
            return (
                <tr value={group._id} key={group._id} onClick={this.onClick.bind(null, group._id)}>
                    <td>{group.name}</td>
                    <td>{group.totalMembers}</td>
                    <td>{group.contactperson}</td>
                    <td>{group.contactnumber}</td>
                    <td>{group.description}</td>
                </tr>
                )
        })
    }

    renderTable() {
        return (
            <table>
                <thead>
                <tr>
                    <th>Navn</th>
                    <th>Medlemmer</th>
                    <th colSpan="2">Kontaktperson</th>
                    <th>Beskrivelse</th>
                </tr>
                </thead>
                <tbody>
                {this.renderGroups()}
                </tbody>
            </table>
        );
    }

    changeValue(key, e) {
        e.preventDefault();
        if(this.state.add) {
            const current = this.state.change;
            current[key] = e.target.value;
            this.setState({change: current});
        }
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    newGroup(e) {
        e.preventDefault();
        if(this.state.change.name.length > 0 &&
            this.state.change.description.length > 0) {

            const info = {
                name: this.state.change.name,
                description: this.state.change.description
            };
            this.setState({fetching: true});
            const token = window.localStorage.getItem('token');
            const changes = this.state.change;
            delete changes._id;
            axios.post(url + 'admin/groups', info, {
                headers: {Authorization: token, "Content-Type": "application/json"},
            }).then(response => {
                this.setState({
                    change: {
                        name: '',
                        description: ''
                    },
                    add: false,
                });
                this.props.onMount();

            })
                .catch(error => {
                    this.setState({error: true, fetching: false})
                })
        }
    }


    disabled(bool) {
        if(bool) {
            return {
                background: '#757575',
            }
        } else {
            {}
        }
    }

    checkChange() {
      if(this.state.change.name.length < 1 ||
       this.state.change.description.length < 1) {
        return true;
      } else {
        return false;
      }
    }

    newForm() {
        if(this.state.add) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2>Ny gruppe</h2>
                        <div>
                            <form onSubmit={this.newGroup} >
                                <input placeholder="Gruppenavn" onChange={this.changeValue.bind(this, 'name')} type="text" className="add-inputF"  value={this.state.change.name} required/>
                                <textarea placeholder="Beskrivelse" onChange={this.changeValue.bind(this, 'description')} type="text" className="add-inputF"  value={this.state.change.description} required/>
                            </form>
                            <div style={styles.delCont}>
                                <button onClick={(e) =>{ e.preventDefault(); this.setState({add: false})} } style={styles.button} className="change">Avbryt</button>
                                <button disabled={this.checkChange()} onClick={this.newGroup} style={{...styles.button, ...this.disabled(this.checkChange())}} className="save">Legg til</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return '';
    }

    render() {
        return (
            <div>
                <h1>Grupper</h1>
                {this.props.access && this.props.access.groups.write ?
                (<button onClick={this.add} style={{...styles.button, ...{marginLeft: '0px'}}} className="save">Legg til ny</button>) : ("")    
                }{this.newForm()}
                <div style={{overflowX: 'auto'}}>
                    {this.props.loading ? <p>Loading</p> : this.renderTable()}
                </div>
            </div>
        );
    }
}

const styles = {
    button: {
        width: '120px',
        marginBottom: '20px',
        marginTop: '20px',
        marginRight: '10px',
        marginLeft: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        fontSize: '18px',
        borderRadius: '8px',

    },
    del: {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#00000070',
        top: 0,
        left: 0,
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    delCard: {
        flex: '',
        height: '400px',
        width: '90%',
        maxWidth: '400px',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '5px 5px 5px 5px',
        textAlign: 'center',
    },
    delCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%'
    }
};

const mapStateToProps = (state) => {
    return {
        groups: state.groups.data,
        error: state.groups.error.active,
        access: state.login.user.info.permissions,
        loading: state.groups.isFetching,
        message: state.groups.error.message,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(actions.getGroups());
        },
        onGroupClick: (id) => {
            dispatch(push(`/admin/groups/${id}`));
        }
    }
};

const GroupsContainer = connect(
    mapStateToProps,
    mapDispatchToProps)(GroupList);


export default GroupsContainer;
