import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { logOut } from '../actions/login.js';
import NavBar from '../../ui/components/NavBar.js';

const mapStateToProps = state => ({
  path: state.router.location.pathname,
  access: state.login.user.info.permissions,
})

const mapDispatchToProps = dispatch => ({
  changeUrl(path) {
    dispatch(push('/admin' + path))
  },
  logOut() {
    dispatch(push('/'))
    dispatch(logOut());
    window.localStorage.removeItem('token');
  }
})

const Navigation = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar)

export default Navigation;
