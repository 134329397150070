import moment from 'moment'
moment.locale('nb')

export default class Months {

  constructor(val) {
    if(val > 0 || val < 0) {
      this.val = moment().add(val, 'month');
    } else {
      this.val = moment().get('month');
    }
    return {
      name: moment().add(val, 'month').format('MMMM'),
      abbrivation: moment().add(val, 'month').format('MMM'),
      year: parseInt(moment().add(val, 'month').format('YYYY'), 10),
      monthIndex: parseInt(moment().add(val, 'month').format('M'), 10),
      start: moment().add(val, 'month').date(1).format('dddd'),
      days: this.dayCalc(val),
      today: parseInt(moment().add(val, 'month').format('DD'), 10),
      startIndex: parseInt(moment().add(val, 'month').date(1).format('M'), 10)
    }
  }

  dayCalc(date) {
    let a = []
    for (let j = 1, days = moment().add(date, 'month').daysInMonth(); j <= days; j++) {
      a.push({ name: j });
    }
    return a
  }

}
