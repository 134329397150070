/* eslint-disable react/prop-types,no-console */
import React, { Component } from "react"

export default class DefaultList extends Component {
    render() {
        return (
            <div style={{ overflowX: "auto", borderRadius: "8px" }}>
                <table className={this.props.height ? "tableBodyScroll" : ""}>
                    {this._renderHeader()}
                    {this._renderBody()}
                </table>
            </div>
        )
    }

    _renderHeader() {
        return (
            <thead>
                <tr>
                    {this.props.config.map(conf => {
                        return <th>{conf.title}</th>
                    })}
                </tr>
            </thead>
        )
    }

    _renderBody() {
        return (
            <tbody style={{ maxHeight: this.props.height ? this.props.height : null }}>
                {this.props.data
                    .filter(x => x !== null)
                    .map((row, i) => {
                        return (
                            <tr
                                className={"default-list-element"}
                                value={row._id}
                                key={i}
                                style={{}}
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    const click = this.props.onClickRow
                                        ? this.props.returnRowElement
                                            ? this.props.onClickRow(row)
                                            : this.props.onClickRow(row._id)
                                        : null
                                }}
                            >
                                {this._renderCells(row)}
                            </tr>
                        )
                    })}
            </tbody>
        )
    }

    _renderCells(row) {
        let config = this.props.config
        return config.map((conf, j) => {
            return (
                <td
                    key={j}
                    onClick={e => {
                        if (conf.onClick) {
                            e.preventDefault()
                            e.stopPropagation()
                            return conf.onClick(row)
                        } else {
                            return null
                        }
                    }}
                >
                    {conf.value(row)}
                </td>
            )
        })
    }
}
