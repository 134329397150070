import { connect } from "react-redux";
import * as actions from "../actions/login.js";
import AdminLayout from "../../ui/layout/adminLayout/AdminLayout.js";
import { push } from "react-router-redux";
import { getSettings } from "../actions/settings.js";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onMount: () => {
      if (localStorage.getItem("token")) {
        dispatch(actions.getInfo(window.location.pathname));
        dispatch(getSettings());
      } else {
        dispatch(push("/"));
      }
    }
  };
};

const AdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayout);

export default AdminContainer;
