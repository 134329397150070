import React from 'react'
import { Component } from 'react'




class PathLink extends Component {
  render() {
    return(
      <li onClick={(e) => {
        e.preventDefault();
        this.props.link();
        this.props.handleClick(e);
      }} className={this.props.active}>
        <a key={this.props.name}>
          <span>{this.props.name}</span>
        </a>
      </li>
    )
  }
}

export default PathLink
