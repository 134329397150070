import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getReservations } from '../../../store/actions/reservations';
import { MakeCalendar, FrontPageCalendar } from '../../layout/adminLayout/AdminLayout';
import { getGuestlists } from '../../../store/actions/guestlist';
import DefaultList from '../../components/DefaultList';

class FrontPage extends Component {
  constructor(props) {
    super(props);
    let { month, year, name, day } = new MakeCalendar(0);
    this.state = {
      day,
      month,
      year,
      name,
      showRes: false,
      showGuest: false,
    };
  }

  newMonth(date) {
    if (this.state.month !== date.month) {

      let { month, year, name, day } = new MakeCalendar(0);
      this.props.newMonth(date.month, date.year);
      console.log('set');
      this.setState({
        day: month === date.month ? day : 1,
        month: date.month,
        year: date.year
      });
    } else {
      this.setState({day: date.day});
    }
  }

  componentDidMount() {
    this.props.onMount();
  }

  getInfo() {
    const res = {};
    const glist = {};
    for (let i = 1; i < 34; i++) {
      res[i] = [];
      glist[i] = [];
    }
    this.props.reservations.forEach((ress) => {
      return res[parseInt(ress.day).toString()].push(ress);
    })
    this.props.guestlist.forEach((guest) => {
      return glist[parseInt(guest.day)].push(guest);
    })

    return {
      res,
      glist,
    }
  }

  renderRes(array) {
      let config = [
          { title: "Navn", value: obj => obj.guest.name},
          { title: "Bord", value: obj => obj.tables.sort().join(", ")},
          { title: "Kommentar", value: obj => obj.comment },
          { title: "Antall", value: obj => obj.totalGuests },
          { title: "Bekreftet", value: obj => (obj.confirmed ? "Ja" : "") }
      ];

      return (
          <DefaultList
              data={array}
              config={config}
              returnRowElement={true}
              onClickRow={((res) => this.props.goToReservation(
                        res.day, res.month, res.year, this.state.name, res._id)).bind(this)}
          />
      );
  }

  renderTable(array) {
    console.log('gus');
    console.log(array);
    let config = [
        {title: "Navn", value: (obj) => obj.name},
        {title: "Antall", value: (obj) => obj.totalAmount},
        {title: "Sjekket inn", value: (obj) => obj.totalEntered ? obj.totalEntered : null},
    ];

    return <DefaultList
        data={array}
        config={config}/>;
}

  expandButton(active) {
    return <i className="material-icons">
      {active ? "expand_less" : "expand_more" }
      </i>
  }

  render() {
    if(this.props.access && this.props.access.frontpage.write){
    return (
      <div>
        <FrontPageCalendar
                info={this.getInfo()}
                day={this.state.day}
                month={this.state.month}
                year={this.state.year}
                name={this.state.name}
                date={this.newMonth.bind(this)}
                changeMonth={this.newMonth.bind(this)}
                />
        <div>
          <div>
          <h3 style={{cursor: "pointer"}} onClick={(() => { this.setState({showRes: !this.state.showRes})} ).bind(this)}>Reservasjoner ({this.getInfo().res[parseInt(this.state.day).toString()].length})
          {this.expandButton(this.state.showRes)}
          </h3>
          
          {this.state.showRes ? <div> {this.renderRes(this.getInfo().res[parseInt(this.state.day).toString()])} </div>: ''}
          </div>
          <div>
          <h3 style={{cursor: "pointer"}} onClick={(() => { this.setState({showGuest: !this.state.showGuest})} ).bind(this)}>Gjesteliste ({this.getInfo().glist[parseInt(this.state.day).toString()].length})
          {this.expandButton(this.state.showGuest)}
          </h3>
          
          {this.state.showGuest ? this.renderTable(this.getInfo().glist[parseInt(this.state.day).toString()]): ''}
          </div>
        </div>
      </div>
    ); } else{
      return <div style={{marginTop: "10%", marginLeft: "10%"}}>Du har ikke rettigheter til å se innholdet på denne siden.
              </div>
    }
  }
}

const styles = {
  button: {
    width: '160px',
    marginBottom: '20px',
    marginRight: '10px',
    marginLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '18px',
    borderRadius: '8px',
  },
  del: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000070',
    top: 0,
    left: 0,
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  delCard: {
    flex: '',
    height: '200px',
    width: '90%',
    maxWidth: '400px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '5px 5px 5px 5px',
    textAlign: 'center',
  },
  delCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%'
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    reservations: state.reservations.data,
    guestlist: state.guestlists.data,
    location: ownProps.location.search,
    access: state.login.user.info.permissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: ()=> {
      let { month, year} = new MakeCalendar(0);
      dispatch(getReservations(null, month, year))
      dispatch(getGuestlists(null, month, year))
    },
    newMonth: (month, year )=> {
      dispatch(getReservations(null, month, year))
      dispatch(getGuestlists(null, month, year))
    },
    goToReservation: (day,month, year, name, id) => {
      dispatch(push(`/admin/tableorder?day=${day}&month=${month}&year=${year}&name=${name}&id=${id}`));
    },
  }
}

const FrontPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(FrontPage)


export default FrontPageContainer;
