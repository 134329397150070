import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "../Modal"
import Mail from "../messageAndMail/mail"
import axios from "axios"
import url from "../../../url.js"
import Message from "../messageAndMail/message"
import "./communication.css"
import GuestsListContainer from "../guests/GuestsList"
import { getGuest } from "../../../store/actions/guests"

class Communications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addmail: false,
            addmessage: false,
            add: false,
            change: {
                name: "",
                description: "",
                _id: "",
                guest: {
                    name: "",
                    number: "+47",
                    email: "",
                },
                email: {
                    send: false,
                    bodyHTML: "",
                    bodyText: "",
                    subject: "",
                },
                message: {
                    send: false,
                    message: "",
                },
                reciever: "",
                hasSentMessage: false,
                confirmed: false,
            },
        }
        this.addmail = this.addmail.bind(this)
        this.addmessage = this.addmessage.bind(this)
        this.add = this.add.bind(this)
        this.click = this.click.bind(this)
        this.proceed = this.proceed.bind(this)
    }

    click() {
        const current = this.state.change
        current.requiresReservation = !current.requiresReservation
        this.setState({ change: current })
    }

    changeValue(key, e) {
        e.preventDefault()
        if (this.state.addmail || this.state.addmessage) {
            const current = this.state.change
            current[key] = e.target.value
            this.setState({ change: current })
        }
    }

    changeComValues(key, value) {
        const current = this.state.change
        this.set(current, key, value)
        this.setState({ change: current })
    }

    changeReciever(key, value) {
        const current = this.state.change.guest.email
        const set = this.set(current, key, value)
        this.setState({ change: current })
    }

    addmail() {
        this.setState({
            addmail: !this.state.addmail,
        })
    }

    addmessage() {
        this.setState({
            addmessage: !this.state.addmessage,
        })
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    reciever(rec) {
        this.setState({
            reciever: rec,
        })
    }

    exitModal(type) {
        const current = this.state.change
        current.email.bodyHTML = ""
        current.email.bodyText = ""
        current.message.message = ""
        current[type] = false
        this.setState(current)
    }

    renderMail() {
        const emailValues = {
            reciever: this.state.change.reciever,
            subject: this.state.change.email.subject,
            message: this.state.change.email.bodyHTML,
            recieverChange: this.changeComValues.bind(this, "reciever"),
            subjectChange: this.changeComValues.bind(this, "email.subject"),
            messageChange: this.changeComValues.bind(this, "email"),
        }
        return (
            <Mail
                subject={emailValues.subject}
                message={emailValues.message}
                subjectChange={emailValues.subjectChange}
                messageChange={emailValues.messageChange}
                edit={true}
            />
        )
    }

    renderSMS() {
        console.log(this.state.change)
        const smsValues = {
            message: this.state.change.message.message,
            messageChange: this.changeComValues.bind(this, "message.message"),
        }
        return <Message message={smsValues.message} messageChange={smsValues.messageChange} edit={true} />
    }
    changeOk(type) {
        const o = {}
        o[type] = false
        this.setState(o)
    }

    set(obj, path, value) {
        let schema = obj // a moving reference to internal objects within obj
        const pList = path.split(".")
        const len = pList.length
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i]
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem]
        }

        if (typeof value === "object") {
            schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value)
        } else {
            schema[pList[len - 1]] = value
        }
    }

    renderGuest() {
        return (
            <div>
                {!this.props.guest.isFetching && this.state.guest ? (
                    <div>
                        <p>
                            {this.props.guest.info.firstname} {this.props.guest.info.lastname}
                        </p>
                        <p>{this.props.guest.info.number}</p>
                        <p>{this.props.guest.info.email}</p>
                        <button style={styles.button} className="change" onClick={this.removeGuest.bind(this)}>
                            Velg en annen gjest
                        </button>
                    </div>
                ) : (
                    <button style={styles.button} className="change" onClick={this.add}>
                        Søk etter gjest
                    </button>
                )}
            </div>
        )
    }

    renderComModal(openType, title, renderFunc) {
        return (
            <div>
                <Modal title={title} exit={this.exitModal.bind(this, openType)}>
                    {this.renderGuest()}

                    {this.state.add ? (
                        <GuestsListContainer
                            onClickRow={this.guest.bind(this)}
                            withModal
                            title={"Velg en gjest"}
                            guest={this.guest.bind(this)}
                            exit={this.exit.bind(this)}
                        />
                    ) : (
                        ""
                    )}
                    <div>Mottaker:</div>
                    {this.state.guest ? (
                        <input
                            placeholder={this.state.addmail ? "kari@nordmann.no" : "+4740019230"}
                            onChange={this.changeValue.bind(this, "reciever")}
                            type="text"
                            className="add-inputF"
                            value={this.state.addmail ? this.props.guest.info.email : this.props.guest.info.number}
                            maxlength="60"
                            required
                        />
                    ) : (
                        <input
                            placeholder={this.state.addmail ? "kari@nordmann.no" : "+4766677888"}
                            onChange={this.changeValue.bind(this, "reciever")}
                            type="text"
                            className="add-inputF"
                            value={this.state.change.reciever}
                            maxlength="60"
                            required
                        />
                    )}
                    {renderFunc()}
                    <button
                        onClick={this.proceed}
                        style={{
                            ...styles.button,
                        }}
                        className="save"
                    >
                        Send
                    </button>
                </Modal>
            </div>
        )
    }

    renderComModals() {
        return (
            <div>
                {this.state.addmail ? this.renderComModal("addmail", "Send mail", this.renderMail.bind(this)) : ""}
                {this.state.addmessage ? this.renderComModal("addmessage", "Send SMS", this.renderSMS.bind(this)) : ""}
            </div>
        )
    }

    proceed(e) {
        e.preventDefault()
        var info = {}
        var postUrl = ""
        var reciever = ""
        if (this.state.addmail) {
            if (this.state.guest) {
                reciever = this.props.guest.info.email
            } else {
                reciever = this.state.change.reciever
            }
            postUrl = "admin/com"
            info = {
                email: {
                    send: true,
                    bodyHTML: this.state.change.email.bodyHTML,
                    receiver: reciever,
                    subject: this.state.change.email.subject,
                    bodyText: this.state.change.email.bodyText,
                },
                message: {
                    send: false,
                    receiver: reciever,
                    message: "j",
                },
            }
            console.log(info)
        } else if (this.state.addmessage) {
            if (this.state.guest) {
                reciever = this.props.guest.info.number
            } else {
                reciever = this.state.change.reciever
            }
            postUrl = "admin/com"
            info = {
                message: {
                    send: true,
                    receiver: reciever,
                    message: this.state.change.message.message,
                },
                email: {
                    send: false,
                    bodyHTML: "j",
                    receiver: "j",
                    subject: "j",
                    bodyText: "j",
                },
            }
            console.log(info)
        }
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        axios
            .post(url + postUrl, info, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({
                    change: {
                        name: "",
                        description: "",
                        _id: "",
                        guest: {
                            name: "",
                            number: "+47",
                            email: "",
                        },
                        email: {
                            send: false,
                            bodyHTML: "",
                            bodyText: "",
                            subject: "",
                        },
                        message: {
                            send: false,
                            message: "",
                        },
                        reciever: "",
                        hasSentMessage: false,
                        confirmed: false,
                    },
                    addmail: false,
                    addmessage: false,
                    add: false,
                })
                this.props.onMount()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    removeGuest() {
        this.setState({ guest: false })
    }

    guest(id) {
        this.props.onGuestSelect(id)
        this.setState({ guest: true, add: false })
    }

    exit() {
        this.setState({ add: false })
    }

    render() {
        return (
            <div>
                <h1>Mail & SMS</h1>
                <button onClick={this.addmail} style={{ ...styles.button, ...{ marginLeft: "0px" } }} className="save">
                    {" "}
                    Send Mail{" "}
                </button>
                <button
                    onClick={this.addmessage}
                    style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                    className="save"
                >
                    {" "}
                    Send SMS{" "}
                </button>
                <div>{this.renderComModals()}</div>
            </div>
        )
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        height: "300px",
        width: "95%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}

const mapStateToProps = state => {
    return {
        /*communications: state.communications.data,
    error: state.communications.error.active,
    loading: state.communications.isFetching,
    message: state.communications.error.message,
    settings: state.settings.info, */
        guest: state.guests,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGuestSelect(id) {
            dispatch(getGuest(id))
        },
    }
}

const CommunicationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Communications)

export default CommunicationsContainer
