import { connect } from 'react-redux';
import Pagetitle from '../../ui/components/Pagetitle.js';

const mapStateToProps = (state) => ({ path: state.router.location.pathname })

const Header = connect(
  mapStateToProps,
  {}
)(Pagetitle)

export default Header;
