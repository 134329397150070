import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import * as actions from "../../../store/actions/guestlist.js"
import { CalendarJonathan, MakeCalendar } from "../../layout/adminLayout/AdminLayout.js"
import DefaultList from "../DefaultList"
import url from "../../../url"
import { getGuest } from "../../../store/actions/guests.js"
import GuestsListContainer from "../guests/GuestsList"
import Switch from "../Switch.js"
import Modal from "../Modal"
import Mail from "../messageAndMail/mail.js"
import Message from "../messageAndMail/message.js"
import { getSettings } from "../../../store/actions/settings.js"
import "./guestLists.css"

class Guestlists extends Component {
    constructor(props) {
        super(props)
        let { month, year, name, day } = new MakeCalendar(0)
        this.state = {
            addGuest: false,
            month,
            year,
            day,
            delete: false,
            id: null,
            calendar: false,
            add: false,
            editGuest: false,
            guest: false,
            search: "",
            newGuest: {
                id: null,
                name: "",
                date: {
                    year: "",
                    month: "",
                    day: "",
                },
                totalAmount: "",
                freeEntry: "",
                email: false,
                message: false,
            },
            change: {
                email: {
                    send: false,
                    bodyHTML: "",
                    bodyText: "",
                    subject: "",
                },
                message: {
                    send: false,
                    message: "",
                },
            },
            changeSMS: false,
            changeMail: false,
        }

        this.add = this.add.bind(this)
        this.clickSwitchEmail = this.clickSwitchEmail.bind(this)
        this.clickSwitchMessage = this.clickSwitchMessage.bind(this)
    }

    exitModal(type) {
        const current = this.state.change
        current.email.bodyHTML = this.props.settings.contact.guestlist.mail.bodyHTML
        current.email.bodyText = this.props.settings.contact.guestlist.mail.bodyText
        current.message.message = this.props.settings.contact.guestlist.message.bodyText
        current[type] = false
        this.setState(current)
    }

    componentDidMount() {
        let { month, year, day } = new MakeCalendar(0)
        this.props.onMount(day, month, year)
        this.setState({
            year: year,
            month: month,
            day: day,
        })
    }

    calendar(e) {
        e.preventDefault()
        this.setState({
            calendar: !this.state.calendar,
        })
    }

    renderCalendar() {
        if (this.state.calendar) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <i style={{ cursor: "pointer" }} onClick={this.calendar.bind(this)} className="material-icons">
                            close
                        </i>
                        <div>
                            <CalendarJonathan
                                day={this.state.day}
                                month={this.state.month}
                                year={this.state.year}
                                date={this.newDate.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return ""
        }
    }

    newDate(date) {
        this.setState({
            day: date.day,
            month: date.month,
            year: date.year,
        })
        this.props.onMount(date.day, date.month, date.year)
    }

    editGuest(guest) {
        this.props.onGuestSelect(guest.guestID)
        this.setState({ guest: true, addGuest: false })
        this.setState({
            editGuest: !this.state.editGuest,
            newGuest: {
                id: guest._id,
                date: {
                    year: guest.year,
                    month: guest.month,
                    day: guest.day,
                },
                totalAmount: guest.totalAmount,
                freeEntry: guest.freeEntry,
                freeEntered: guest.freeEntered,
                totalEntered: guest.totalEntered,
            },
        })
    }

    newGuest() {
        this.setState({
            add: !this.state.add,
            newGuest: {
                date: {
                    year: this.state.year,
                    month: this.state.month,
                    day: this.state.day,
                },
                totalAmount: "",
                freeEntry: "",
            },
        })
    }

    removeGuest() {
        this.setState({ guest: false })
    }

    addGuest() {
        let newGuest = this.state.newGuest
        let { hasLimit, guestlistLimit } = this.props.user.info
        if (!hasLimit || parseInt(guestlistLimit, 10) >= parseInt(newGuest.totalAmount, 10)) {
            const token = window.localStorage.getItem("token")
            let date = newGuest.date
            let info = {
                guests: [
                    {
                        id: this.props.guest.info.guestID || this.props.guest.info._id,
                        totalAmount: newGuest.totalAmount,
                        freeEntry: newGuest.freeEntry,
                    },
                ],
                email: {
                    send: this.state.change.email.send,
                    bodyHTML:
                        this.state.change.email.bodyHTML.length > 0
                            ? this.state.change.email.bodyHTML
                            : this.props.settings.contact.guestlist.mail.bodyHTML,
                    bodyText:
                        this.state.change.email.bodyText.length > 0
                            ? this.state.change.email.bodyText
                            : this.props.settings.contact.guestlist.mail.bodyText,
                    subject:
                        this.state.change.email.subject.length > 0
                            ? this.state.change.email.subject
                            : this.props.settings.contact.guestlist.mail.subject,
                },
                message: {
                    send: this.state.change.message.send,
                    message:
                        this.state.change.message.message.length > 0
                            ? this.state.change.message.message
                            : this.props.settings.contact.guestlist.message.bodyText,
                },
                day: this.state.day,
                month: this.state.month,
                year: this.state.year,
                name: this.props.guest.info.firstname,
                number: this.props.guest.info.number,
                mail: this.props.guest.info.email,
            }

            axios
                .post(url + "admin/guestlists?day=" + date.day + "&month=" + date.month + "&year=" + date.year, info, {
                    headers: { Authorization: token },
                })
                .then(response => {
                    this.setState({
                        add: false,
                        guest: false,
                        guestAdd: false,
                    })
                    this.props.onMount(this.state.day, this.state.month, this.state.year)
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        } else {
            window.confirm(
                "Du har ikke rettighetene som kreves for å legge til mer enn " + guestlistLimit + " gjester per dag.",
            )
        }
    }

    updateGuest() {
        let newGuest = this.state.newGuest
        const { hasLimit, guestlistLimit } = this.props.user.info
        if (!hasLimit || parseInt(guestlistLimit, 10) >= parseInt(newGuest.totalAmount, 10)) {
            let newGuest = this.state.newGuest
            const token = window.localStorage.getItem("token")
            let info = {
                id: this.props.guest.info.guestID || this.props.guest.info._id,
                totalAmount: parseInt(newGuest.totalAmount),
                freeEntry: parseInt(newGuest.freeEntry),
            }

            axios
                .patch(
                    url + "admin/guestlists/" + this.state.newGuest.id,
                    {
                        check: parseInt(newGuest.totalEntered),
                        checkFree: parseInt(newGuest.freeEntered),
                    },
                    {
                        headers: { Authorization: token },
                    },
                )
                .then(() => {
                    this.setState({
                        editGuest: false,
                        add: false,
                        guest: false,
                        guestAdd: false,
                    })
                    this.props.onMount(this.state.day, this.state.month, this.state.year)
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })

            axios
                .patch(url + "admin/guestlists/guests/" + this.state.newGuest.id, info, {
                    headers: { Authorization: token },
                })
                .then(response => {
                    this.setState({
                        editGuest: false,
                        add: false,
                        guest: false,
                        guestAdd: false,
                    })
                    this.props.onMount(this.state.day, this.state.month, this.state.year)
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        } else {
            window.confirm(
                "Du har ikke rettighetene som kreves for å legge til mer enn " + guestlistLimit + " gjester per dag.",
            )
        }
    }

    changeValue(key, e, newGuest = false) {
        let current = this.state
        if (newGuest) {
            let newGuest = current.newGuest
            if (key === "date") {
                let dateList = e.target.value.split("-")
                newGuest.date = {
                    year: dateList[0],
                    month: dateList[1],
                    day: dateList[2],
                }
            } else if (key === "totalAmount" || key === "freeEntry") {
                let { guestlistLimit, hasLimit } = this.props.user.info
                if (hasLimit && e.target.value > guestlistLimit) {
                    window.confirm(
                        "Du har ikke tillatelse til å legge til flere enn " + guestlistLimit + " gjester per dag.",
                    )
                } else {
                    newGuest[key] = e.target.value
                }
            } else {
                newGuest[key] = e.target.value
            }
            current.newGuest = newGuest
        } else {
            current[key] = e.target.value
        }
        this.setState(current)
    }

    changeCom(type, e) {
        e.preventDefault()
        const x = {}
        x[type] = !this.state[type]
        this.setState(x)
    }

    newForm() {
        let formInputs = [
            {
                placeHolder: "Dato",
                valueKey: "date",
                type: "date",
                value: `${this.state.newGuest.date.year}-${this.state.newGuest.date.month}-${
                    this.state.newGuest.date.day
                }`,
            },
            { placeHolder: "Antall", valueKey: "totalAmount", type: "number", value: this.state.newGuest.totalAmount },
            {
                placeHolder: "Gratis inngang",
                valueKey: "freeEntry",
                type: "number",
                value: this.state.newGuest.freeEntry,
            },
        ]

        if (this.state.editGuest) {
            formInputs = [
                ...formInputs,
                {
                    placeHolder: "Sjekket inn totalt",
                    valueKey: "totalEntered",
                    type: "number",
                    value: this.state.newGuest.totalEntered,
                },
                {
                    placeHolder: "Sjekket inn med gratis inngang",
                    valueKey: "freeEntered",
                    type: "number",
                    value: this.state.newGuest.freeEntered,
                },
            ]
        }

        if (this.state.add || this.state.editGuest) {
            return (
                <div style={styles.del} className={"del"}>
                    {this.state.addGuest ? (
                        <GuestsListContainer
                            onClickRow={this.guest.bind(this)}
                            withModal
                            title={"Velg en gjest"}
                            guest={this.guest.bind(this)}
                            exit={this.exit.bind(this)}
                        />
                    ) : (
                        ""
                    )}
                    <div style={styles.delCard} className={"delCard"}>
                        <h2>{this.state.add ? "Legg til gjest i gjestelisten" : "Endre gjesten i gjestelisten"}</h2>
                        <div>
                            {!this.props.guest.isFetching && this.state.guest ? (
                                <div>
                                    <p>
                                        {this.props.guest.info.firstname} {this.props.guest.info.lastname}
                                    </p>
                                    <p>{this.props.guest.info.number}</p>
                                    <p>{this.props.guest.info.email}</p>
                                    <button
                                        style={styles.button}
                                        className="change"
                                        onClick={this.removeGuest.bind(this)}
                                    >
                                        Fjern gjest
                                    </button>
                                </div>
                            ) : (
                                <button style={styles.button} className="change" onClick={this.add}>
                                    Legg til gjest
                                </button>
                            )}
                            <form onSubmit={this.addGuest} style={styles.addNew.form}>
                                {formInputs.map(
                                    input =>
                                        this.state.add || input.valueKey !== "date" ? (
                                            <div>
                                                <h4 style={styles.addNew.formInputTitle}>{input.placeHolder}</h4>
                                                <input
                                                    style={styles.addNew.input}
                                                    placeholder={input.placeHolder}
                                                    onChange={e => this.changeValue(input.valueKey, e, true)}
                                                    type={input.type}
                                                    className="add-input"
                                                    value={input.value}
                                                />
                                            </div>
                                        ) : null,
                                )}
                            </form>

                            <div className="switchdiv">
                                <p>Send mail?</p>
                                <Switch click={this.clickSwitchEmail} checked={this.state.change.email.send} />
                            </div>
                            <div className="switchdiv">
                                <p>Send SMS?</p>

                                <Switch click={this.clickSwitchMessage} checked={this.state.change.message.send} />
                            </div>

                            <div style={styles.delCont}>
                                <button
                                    disabled={!this.state.change.email.send}
                                    onClick={this.changeCom.bind(this, "changeMail")}
                                    style={{
                                        ...styles.button,
                                        ...this.disabled(!this.state.change.email.send),
                                    }}
                                    className="change"
                                >
                                    Endre mail
                                </button>
                                <button
                                    disabled={!this.state.change.message.send}
                                    onClick={this.changeCom.bind(this, "changeSMS")}
                                    style={{
                                        ...styles.button,
                                        ...this.disabled(!this.state.change.message.send),
                                    }}
                                    className="change"
                                >
                                    Endre sms
                                </button>
                            </div>

                            <div style={{ ...styles.delCont, marginBottom: 84 }}>
                                <button
                                    onClick={e => {
                                        e.preventDefault()
                                        this.setState({
                                            add: false,
                                            editGuest: false,
                                            newGuest: {
                                                date: {
                                                    year: "",
                                                    month: "",
                                                    day: "",
                                                },
                                                totalAmount: "",
                                                freeEntry: "",
                                                message: {
                                                    send: false,
                                                },
                                                email: {
                                                    send: false,
                                                },
                                            },
                                        })
                                    }}
                                    style={styles.button}
                                    className="change"
                                >
                                    Avbryt
                                </button>
                                <button
                                    onClick={this.state.add ? this.addGuest.bind(this) : this.updateGuest.bind(this)}
                                    style={styles.button}
                                    className="save"
                                >
                                    Lagre
                                </button>
                                <div>{this.renderComModals()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    renderComModals() {
        return (
            <div>
                {this.state.changeMail
                    ? this.renderComModal("changeMail", "Endre mail", this.renderMail.bind(this))
                    : ""}
                {this.state.changeSMS
                    ? this.renderComModal("changeSMS", "Endre melding/SMS", this.renderSMS.bind(this))
                    : ""}
            </div>
        )
    }

    renderComModal(openType, title, renderFunc) {
        return (
            <div>
                <Modal title={title} exit={this.exitModal.bind(this, openType)}>
                    {renderFunc()}
                    <button
                        onClick={this.changeOk.bind(this, openType)}
                        style={{
                            ...styles.button,
                            marginBottom: 84,
                        }}
                        className="save"
                    >
                        Ok
                    </button>
                </Modal>
            </div>
        )
    }

    changeComValues(key, value) {
        const current = this.state.change
        const set = this.set(current, key, value)
        this.setState({ change: current })
    }

    renderMail() {
        const emailValues = {
            subject:
                this.state.change.email.subject.length > 0
                    ? this.state.change.email.subject
                    : this.props.settings.contact.guestlist.mail.subject,
            message:
                this.state.change.email.bodyHTML.length > 0
                    ? this.state.change.email.bodyHTML
                    : this.props.settings.contact.guestlist.mail.bodyHTML,
            subjectChange: this.changeComValues.bind(this, "email.subject"),
            messageChange: this.changeComValues.bind(this, "email"),
        }
        console.log(emailValues)
        return (
            <Mail
                subject={emailValues.subject}
                message={emailValues.message}
                subjectChange={emailValues.subjectChange}
                messageChange={emailValues.messageChange}
                edit={true}
            />
        )
    }

    renderSMS() {
        const mes = this.props.settings && this.props.settings.contact.guestlist.message.bodyText
        const smsValues = {
            message: this.state.change.message.message.length > 0 ? this.state.change.message.message : mes,
            messageChange: this.changeComValues.bind(this, "message.message"),
        }
        return <Message message={smsValues.message} messageChange={smsValues.messageChange} edit={true} />
    }

    changeOk(type) {
        const o = {}
        o[type] = false
        this.setState(o)
    }

    set(obj, path, value) {
        let schema = obj // a moving reference to internal objects within obj
        const pList = path.split(".")
        const len = pList.length
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i]
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem]
        }

        if (typeof value === "object") {
            schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value)
        } else {
            schema[pList[len - 1]] = value
        }
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        }
    }

    clickSwitchEmail() {
        const current = this.state.change
        current.email.send = !current.email.send
        this.setState({
            change: current,
        })
    }

    clickSwitchMessage() {
        const current = this.state.change
        current.message.send = !current.message.send
        this.setState({
            change: current,
        })
    }

    status() {
        if (this.state.fetching || this.props.loading) {
            return <p>Oppdaterer...</p>
        } else if (this.state.error) {
            return <p style={{ color: "red" }}>Error</p>
        } else if (this.state.patch) {
            setTimeout(() => {
                this.setState({ patch: false })
            }, 3000)
            return <p style={{ color: "green" }}>Oppdatert</p>
        } else {
            return null
        }
    }

    renderTable() {
        let config = [
            { title: "Navn", value: obj => obj.name },
            { title: "Antall", value: obj => obj.totalAmount },
            { title: "Gratis inngang", value: obj => obj.freeEntry },
            { title: "Sjekket inn totalt", value: obj => (obj.totalEntered ? obj.totalEntered : null) },
            { title: "Sjekket inn gratis", value: obj => (obj.freeEntered ? obj.freeEntered : null) },
            { title: "Slett", value: obj => <span>Slett</span>, onClick: guest => this.deleteGuestPrompt(guest._id) },
        ]

        return (
            <DefaultList
                onClickRow={guest => this.editGuest(guest)}
                returnRowElement={true}
                data={this.props.guestlist.filter(
                    guest => guest.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1,
                )}
                config={config}
            />
        )
    }

    deleteGuestPrompt(id) {
        this.setState({
            delete: true,
            id: id,
        })
    }

    deleteModal() {
        if (this.state.delete) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h2>Du er i ferd med å slette en gjest fra gjestelisten. Vil du slette?</h2>
                        </div>
                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                        delete: false,
                                        id: null,
                                    })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Nei, Avbryt
                            </button>
                            <button
                                onClick={() => this.deleteGuest(this.state.id)}
                                style={styles.button}
                                className="delete"
                            >
                                Ja, Slett
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    deleteGuest(id) {
        const token = window.localStorage.getItem("token")
        const info = {
            day: this.state.day,
            month: this.state.month,
            year: this.state.year,
        }
        axios
            .delete(url + "admin/guestlists/" + id, {
                headers: { Authorization: token },
                params: {
                    ...info,
                },
            })
            .then(response => {
                this.props.onMount(this.state.day, this.state.month, this.state.year)
                this.setState({
                    delete: false,
                    id: null,
                })
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    add() {
        this.setState({
            addGuest: !this.state.addGuest,
        })
    }

    guest(id) {
        this.props.onGuestSelect(id)
        this.setState({ guest: true, addGuest: false })
    }

    exit() {
        this.setState({ addGuest: false })
    }

    openGuest() {
        this.setState({ addGuest: true })
    }

    render() {
        return (
            <div>
                {this.deleteModal()}

                <h1 className="title" style={{ marginTop: "50px" }}>
                    Gjestelister
                </h1>
                <h3>{this.state.day + "." + this.state.month + "." + this.state.year}</h3>
                {this.status()}
                {this.renderCalendar()}
                <div>
                    <button
                        onClick={this.calendar.bind(this)}
                        style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                        className="change"
                    >
                        Kalender
                    </button>
                    {this.props.access && this.props.access.guestlist.write && this.props.settings ? (
                        <button
                            onClick={this.newGuest.bind(this)}
                            style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                            className="change"
                        >
                            Legg til
                        </button>
                    ) : (
                        ""
                    )}
                </div>

                <input
                    placeholder="Søk etter gjest"
                    onChange={e => this.setState({ search: e.target.value })}
                    type="text"
                    className="add-input"
                    value={this.state.search}
                />
                {this.newForm()}

                {this.props.loading ? null : this.renderTable()}
            </div>
        )
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        width: "98%",
        maxWidth: "600px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
    addNew: {
        title: {
            textAlign: "center",
        },
        form: {
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
        },
        input: {
            borderRadius: 4,
        },
        sideBySide: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        selectorInput: {
            button: {
                flex: 1,
            },
        },
        formInputTitle: {
            marginBottom: 3,
        },
    },
}

const mapStateToProps = state => {
    return {
        guestlist: state.guestlists.data,
        error: state.guestlists.error.active,
        access: state.login.user.info.permissions,
        loading: state.guestlists.isFetching,
        message: state.guestlists.error.message,
        guest: state.guests,
        settings: state.settings.info,
        user: state.login.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: (day, month, year) => {
            dispatch(actions.getGuestlists(day, month, year))
            dispatch(getSettings())
        },
        onGuestSelect(id) {
            dispatch(getGuest(id))
        },
    }
}

const GuestlistsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Guestlists)

export default GuestlistsContainer
