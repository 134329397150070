import React, {Component} from 'react';
import {location} from '../../constants.js'
import {checkUrl} from '../../methods.js'
import PathLink from './PathLink'
import { connect } from 'react-redux';
import './styles/NavBar.css';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false,
            collapsed: true,
        }
        this.resizeNav = this.resizeNav.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    resizeNav() {
        if (window.matchMedia("(min-width: 700px)").matches) {
            this.setState({mobile: false})
        } else {
            this.setState({mobile: true})
        }
    }


    componentDidMount() {
        this.resizeNav()
        window.addEventListener('resize', this.resizeNav)
    }

    nav() {
        if (this.state.mobile && this.state.collapsed) {
            return {
                display: 'none',
            }
        } else {
            return {display: 'block'}
        }
    }

    toggle(e) {
        e.preventDefault();
        this.setState({collapsed: !this.state.collapsed});
    }

    img() {
        return this.state.mobile ? {display: 'none'} : styles.imgs;
    }

    hamburger() {
        return this.state.mobile ? styles.hamburger : {display: 'none'};
    }

    access(location) {
        const items = location.items.filter(item => (this.props.access[item.perm].write || this.props.access[item.perm].read))
        return items;
    }

    render() {
        const {path, changeUrl} = this.props
        return (
            <div style={{overflow: 'hidden'}}>
                <div onClick={this.toggle} style={this.hamburger()}>
                    <i style={{fontSize: '48px', color: '#E3F2FD'}} className="material-icons">menu</i>
                </div>
                {this.props.access ? (<nav className="scrollmenu" style={this.nav()}>

                    {!this.state.mobile ? <img src="/imgs/logo.png" alt="Studio 26 logo" style={this.img()}/> : ''}
                    <ul>
                        {this.access(location) ? this.access(location).map(item =>
                           
                           <PathLink
                                key={item.path}
                                handleClick={this.toggle}
                                link={changeUrl.bind(null, item.path)}
                                icon={item.icon}
                                name={item.name}
                                active={checkUrl(path, item.path) ? 'active' : ''}
                                path={item.path}
                            />

                        ) : null}
                        <li onClick={this.props.logOut}>
                            <a>
                                <span>Logg ut</span>
                            </a>
                        </li>
                    </ul>

                </nav>) : <p>Loading</p>}
            </div>
        )
    }
}


const styles = {
    imgs: {
        width: '90%',
        margin: '5%',
    },
    hamburger: {
        width: '48px',
        height: '48px',
        backgroundColor: '#01579B',
        borderRadius: '8px',
        cursor: 'pointer',
        position: 'fixed',
        zIndex: '2000',
        marginLeft: '5px',
        marginTop: '5px,'
    }
}

const mapStateToProps = function(state){
    return{
        permissions: state.login.user.info.permissions,
    }
}





export default connect(mapStateToProps)(NavBar);
