import React, { Component } from "react";
import "./styles/switch.css";
import "./styles/Kartandre.css";

const table = [
  "Hestesko 1",
  "Hestesko 2",
  "Hestesko 3",
  "Moethjørnet",
  "Høybord v/ trapp",
  "Lille hjørnet",
  "Store hjørnet",
  "Kobberrekka",
  "Mørksofa v/ kontor",
  "Blåsofa",
  "Hjørnet v/ Retro",
  "Foran Retro",
  "Trapp v/ Kos 1",
  "Trapp v/ Kos 2",
  "Lysekrona",
  "Dansegulv",
  "Dansegulv v/DJ",
  "Lounge 1",
  "Lounge 2",
  "Lounge 3",
  "Lounge 4",
  "Forran bar",
  "Hjørnet ved bar"
];

export default class Kart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kart: 2
    };
  }

  status(index) {
    /*return this.props.table[index];*/
    let classNameString = "group";
    if (this.props.selected.indexOf(table[index]) > -1) {
      classNameString += " selected";
    }
    if (this.props.newres.indexOf(table[index]) > -1) {
      classNameString += "newres";
    } else if (this.props.booked.indexOf(table[index]) > -1) {
      classNameString += " booked";
    } else {
      classNameString += " available";
    }
    return classNameString;
  }

  click(index, e) {
    e.preventDefault();
    this.props.click(table[index]);
  }

  switch(kart, e) {
    e.preventDefault();
    this.setState({ kart });
  }

  renderKartAndre() {
    return (
      <svg
        style={styles.svg2}
        width="323px"
        height="482px"
        viewBox="0 0 323 482"
        version="1.1"
        id="svg96"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group-4">
            <path
              d="M259,298 L259,313 L259,342 L196,342 L196,313 L230,313 L230,298 L194,298 L194,261 L259,261 L259,298 Z"
              id="Combined-Shape"
              fill="#D8D8D8"
            />
            <path
              d="M68,450.090366 L68,450 L191,450 L191,417 L209,417 L209,466 L276,466 L282.384226,466 L282.131479,464.547521 L282.131479,388.945645 L285.354493,386.280968 L285.354493,347.837439 L257.973335,342.072985 L257.973335,342 L194,342 L194,296 L257.973335,296 L257.973335,264 L192,264 L192,250 L192,143 L59,143 L59,204 L58.8756126,204 L58.8756126,220.28723 C48.1029808,218.101486 39.282789,218.101486 32.4150371,220.28723 C25.5472851,222.472973 21.5874458,226.334984 20.535519,231.873264 C18.9829102,241.582427 22.2205804,247.893713 30.2485295,250.807122 C41.2722487,254.80772 54.4908772,255.896971 57.3855427,256.052572 L58.89127,256.052572 C58.89127,266.860233 58.89127,272.264064 58.89127,272.264064 C58.89127,272.264064 55.2341402,271.886106 47.9198805,271.130188 L40.3582643,271.130188 L34.4253843,272.060226 C25.9628054,274.07054 21.3905579,277.809366 20.7086417,283.276705 C19.6319831,291.908935 20.7086417,298.760474 28.2506106,301.583199 C35.7925795,304.405924 52.8682532,308.89475 58.6884751,307.835404 L58.8022415,339.370949 C40.3858282,335.685907 27.6879617,338.483351 20.7086417,347.76328 C18.3904457,356.38864 19.8207693,362.575992 24.9996127,366.325336 C32.7678777,371.949352 51.4280368,374.971581 58.8022415,374.971581 L50.4923496,385.323497 C26.2661567,387.952764 14.6776497,394.149185 15.7268285,403.912758 C17.3005968,418.558118 15.2651679,412.128669 24.9996127,423.84743 C34.7340575,435.566192 53.7412865,451.795213 57.3133859,454.68966 L60.7840848,454.724285 C65.4781844,451.635005 67.8252343,450.090366 67.8252343,450.090366 L68,450.090366 Z M320.863565,264.404105 L301.853508,481.690058 L283.922003,480.121255 L283.932612,480 L276,480 L61,480 L60.5502525,480.449747 L60.1005051,480 L60,480 L60,479.899495 L0.100505063,420 L0,420 L0,204 L0,37 L58,37 L58,-5.1159077e-13 L199,-5.1159077e-13 L206,-5.1159077e-13 L206,234.232309 L322.880388,246.516932 L320.998876,264.418326 L320.863565,264.404105 Z"
              id="path9"
              fill="#D8D8D8"
            />

            <path
              d="M158.607052,171.083404 L192,171.083404 L192,184 L158.607052,184 L151,184 L151,156 L158.607052,156 L158.607052,171.083404 Z"
              id="path42"
              className="path66"
            />

            <path
              d="M95.0999783,395.133387 L92.6857438,450.417136 L75.9717701,450.341001 L79.276968,389.004758 L79.6737771,381.433191 L192.343615,387.337967 L191.669838,400.194399 L95.0999783,395.133387 Z"
              id="path66"
              fill="#000000"
              className="path66"
            />

            <path d="" id="Path-3" stroke="#979797" />

            <path
              d="M77.4140496,156.609374 C77.3671747,156.656248 77.3046747,156.679686 77.22655,156.679686 C77.1484253,156.679686 77.078113,156.656248 77.015613,156.609374 L72.1171872,151.68751 C72.0546873,151.640635 72.0234374,151.578135 72.0234374,151.500011 C72.0234374,151.421886 72.0546873,151.359386 72.1171872,151.312511 L77.015613,146.390648 C77.078113,146.343773 77.1484253,146.320336 77.22655,146.320336 C77.3046747,146.320336 77.3671747,146.343773 77.4140496,146.390648 L77.8827985,146.859397 C77.9452984,146.906272 77.9765483,146.968772 77.9765483,147.046896 C77.9765483,147.125021 77.9452984,147.195333 77.8827985,147.257833 L73.6406211,151.500011 L77.8827985,155.742188 C77.9452984,155.804688 77.9765483,155.875 77.9765483,155.953125 C77.9765483,156.03125 77.9452984,156.09375 77.8827985,156.140625 L77.4140496,156.609374 Z"
              id="chevron-left-copy"
              fill="#93D877"
              transform="translate(74.999993, 151.500011) rotate(-270.000000) translate(-74.999993, -151.500011) "
            />
            <rect
              id="Rectangle-20"
              fill="#D8D8D8"
              x="124"
              y="199"
              width="10"
              height="87"
            />

            <g onClick={this.click.bind(this, 8)} className={this.status(8)}>
              <rect
                y="135.56442"
                x="94.976898"
                height="44.350449"
                width="48.952854"
                id="rect864"
                className="rectangle"
              />
              <rect
                id="Rectangle-4"
                fill="#83BEAB"
                x="99"
                y="143"
                className="chi"
                width="43"
                height="13"
              />
              <circle
                id="Oval-Copy-20-6"
                cx="110.87611"
                cy="164.85246"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="Oval-Copy-20-06"
                cx="131.37773"
                cy="164.43405"
                className="eclipse chi"
                r="6.5564036"
              />
            </g>
            <g onClick={this.click.bind(this, 0)} className={this.status(0)}>
              <rect
                y="212.55011"
                x="12.970414"
                height="44.768848"
                width="53.136856"
                id="rect858"
                className="rectangle"
              />
              <circle
                id="Oval-Copy-20-93"
                cx="46.442448"
                cy="238.07254"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M58.405509,226.341952 C38.6142883,223.147148 28.1523123,226.004823 27.0195811,234.914976 C25.8868498,243.825129 36.3488258,248.511826 58.405509,248.975066 L58.405509,256.527592 C31.0706926,255.739223 17.7701038,248.535018 18.5037427,234.914976 C19.2373816,221.294934 32.5379704,216.150638 58.405509,219.482086 L58.405509,226.341952 Z"
                id="Path-2"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 9)} className={this.status(9)}>
              <rect
                y="200.8349"
                x="87.445694"
                height="92.884903"
                width="37.65604"
                id="rect860"
                className="rectangle"
              />
              <circle
                id="Oval-Copy-20-26"
                cx="103.34491"
                cy="218.82613"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="Oval-Copy-20-18"
                cx="102.50811"
                cy="236.39894"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="Oval-Copy-20-7"
                cx="102.08971"
                cy="252.29816"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="Oval-Copy-20-92"
                cx="102.92651"
                cy="270.28937"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M124,269 L124,282 L113,282 L113,269 L113,256 L113,243 L113,230 L113,217 L113,204 L124,204 L124,217 L124,230 L124,243 L124,256 L124,269 Z"
                id="path12"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 1)} className={this.status(1)}>
              <rect
                y="269.03418"
                x="10.460011"
                height="42.258446"
                width="58.576061"
                id="rect856"
                className="rectangle"
              />
              <circle
                id="Oval-Copy-20-0"
                cx="46.024048"
                cy="287.86218"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M58.6025627,278.635735 C38.9738959,275.524944 28.5978487,278.307471 27.4744211,286.983316 C26.3509934,295.659162 36.7270406,300.222614 58.6025627,300.673673 L58.6025627,308.027592 C31.492259,307.259954 18.3009138,300.245196 19.028527,286.983316 C19.7561402,273.721437 32.9474854,268.712418 58.6025627,271.95626 L58.6025627,278.635735 Z"
                id="Path-2-Copy"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 2)} className={this.status(2)}>
              <rect
                y="335.55984"
                x="10.878411"
                height="41.003242"
                width="58.994465"
                id="rect854"
                className="rectangle"
              />
              <circle
                id="Oval-Copy-20-27"
                cx="45.187248"
                cy="356.89825"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M59.0058077,345.341952 C58.0541014,351.131358 28.8726027,340.549747 27.147958,353.914976 C25.9981949,362.825129 36.6174782,367.511826 59.0058077,367.975066 C59.4173683,371.24256 59.4173683,373.760069 59.0058077,375.527592 C58.3884667,378.178877 17.3870698,374.345038 18.5040748,353.914976 C19.2487447,340.294934 32.7493223,335.150638 59.0058077,338.482086 C59.6402785,339.195726 59.6402785,341.482348 59.0058077,345.341952 Z"
                id="Path-2-Copy-2"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 3)} className={this.status(3)}>
              <rect
                y="382.42068"
                x="5.8576064"
                height="77.822487"
                width="76.14888"
                id="rect852"
                className="rectangle"
              />
              <circle
                id="Oval-Copy-20-9"
                cx="46.442448"
                cy="410.03513"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="Oval-Copy-20-1"
                cx="61.086464"
                cy="425.51593"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M47.2213561,385.526223 C27.1119503,388.765491 7.75096283,393.931954 16.1762784,414.231811 C17.8732803,418.320547 23.6304774,423.654339 26.269877,426.094743 C33.0217396,432.337554 44.7052421,445.79216 53.5595851,452.582096 C63.1063811,459.903038 68.727091,449.092777 68.5577965,445.79216 C68.1458081,437.759914 57.9046175,441.581089 52.9957744,440.17967 C42.3349447,437.136125 40.0418295,430.675073 38.9138634,427.320242 C36.5215686,420.205006 31.2946422,420.600012 28.3551125,414.231811 C25.4155827,407.863609 25.5097738,401.940735 37.4145725,399.857136 C49.3193713,397.773536 53.9562973,396.745563 52.9957744,390.282774 C52.4748074,386.777496 50.9020102,384.933335 47.2213561,385.526223 Z"
                id="Path-4"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 7)} className={this.status(7)}>
              <rect
                y="197.06929"
                x="129.28574"
                height="89.119293"
                width="33.053635"
                id="rect862"
                className="rectangle"
              />
              <ellipse
                id="Oval-Copy-20-02"
                cx="151.67017"
                cy="225.93892"
                className="eclipse chi"
                rx="2.9999998"
                ry="15.761213"
              />
              <ellipse
                id="Oval-Copy-20-02-5"
                cx="151.87936"
                cy="262.33975"
                className="eclipse chi"
                rx="2.9999998"
                ry="15.761213"
              />
              <path
                d="M134,217 L134,204 L145,204 L145,217 L145,230 L145,243 L145,256 L145,269 L145,282 L134,282 L134,269 L134,256 L134,243 L134,230 L134,217 Z"
                id="path14"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 4)} className={this.status(4)}>
              <rect
                y="292.883"
                x="154.47299"
                height="54.392059"
                width="64.727238"
                id="rect866"
                className="rectangle"
              />
              <circle
                id="jowie"
                cx="175.024048"
                cy="310.86218"
                className="eclipse chi"
                r="6.5564036"
              />
              <circle
                id="jowie2"
                cx="175.024048"
                cy="325.86218"
                className="eclipse chi"
                r="6.5564036"
              />
              <path
                d="M194,306 L194,310 L194,325 L194,331 L194,337 L183,337 L183,331 L183,325 L183,310 L183,306 L183,300 L194,300 L194,306 Z"
                id="Combined-Shape-Copy-3"
                className="chi"
                fill="#83BEAB"
              />
            </g>
            <g onClick={this.click.bind(this, 5)} className={this.status(5)}>
              <rect
                y="344.34625"
                x="247.27466"
                height="44.350449"
                width="56.484062"
                id="rect868"
                className="rectangle"
              />
              <path
                d="M267,372 L267,354 L286,354 L286,372 L286,386 L267,386 L267,372 Z"
                id="path80"
                className="chi"
                fill="#83BEAB"
              />
              <circle
                id="Oval-Copy-20"
                cx="255.97482"
                cy="370.67798"
                className="eclipse chi"
                r="6.5564036"
              />
            </g>
            <g onClick={this.click.bind(this, 6)} className={this.status(6)}>
              <rect
                y="391.62549"
                x="207.52663"
                height="75.730484"
                width="80.332886"
                id="rect870"
                className="rectangle"
              />
              <ellipse
                id="Oval-Copy-20-5"
                cx="233.46745"
                cy="430.53674"
                className="eclipse chi"
                rx="6.9748039"
                ry="6.5564036"
              />
              <ellipse
                id="Oval-Copy-20-3"
                cx="249.36667"
                cy="435.13916"
                className="eclipse chi"
                rx="6.9748039"
                ry="6.5564036"
              />
              <ellipse
                id="Oval-Copy-20-56"
                cx="258.15308"
                cy="424.26074"
                className="eclipse chi"
                rx="6.9748039"
                ry="6.5564036"
              />
              <ellipse
                id="Oval-Copy-20-2"
                cx="256.06107"
                cy="410.87192"
                className="eclipse chi"
                rx="6.9748039"
                ry="6.5564036"
              />
              <path
                d="M269,450 L269,403 L258,403 L258,390 L283,390 L283,403 L283,466 L269,466 L209,466 L209,457 L209,417 L225,417 L225,450 L269,450 Z"
                id="path46"
                className="chi"
                fill="#83BEAB"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  renderKartFørste() {
    return (
      <svg
        style={styles.svg1}
        width="300px"
        height="489px"
        viewBox="0 0 300 489"
        version="1.1"
        id="svg113"
      >
        <g id="Page-1">
          <g id="Group-3">
            <path
              d="m 188.41405,301.60937 c -0.0469,0.0469 -0.10938,0.0703 -0.1875,0.0703 -0.0781,0 -0.14844,-0.0234 -0.21094,-0.0703 l -4.89842,-4.92186 c -0.0625,-0.0469 -0.0937,-0.10938 -0.0937,-0.1875 0,-0.0781 0.0312,-0.14062 0.0937,-0.1875 l 4.89842,-4.92186 c 0.0625,-0.0469 0.13281,-0.0703 0.21094,-0.0703 0.0781,0 0.14062,0.0234 0.1875,0.0703 l 0.46875,0.46875 c 0.0625,0.0469 0.0937,0.10937 0.0937,0.1875 0,0.0781 -0.0312,0.14843 -0.0937,0.21093 l -4.24218,4.24218 4.24218,4.24218 c 0.0625,0.0625 0.0937,0.13281 0.0937,0.21093 0,0.0781 -0.0312,0.14063 -0.0937,0.1875 z"
              id="chevron-left"
            />
            <path
              d="m 3,170 v 46.78075 h 11.178214 v 80.2258 H 8.151375 v 19.33653 h 41.834558 v 5.75726 h 3.596497 v 66.14206 l -3.596497,3.64143 19.761335,21.39926 -29.637918,26.40291 26.400866,25.78 c 13.83593,3.22548 22.564057,4.83822 26.184381,4.83822 3.620324,0 39.865093,1.77953 108.734313,5.33858 h 41.68139 l 8.56134,-110.87873 -70.10414,-4.54821 v -6.96017 h 7.47892 v -31.15535 l 4.7504,-2.45077 v -42.95578 -25.45324 h -6.98468 V 235.2248 h -9.24551 v -27.78759 h 16.23019 V 166 H 91 V 103.64323 H 72 V 170 Z m -3,0 V 103 H 0.39470391 V 0.16172488 L 201.42891,11.233056 V 265.03836 l 98.12046,6.807 L 283.27833,488.93003 40.10935,475.6428 0.39470391,436.20387 V 436 H 0 Z"
              id="Combined-Shape"
              className="out"
            />
            <rect
              id="Rectangle-26"
              x="118"
              y="178"
              width="76"
              height="17"
              className="black"
            />
            <path
              d="m 51,211 v 93.5 H 34 V 227 H 14 v -17 h 37 z"
              id="path11"
              className="black"
            />

            <path
              d="m 184.41405,342.60937 c -0.0469,0.0469 -0.10938,0.0703 -0.1875,0.0703 -0.0781,0 -0.14844,-0.0234 -0.21094,-0.0703 l -4.89842,-4.92186 c -0.0625,-0.0469 -0.0937,-0.10938 -0.0937,-0.1875 0,-0.0781 0.0312,-0.14062 0.0937,-0.1875 l 4.89842,-4.92186 c 0.0625,-0.0469 0.13281,-0.0703 0.21094,-0.0703 0.0781,0 0.14062,0.0234 0.1875,0.0703 l 0.46875,0.46875 c 0.0625,0.0469 0.0937,0.10937 0.0937,0.1875 0,0.0781 -0.0312,0.14843 -0.0937,0.21093 l -4.24218,4.24218 4.24218,4.24218 c 0.0625,0.0625 0.0937,0.13281 0.0937,0.21093 0,0.0781 -0.0312,0.14063 -0.0937,0.1875 z"
              id="path91"
              transform="rotate(-180,181.99999,337.50001)"
            />
            <path
              d="m 67.131564,403.82689 c -0.04687,0.0469 -0.109375,0.0703 -0.1875,0.0703 -0.07812,0 -0.148437,-0.0234 -0.210937,-0.0703 l -4.898425,-4.92186 c -0.0625,-0.0469 -0.09375,-0.10938 -0.09375,-0.1875 0,-0.0781 0.03125,-0.14063 0.09375,-0.1875 l 4.898425,-4.92187 c 0.0625,-0.0469 0.132813,-0.0703 0.210937,-0.0703 0.07813,0 0.140625,0.0234 0.1875,0.0703 l 0.468749,0.46875 c 0.0625,0.0469 0.09375,0.10938 0.09375,0.1875 0,0.0781 -0.03125,0.14844 -0.09375,0.21094 l -4.242177,4.24218 4.242177,4.24217 c 0.0625,0.0625 0.09375,0.13281 0.09375,0.21094 0,0.0781 -0.03125,0.14062 -0.09375,0.1875 z"
              id="path106"
              transform="rotate(-45,64.717507,398.71753)"
            />
            <path
              d="m 10.41405,203.60937 c -0.04687,0.0469 -0.109375,0.0703 -0.1875,0.0703 -0.07813,0 -0.148437,-0.0234 -0.210937,-0.0703 l -4.8984258,-4.92186 c -0.0625,-0.0469 -0.09375,-0.10938 -0.09375,-0.1875 0,-0.0781 0.03125,-0.14062 0.09375,-0.1875 l 4.8984258,-4.92186 c 0.0625,-0.0469 0.132812,-0.0703 0.210937,-0.0703 0.07813,0 0.140625,0.0234 0.1875,0.0703 l 0.468749,0.46875 c 0.0625,0.0469 0.09375,0.10937 0.09375,0.1875 0,0.0781 -0.03125,0.14843 -0.09375,0.21093 l -4.2421779,4.24218 4.2421779,4.24218 c 0.0625,0.0625 0.09375,0.13281 0.09375,0.21093 0,0.0781 -0.03125,0.14063 -0.09375,0.1875 z"
              id="path108"
            />
            <rect
              id="Rectangle-30"
              transform="rotate(2,126,255)"
              x="123"
              y="216"
              width="6"
              height="78"
            />
          </g>
        </g>
        <g id="layer3" />
        <g>
          <g onClick={this.click.bind(this, 16)} className={this.status(16)}>
            <rect
              className="rectangle"
              id="rect2"
              width="70.433189"
              height="57.059803"
              x="189.90215"
              y="428.67114"
            />
            <ellipse
              id="Oval-Copy-64-3-5"
              cx="226.45609"
              cy="436.99234"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-3"
              cx="225.26733"
              cy="453.33762"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-56"
              cx="208.62489"
              cy="452.44604"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <path
              d="m 245.19309,445.31168 -1.71468,32.7179 -10.98492,-0.5757 1.72472,-32.90964 -32.8739,-2.29877 0.67058,-9.58978 44.01469,3.07781 -0.67059,9.58978 z"
              id="Combined-Shape-Copy"
              transform="rotate(90,223.68694,455.34263)"
              className="rect-dark chi"
            />
          </g>
          <g onClick={this.click.bind(this, 15)} className={this.status(15)}>
            <rect
              className="rectangle"
              id="rect1"
              width="62.409157"
              height="54.682308"
              x="197.33182"
              y="354.9689"
            />
            <ellipse
              id="Oval-Copy-64-3"
              cx="213.23129"
              cy="381.12128"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-6"
              cx="229.42793"
              cy="381.7157"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-7"
              cx="228.53639"
              cy="396.87219"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <path
              d="m 251.84878,373.65599 -1.71467,32.71789 -10.98493,-0.57569 1.72472,-32.90964 L 208,370.58978 208.67058,361 l 44.01469,3.07781 -0.67058,9.58978 z"
              id="path45"
              className="rect-dark chi"
            />
          </g>
          <g onClick={this.click.bind(this, 14)} className={this.status(14)}>
            <rect
              className="rectangle"
              id="rect3"
              width="73.442894"
              height="61.852623"
              x="76.65519"
              y="299.67331"
            />
            <ellipse
              id="Oval-Copy-64-3-2"
              cx="112.93087"
              cy="319.30652"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-9"
              cx="120.6577"
              cy="334.16586"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-1"
              cx="120.36052"
              cy="305.93314"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <path
              d="m 104.37271,342.48791 25.89321,1.357 -0.5757,10.98493 -31.95614,-1.67475 0.0011,-0.0208 L 93,352.96896 l 1.884573,-53.96711 10.993297,0.3839 z"
              id="path49"
              className="rect-dark chi"
            />
          </g>
          <g onClick={this.click.bind(this, 13)} className={this.status(13)}>
            <rect
              className="rectangle"
              id="rect4"
              width="38.634239"
              height="41.011734"
              x="126.00706"
              y="256.60019"
            />
            <rect
              id="Rectangle-25-2"
              transform="matrix(0.99981203,0.01938815,-0.06276138,0.99802856,0,0)"
              x="147.09503"
              y="257.6922"
              width="6.3238111"
              height="37.692219"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-7"
              cx="143.83824"
              cy="291.07382"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-0"
              cx="144.13544"
              cy="274.72855"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
          </g>
          <g onClick={this.click.bind(this, 11)} className={this.status(11)}>
            <rect
              className="rectangle"
              id="rect5"
              width="31.204578"
              height="67.758514"
              x="92.722176"
              y="218.26312"
            />
            <rect
              id="Rectangle-25-Copy"
              transform="rotate(2,118.51003,251.10663)"
              x="115.01003"
              y="222.10448"
              width="7"
              height="58.004295"
              className="rect-dark chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-6"
              cx="105.79838"
              cy="232.2309"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-06"
              cx="104.90682"
              cy="250.95364"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-2"
              cx="104.31245"
              cy="269.37921"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
          </g>
          <g onClick={this.click.bind(this, 12)} className={this.status(12)}>
            <rect
              className="rectangle"
              id="rect6"
              width="31.798952"
              height="41.903294"
              x="127.493"
              y="206.67285"
            />
            <rect
              id="Rectangle-25"
              transform="matrix(0.99985821,0.01683938,-0.07221782,0.99738888,0,0)"
              x="147.64958"
              y="209.36163"
              width="6.9967279"
              height="36.243996"
              className="rect-dark chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-9"
              cx="144.7298"
              cy="239.36336"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-3"
              cx="146.8101"
              cy="222.42374"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
          </g>
          <g onClick={this.click.bind(this, 10)} className={this.status(10)}>
            <rect
              className="rectangle"
              id="rect7"
              width="71.027565"
              height="34.770817"
              x="2.3774917"
              y="164.17519"
            />
            <ellipse
              id="Oval-Copy-64-3-2-61"
              cx="19.31712"
              cy="188.54448"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <ellipse
              id="Oval-Copy-64-3-2-8"
              cx="48.441391"
              cy="188.54448"
              rx="6.1204576"
              ry="5.9718642"
              className="eclipse chi"
            />
            <rect
              id="Rectangle-25-Copy-2"
              transform="rotate(-90,35.251074,174.8066)"
              x="30.444475"
              y="141.55553"
              width="9.6131983"
              height="66.502144"
              className="rect-dark chi"
            />
          </g>
        </g>
      </svg>
    );
  }

  renderKartKjeller() {
    return (
      <svg
        style={styles.svg}
        width="295.34949"
        height="179.51082"
        viewBox="0 0 295.34949 179.51082"
        version="1.1"
        id="svg48"
      >
        <g
          id="Page-1"
          transform="matrix(1,0,0,0.97520898,-31.311207,-324.31684)"
        >
          <g id="Group-2" transform="translate(-17.80303,10.479798)">
            <path
              d="m 205.48936,456.50048 -1.40549,26.81836 -10.98492,-0.57569 1.40549,-26.81837 -84.88351,-4.44856 0.88971,-16.9767 95.86843,5.02425 z"
              id="Combined-Shape"
            />

            <path d="" id="Path-9" />

            <path
              d="m 215.26831,385.9848 c -0.0469,0.0469 -0.10937,0.0703 -0.1875,0.0703 -0.0781,0 -0.14844,-0.0234 -0.21094,-0.0703 l -4.89842,-4.92186 c -0.0625,-0.0469 -0.0937,-0.10937 -0.0937,-0.1875 0,-0.0781 0.0312,-0.14062 0.0937,-0.1875 l 4.89842,-4.92186 c 0.0625,-0.0469 0.13282,-0.0703 0.21094,-0.0703 0.0781,0 0.14063,0.0234 0.1875,0.0703 l 0.46875,0.46875 c 0.0625,0.0469 0.0937,0.10937 0.0937,0.1875 0,0.0781 -0.0312,0.14843 -0.0937,0.21093 l -4.24218,4.24218 4.24218,4.24218 c 0.0625,0.0625 0.0937,0.13281 0.0937,0.21093 0,0.0781 -0.0312,0.14063 -0.0937,0.1875 z"
              id="chevron-left"
              transform="rotate(95,212.85425,380.87544)"
            />

            <path
              d="m 305.71398,442.25952 1.59357,9.89855 c 6.37354,10.90534 8.73503,19.16841 7.08449,24.78921 -2.47581,8.4312 -11.01531,11.96793 -11.01531,11.96793 -12.09598,3.23451 -18.39901,3.64345 -18.90908,1.2268 -0.51007,-2.41665 -5.12706,-2.39512 -7.54367,0.0215 l -0.0805,-18.49182 c 0.48383,2.35731 4.50504,1.38606 5.24549,3.14685 1.31733,3.13261 3.04078,5.32117 5.17035,6.56566 2.89799,1.69354 6.54806,1.63881 10.95023,-0.1642 9.45535,-3.87266 11.84927,-10.31505 7.18176,-19.32717 l -4.19956,-9.73479 -1.0669,-7.5153"
              id="Path-12-5"
              className="lol"
            />
            <path
              d="M 240.23968,375.71631 99,368.16049 V 451 477.83773 l 138.7898,9.09073 2.74158,-27.15111 10.30262,18.96723 23.70645,6.67131 27.24636,2.65772 c 7.67591,-5.48605 11.51386,-10.65286 11.51386,-15.50044 0,-7.27136 -8.09823,-18.67092 -8.09823,-21.57342 0,-1.93501 0.45971,-7.72489 1.37913,-17.36964 11.21735,-10.69414 15.68912,-20.08738 13.4153,-28.17972 -2.86715,-10.20393 -20.22048,-17.33197 -25.67905,-19.34485 l -0.0591,0.0348 c -12.5249,-0.83779 -24.589,-1.6559 -36.19229,-2.45434 -11.6033,-0.79844 -18.20291,0.52018 -19.79886,3.95586 z M 64,451 H 50 L 49.114237,322.08158 c 295.349493,1.72863 0,0 295.349493,1.72863 L 332.88105,506.15578 H 319.35828 L 89.313766,492.32424 50,451.0005 h 14 z"
              id="Rectangle-30"
            />
          </g>
          <g id="layer2" />
        </g>
        <g id="layer3" transform="translate(-6.5098378,-8.5066179)" />
        <g>
          <g onClick={this.click.bind(this, 17)} className={this.status(17)}>
            <path
              d="m 277.73822,491.93412 c -7.70016,-0.51288 -12.0757,-1.53175 -13.12662,-3.05662 -1.57638,-2.28731 -15.4109,-7.32638 -15.4109,-8.73224 0,-1.40587 -10.86446,-15.38835 -8.96066,-18.55205 1.26921,-2.10914 3.25768,-3.1637 5.96542,-3.1637 4.60066,13.53189 9.72409,19.78978 15.37029,18.77365 5.6462,-1.01613 9.23507,-3.27267 10.76662,-6.76963 h 5.57076"
              id="Path-12"
              className="lol chi"
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-62"
              className="eclipse chi"
              cy="460.66162"
              cx="255.68182"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-61"
              className="eclipse chi"
              cy="467.10101"
              cx="264.39392"
              rx="3.7575755"
              ry="3.6313131"
            />
            <rect
              className="rectangle"
              id="rect4873"
              width="44.246449"
              height="49.561028"
              x="186.03114"
              y="131.16304"
            />
          </g>
          <g onClick={this.click.bind(this, 18)} className={this.status(18)}>
            <rect
              className="rectangle"
              id="rect4871"
              width="49.196163"
              height="51.649925"
              x="235.13382"
              transform="translate(-5,-6.479798)"
              y="125.39467"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-93"
              className="eclipse chi"
              cy="465.08081"
              cx="298.35858"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-60"
              className="eclipse chi"
              cy="472.15152"
              cx="289.89899"
              rx="3.7575755"
              ry="3.6313131"
            />
            <path
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              d="m 305.71398,442.25952 1.59357,9.89855 c 6.37354,10.90534 8.73503,19.16841 7.08449,24.78921 -2.47581,8.4312 -11.01531,11.96793 -11.01531,11.96793 -12.09598,3.23451 -18.39901,3.64345 -18.90908,1.2268 -0.51007,-2.41665 -5.12706,-2.39512 -7.54367,0.0215 l -0.0805,-18.49182 c 0.48383,2.35731 4.50504,1.38606 5.24549,3.14685 1.31733,3.13261 3.04078,5.32117 5.17035,6.56566 2.89799,1.69354 6.54806,1.63881 10.95023,-0.1642 9.45535,-3.87266 11.84927,-10.31505 7.18176,-19.32717 l -4.19956,-9.73479 -1.0669,-7.5153"
              id="Path-12-5"
              className="lol chi"
            />
          </g>
          <g onClick={this.click.bind(this, 19)} className={this.status(19)}>
            <rect
              transform="translate(-5,-6.479798)"
              className="rectangle"
              id="rect4869"
              width="48.339664"
              height="54.980671"
              x="235.99033"
              y="60.217773"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-27"
              className="eclipse chi"
              cy="403.59091"
              cx="290.5303"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-0"
              className="eclipse chi"
              cy="413.06058"
              cx="299.74747"
              rx="3.7575755"
              ry="3.6313131"
            />
            <path
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              d="m 276.11301,376.75984 c 4.32122,4.89304 11.75641,7.4543 18.51768,8.94641 10.1419,2.23816 30.86858,15.36588 26.76429,26.24682 -4.10429,10.88094 -5.44965,13.56372 -14.81341,21.67704 -1.1401,4.90762 1.36412,13.32228 -6.4033,13.13135 l 1.12778,-19.2562 c 9.63318,-4.17616 14.05668,-8.71732 13.27052,-13.62349 -1.17925,-7.35925 -2.22212,-16.92557 -18.43773,-20.26074 -10.81041,-2.22345 -19.24642,-5.29653 -17.89228,3.96208"
              id="Path-12-7"
              className="lol chi"
            />
          </g>
          <g onClick={this.click.bind(this, 20)} className={this.status(20)}>
            <rect
              transform="translate(-5,-6.479798)"
              className="rectangle"
              id="rect4867"
              width="42.516632"
              height="50.488503"
              x="189.55333"
              y="45.223145"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-9"
              className="eclipse chi"
              cy="400.68689"
              cx="256.43936"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-1"
              className="eclipse chi"
              cy="400.81314"
              cx="266.91922"
              rx="3.7575755"
              ry="3.6313131"
            />
            <path
              transform="matrix(1,0,0,0.97520898,-32.114237,-323.837042)"
              d="m 261.79179,408.98798 -4.7775,-0.17863 c -3.84792,-7.0514 -8.93658,-10.5771 -15.266,-10.5771 -6.32941,0 -9.49412,3.5257 -9.49412,10.5771 -10.05767,0.87441 -14.73989,-0.4967 -14.04665,-4.11332 1.03986,-5.42492 2.16108,-9.76486 6.9898,-11.11257 4.82872,-1.34771 33.67408,-18.95418 35.59437,-7.99637"
              id="Path-12-3"
              className="Path-12-3 chi"
            />
          </g>
          <g onClick={this.click.bind(this, 21)} className={this.status(21)}>
            <rect
              transform="translate(-5,-6.479798)"
              className="rectangle"
              id="rect4865"
              width="43.402397"
              height="40.745106"
              x="100.97701"
              y="39.022797"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-6"
              className="eclipse chi"
              cy="385.40909"
              cx="153.78787"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-2"
              className="eclipse chi"
              cy="387.05048"
              cx="172.9798"
              rx="3.7575755"
              ry="3.6313131"
            />
            <rect
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Rectangle-21-1"
              className="eclipse chi"
              x="145"
              y="371"
              width="39"
              height="10"
            />
          </g>
          <g onClick={this.click.bind(this, 22)} className={this.status(22)}>
            <rect
              transform="translate(-5,-6.479798)"
              className="rectangle"
              id="rect4863"
              width="39.625038"
              height="56.521599"
              x="56.037395"
              y="52.476501"
            />
            <path
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              d="M 137.07333,384.94217 V 369.7976 l -38.431543,-2.43158 v 50.81225 l 10.667923,3.18775 2.97144,-6.51998 -7.3817,-1.68554 c -0.0684,-23.53119 0.32142,-36.01465 1.16934,-37.45039 1.27188,-2.15361 1.01751,-4.30722 24.66897,-2.15361 v 11.38567 z"
              id="Path-10"
              className="beab chi"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              id="Oval-Copy-27-5"
              cy="398.79294"
              cx="112.5"
              className="eclipse chi"
              rx="3.7575755"
              ry="3.6313131"
            />
            <ellipse
              transform="matrix(1,0,0,0.97520898,-49.114237,-313.837042)"
              className="eclipse chi"
              id="Oval-Copy-27"
              cy="381.73233"
              cx="121.13637"
              rx="3.7575755"
              ry="3.6313131"
            />
          </g>
        </g>
      </svg>
    );
  }

  renderSelected() {
    switch (this.state.kart) {
      case 2:
        return this.renderKartAndre();
      case 1:
        return this.renderKartFørste();
      case 0:
        return this.renderKartKjeller();
      default:
        return this.renderKartAndre();
    }
  }

  disabled(bool) {
    if (bool) {
      return {
        background: "#757575"
      };
    } else {
      return {};
    }
  }

  render() {
    return (
      <div>
        <div style={styles.cont}>{this.renderSelected()}</div>
        <button
          disabled={this.state.kart === 0}
          onClick={this.switch.bind(this, 0)}
          style={{
            ...styles.button,
            ...{ marginLeft: "0px" },
            ...this.disabled(this.state.kart === 0)
          }}
          className="save"
        >
          K
        </button>
        <button
          disabled={this.state.kart === 1}
          onClick={this.switch.bind(this, 1)}
          style={{ ...styles.button, ...this.disabled(this.state.kart === 1) }}
          className="save"
        >
          1.
        </button>
        <button
          disabled={this.state.kart === 2}
          onClick={this.switch.bind(this, 2)}
          style={{ ...styles.button, ...this.disabled(this.state.kart === 2) }}
          className="save"
        >
          2.
        </button>
      </div>
    );
  }
}

const styles = {
  button: {
    width: "50px",
    marginBottom: "20px",
    marginTop: "20px",
    marginRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: "18px",
    borderRadius: "8px",
    height: "50px"
  },
  cont: {
    height: "400px",
    width: "500px",
    maxWidth: "95%",
    maxHeight: "90vh",
    display: "flex",
    alignItems: "flex-end",
    flexShrink: ""
  },
  svg: {
    transformOrigin: "50% 50%",
    transform: "translateY(-60px) rotate(-90deg) scale(1.4)",
    height: "350px"
  },
  svg1: {
    transformOrigin: "50% 50%",
    transform: "translateY(-60px) rotate(-90deg) scale(1.0)",
    height: "350px"
  },
  svg2: {
    transformOrigin: "50% 50%",
    transform: "translateY(-60px) rotate(-90deg) scale(1.0)",
    height: "350px"
  }
};
