import React, { Component } from "react"
import "./Dropdown.css"

class Dropdown extends Component {
    render() {
        return (
            <select className={"select_box"} onChange={e => this.props.handleChange(e.target.value)}>
                {this.props.values.map((value, i) => {
                    return (
                        <option key={value.key} value={value.key}>
                            {value.name}
                        </option>
                    )
                })}
            </select>
        )
    }
}

export default Dropdown
