import axios from 'axios';
import url from '../../url.js';

const requestEmployeesFailure = (message) => {
    return {
    type: 'REQUEST_EMPLOYEES_FAILURE',
    message,
  }
};

const requestEmployeesSuccess = (employees) => {
    return {
    type: 'REQUEST_EMPLOYEES_SUCCESS',
    employees,
  }
};

const requestEmployees = () => {
  return {
    type: 'REQUEST_EMPLOYEES',
  }
};

const fetchEmployees = (token, search = '') => {
  console.log('asd');
  return dispatch => {
    dispatch(requestEmployees())
    return axios.get(url + 'admin/employees?search='+ search, {
      headers: {Authorization: token},
    }).then(response => {
      dispatch(requestEmployeesSuccess(response.data.employees));
    }
      )
      .catch(error => {
        dispatch(requestEmployeesFailure(error.response.data));
      })
  }
}

export const getEmployees = (searchString) => {
  return (dispatch, getState) => {
    return dispatch(fetchEmployees(window.localStorage.getItem('token'), searchString));
  }
}

const requestEmployeeFailure = (message) => {
    return {
    type: 'REQUEST_EMPLOYEE_FAILURE',
    message,
  }
};

const requestEmployeeSuccess = (employee) => {
  console.log('runs');
    return {
    type: 'REQUEST_EMPLOYEE_SUCCESS',
    employee,
  }
};

const requestEmployee = () => {
  return {
    type: 'REQUEST_EMPLOYEE',
  }
};

const fetchEmployee = (token, id) => {
  console.log('asdsss');
  return dispatch => {
    dispatch(requestEmployee())
    return axios.get(url + 'admin/employees/'+ id, {
      headers: {Authorization: token},
    }).then(response => {
      console.log('asdsss');
      console.log(response);
      console.log('asd');
      dispatch(requestEmployeeSuccess(response.data));
    }
      )
      .catch(error => {
        dispatch(requestEmployeeFailure(error.response.data));
      })
  }
}

export const getEmployee = (id) => {
  return (dispatch, getState) => {
    return dispatch(fetchEmployee(window.localStorage.getItem('token'), id));
  }
}
