import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import axios from 'axios';
import url from '../../../url.js';
import * as actions from '../../../store/actions/employees.js';
import './employee.css';

class EmployeesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      change: {
        name: '',
        username: '',
        password: '',
      },
      search: '',
    }
    this.onClick = this.onClick.bind(this);
    this.add = this.add.bind(this);
    this.newUser = this.newUser.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  componentDidMount() {
    this.props.onMount();
  }

  onClick(id, e) {
    e.preventDefault();
    console.log(id);
    this.props.onEmployeeClick(id)
  }

  handleSearchChange(e) {
    e.preventDefault();
    this.setState({search: e.target.value});
  }

  next() {
    console.log('asd')
  }

  prev() {
    console.log('asd')
  }

  renderEmployees() {
    return this.props.employees.map((employee) => {
      return (
        <tr value={employee._id} key={employee._id} onClick={this.onClick.bind(null, employee._id)}>
          <td>{employee.name}</td>
          <td>{employee.username}</td>
        </tr>)
    })
  }

  renderTable() {
    
      if(this.props.access && this.props.access.employees.write){
        return(
      <table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Brukernavn</th>
          </tr>
        </thead>
        <tbody>
          {this.renderEmployees()}
        </tbody>
      </table>);
      } else {
        return "Du har ikke rettigheter til funksjonaliteten denne siden tilbyr";
      }
  }

  changeValue(key, e) {
    e.preventDefault();
    if(this.state.add) {
      const current = this.state.change;
      current[key] = e.target.value;
      this.setState({change: current});
    }
  }

  add() {
    this.setState({
      add: !this.state.add,
    })
  }

  newUser(e) {
    e.preventDefault();
    if(this.state.change.username.length > 0 &&
      this.state.change.name.length > 0 &&
      this.state.change.password.length) {

      const info = {
        name: this.state.change.name,
        username: this.state.change.username,
        password: this.state.change.password,
      }
      this.setState({fetching: true});
      const token = window.localStorage.getItem('token');
      const changes = this.state.change;
      delete changes._id;
      axios.post(url + 'admin/employees/', info, {
        headers: {Authorization: token},
      }).then(response => {
        this.setState({
          change: {
            name: '',
            username: '',
            password: '',
          },
          add: false,
        });
        this.props.onMount();

      })
      .catch(error => {
        this.setState({error: true, fetching: false})
      })
    }
  }

  disabled(bool) {
      if(bool) {
          return {
              background: '#757575',
          }
      } else {
          {}
      }
  }

  checkChange() {
    if(this.state.change.name.length < 1 ||
     this.state.change.username.length < 1 ||
     this.state.change.password.lenth < 1 ) {
      return true;
    } else {
      return false;
    }
  }


  newForm() {
    if(this.state.add) {
      return (
        <div style={styles.del}>
          <div style={styles.delCard}>
            <h2>Ny bruker</h2>
            <div>
            <form onSubmit={this.newUser}>
              <input placeholder="Navn" onChange={this.changeValue.bind(this, 'name')} type="text" className="add-inputE"  value={this.state.change.name} required/>
              <input placeholder="Brukernavn" onChange={this.changeValue.bind(this, 'username')} type="text" className="add-inputE"  value={this.state.change.username} required/>
              <input placeholder="Passord" onChange={this.changeValue.bind(this, 'password')} type="text" className="add-inputE"  value={this.state.change.password} required/>
            </form>
            <div style={styles.delCont}>
              <button onClick={(e) =>{ e.preventDefault(); this.setState({add: false})} } style={styles.button} className="change">Avbryt</button>
              <button  disabled={this.checkChange()} onClick={this.newUser} style={{...styles.button, ...this.disabled(this.checkChange())}} className="save">Legg til</button>
            </div>
            </div>
          </div>
        </div>
      )
    }
    return '';
  }

  render() {
    return (
      <div>
        <h1>Ansatte</h1>
        {this.props.access && this.props.access.employees.write ?
        (<button onClick={this.add} style={{...styles.button, ...{marginLeft: '0px'}}} className="save">Legg til ny</button>) : 
        ("")
        }
        {this.newForm()}
        <div style={{overflowX: 'auto'}}>
          {this.props.loading ? <p>Loading</p> : this.renderTable()}
        </div>
      </div>
    );
  }
}

const styles = {
  button: {
    width: '120px',
    marginBottom: '20px',
    marginTop: '20px',
    marginRight: '10px',
    marginLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '18px',
    borderRadius: '8px',

  },
  del: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000070',
    top: 0,
    left: 0,
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  delCard: {
    flex: '',
    height: '400px',
    width: '90%',
    maxWidth: '400px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '5px 5px 5px 5px',
    textAlign: 'center',
  },
  delCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%'
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.employees.data,
    error: state.employees.error.active,
    access: state.login.user.info.permissions,
    loading: state.employees.isFetching,
    message: state.employees.error.message,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: () => {
      dispatch(actions.getEmployees());
    },
    onEmployeeClick: (id) => {
      dispatch(push(`/admin/employees/${id}`));
    }
  }
}

const EmployeesContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(EmployeesList)


export default EmployeesContainer;
