import initialState from '../initialState.js';

const error = (state = initialState.error, action) => {
  switch(action.type) {
    case 'SHOW_ERROR':
      return Object.assign({}, state, {
        active: true,
        message: action.message,
      })
    case 'HIDE_ERROR':
      return Object.assign({}, state, {
        error: false,
        message: '',
      });
    default:
      return initialState.error
  }
}

export default error;
