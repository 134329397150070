import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import axios from "axios";
import url from "../../../url.js";
import * as actions from "../../../store/actions/products.js";
import "./Product.css";
import Switch from "../Switch.js";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      change: {
        name: "",
        description: "",
        price: "",
        requiresReservation: false,
        unit: "pcs",
        taxRate: "",
        picture: "",
        mobile: false
      }
    };
    this.onClick = this.onClick.bind(this);
    this.add = this.add.bind(this);
    this.newProduct = this.newProduct.bind(this);
    this.resizedataURL = this.resizedataURL.bind(this);
    this.encodeImageFileAsURL = this.encodeImageFileAsURL.bind(this);
    this.requiresReservation = this.requiresReservation.bind(this);
    this.click = this.click.bind(this);
  }
  componentDidMount() {
    this.props.onMount();
  }

  onClick(id, e) {
    e.preventDefault();
    console.log(id);
    this.props.onProductClick(id);
  }

  click() {
    const current = this.state.change;
    current.requiresReservation = !current.requiresReservation;
    this.setState({ change: current });
  }

  smaller(picture, wantedWidth, wantedHeight, img) {
    // We create a canvas and get its context.
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    // We set the dimensions at the wanted size.
    canvas.width = wantedWidth;
    canvas.height = wantedHeight;
    console.log(picture);
    // We resize the image with the canvas method drawImage();
    ctx.drawImage(picture, 0, 0, wantedWidth, wantedHeight);

    var dataURI = canvas.toDataURL("image/jpeg", 0.8);
    console.log(dataURI);
    /////////////////////////////////////////
    // Use and treat your Data URI here !! //
    /////////////////////////////////////////
    /*
      this.parent.setState({
          imagePreviewUrl: dataURI,
          image: dataURI,
      });
      */
    if (this.state.add) {
      const current = this.state.change;
      current["picture"] = dataURI;
      this.setState({ change: current });
    }
  }

  resizedataURL(datas, wantedWidth, wantedHeight) {
    // We create an image to receive the Data URI
    var img = document.createElement("img");

    img.parent = this;
    // When the event "onload" is triggered we can resize the image.
    img.onload = this.smaller.bind(this, img, wantedWidth, wantedHeight);

    // We put the Data URI in the image's src attribute
    img.src = datas;
  }

  encodeImageFileAsURL(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.resizedataURL(reader.result, 350, 350);
    };
    reader.readAsDataURL(file);
  }

  renderProducts() {
    return this.props.products.map(product => {
      const requiresReservation = product.requiresReservation;
      var a = "";
      if (requiresReservation) {
        a += "Krever reservasjon";
      } else {
        a += "Krever ikke reservasjon";
      }
      return (
        <div className="card" onClick={this.onClick.bind(null, product._id)}>
          <img className="picture" src={product.picture} alt="avatar" />

          <h1 className="prodName">{product.name}</h1>

          <p className="title">{product.description}</p>

          <p className="price">{product.price} NOK</p>
          <p className="taxRate">Skattesats: {product.taxRate}</p>
          <p className="requiresReservation">{a}</p>
        </div>
      );
    });
  }

  changeValue(key, e) {
    e.preventDefault();
    if (this.state.add) {
      const current = this.state.change;
      current[key] = e.target.value;
      this.setState({ change: current });
    }
  }

  add() {
    this.setState({
      add: !this.state.add
    });
  }
  newProduct(e) {
    e.preventDefault();
    console.log(this.state.change.price);
    console.log(this.state.change.name);
    console.log(this.state.change.description);
    console.log(this.state.change.taxRate);
    console.log(this.state.change.requiresReservation);
    console.log(this.state.change.picture);
    if (
      this.state.change.name.length > 0 &&
      this.state.change.description.length > 0 &&
      parseInt(this.state.change.price) >= 0 &&
      parseInt(this.state.change.taxRate) >= 0
    ) {
      const info = {
        name: this.state.change.name,
        description: this.state.change.description,
        price: this.state.change.price,
        picture: this.state.change.picture,
        requiresReservation: this.state.change.requiresReservation,
        taxRate: this.state.change.taxRate,
        unit: this.state.change.unit
      };
      this.setState({ fetching: true });
      const token = window.localStorage.getItem("token");
      const changes = this.state.change;
      delete changes._id;
      axios
        .post(url + "admin/products/", info, {
          headers: { Authorization: token }
        })
        .then(response => {
          this.setState({
            change: {
              name: "",
              description: "",
              price: "",
              picture: "",
              taxRate: "",
              requiresReservation: false,
              unit: "pcs"
            },
            add: false
          });
          this.props.onMount();
        })
        .catch(error => {
          this.setState({ error: true, fetching: false });
        });
    }
  }

  requiresReservation(e) {
    if ((e.checked = "checked")) {
      return true;
    } else {
      return false;
    }
  }

  disabled(bool) {
    if (bool) {
      return {
        background: "#757575"
      };
    } else {
      {
      }
    }
  }

  checkChange() {
    if (
      this.state.change.name.length < 1 ||
      this.state.change.description.length < 1 ||
      this.state.change.price.lenth < 1 ||
      this.state.change.taxRate.length < 1 ||
      this.state.change.picture.length < 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  newForm() {
    if (this.state.add) {
      return (
        <div style={styles.del}>
          <div style={styles.delCard}>
            <h2>Nytt produkt</h2>
            <div>
              <form onSubmit={this.newProduct}>
                <input
                  placeholder="Produktnavn"
                  onChange={this.changeValue.bind(this, "name")}
                  type="text"
                  className="add-inputF"
                  value={this.state.change.name}
                  maxlength="60"
                  required
                />
                <input
                  placeholder="Pris"
                  onChange={this.changeValue.bind(this, "price")}
                  type="number"
                  className="add-inputF"
                  min="0"
                  value={this.state.change.price}
                  required
                />
                <input
                  placeholder="Skattesats (taxrate)"
                  onChange={this.changeValue.bind(this, "taxRate")}
                  type="number"
                  className="add-inputF"
                  min="0"
                  value={this.state.change.taxRate}
                  required
                />
                <textarea
                  placeholder="Produktbeskrivelse"
                  onChange={this.changeValue.bind(this, "description")}
                  type="text"
                  className="add-inputText"
                  maxlength="250"
                  value={this.state.change.description}
                  required
                />

                <div className="flex-container">
                  <div>
                    <label className="label">Krever reservasjon?</label>
                  </div>
                  <div>
                    <Switch
                      click={this.click}
                      checked={this.state.change.requiresReservation}
                    />
                  </div>
                </div>
                <input
                  onChange={e => this.encodeImageFileAsURL(e)}
                  type="file"
                  className="add-input"
                  accept="image/*"
                  required
                />
              </form>

              <div style={styles.delCont}>
                <button
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ add: false });
                  }}
                  style={styles.button}
                  className="change"
                >
                  Avbryt
                </button>
                <button
                  disabled={this.checkChange()}
                  onClick={this.newProduct}
                  style={{
                    ...styles.button,
                    ...this.disabled(this.checkChange())
                  }}
                  className="save"
                >
                  Legg til
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return "";
  }

  render() {
    return (
      <div>
        <h1>Produkter</h1>
        {this.props.access && this.props.access.products.write ?   
        (<button
          onClick={this.add}
          style={{ ...styles.button, ...{ marginLeft: "0px" } }}
          className="save"
        >
          {" "}
          Legg til ny{" "}
        </button>):("")} 
        {this.newForm()}
        <div className="band">{this.renderProducts()}</div>
      </div>
    );
  }
}

const styles = {
  button: {
    width: "120px",
    marginBottom: "20px",
    marginTop: "20px",
    marginRight: "10px",
    marginLeft: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: "18px",
    borderRadius: "8px"
  },
  del: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#00000070",
    top: 0,
    left: 0,
    zIndex: 2000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  delCard: {
    flex: "",
    height: "540px",
    width: "95%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "5px 5px 5px 5px",
    textAlign: "center"
  },
  delCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90%"
  }
};

const mapStateToProps = state => {
  return {
    products: state.products.data,
    error: state.products.error.active,
    access: state.login.user.info.permissions,
    loading: state.products.isFetching,
    message: state.products.error.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMount: () => {
      dispatch(actions.getProducts());
    },

    onProductClick: id => {
      console.log(id);
      dispatch(push(`/admin/products/${id}`));
    }
  };
};

const ProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);

export default ProductsContainer;
