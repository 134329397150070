import initialState from '../initialState.js';

const guestlists = (state = initialState.guestlists, action) => {
    switch (action.type) {
        case 'REQUEST_GUESTLISTS':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_GUESTLISTS_SUCCESS':
            return Object.assign({}, state, {
                data: action.guestlists.guests,
                page: action.guestlists.page,
                showing: action.guestlists.sent,
                total: action.guestlists.totalGuests,
                pages: Math.ceil(action.guestlists.totalGuests/50),
                isFetching: false,
            });

        case 'REQUEST_GUESTLISTS_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });

        case 'REQUEST_GUESTLIST':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_GUESTLIST_SUCCESS':
            return Object.assign({}, state, {
                info: action.guestlist,
                isFetching: false,
            });

        case 'REQUEST_GUESTLIST_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });
        default:
            return state;
    }
};

export default guestlists;