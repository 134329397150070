import axios from "axios";
import url from "../../url.js";

const requestSettingsFailure = message => {
  return {
    type: "REQUEST_SETTINGS_FAILURE",
    message
  };
};

const requestSettingsSuccess = settings => {
  return {
    type: "REQUEST_SETTINGS_SUCCESS",
    settings
  };
};

const requestSettings = () => {
  return {
    type: "REQUEST_SETTINGS"
  };
};

const fetchSettings = token => {
  return dispatch => {
    dispatch(requestSettings());
    return axios
      .get(url + "admin/settings", {
        headers: { Authorization: token }
      })
      .then(response => {
        dispatch(requestSettingsSuccess(response.data));
      })
      .catch(error => {
        dispatch(requestSettingsFailure(error.response.data));
      });
  };
};

export const getSettings = () => {
  return dispatch => {
    return dispatch(fetchSettings(window.localStorage.getItem("token")));
  };
};
