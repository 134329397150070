import initialState from '../initialState.js';

const guests = (state = initialState.guests, action) => {

    switch (action.type) {
        case 'REQUEST_GUESTS':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_GUESTS_SUCCESS':
            return Object.assign({}, state, {
                data: action.guests.guests,
                page: action.guests.page,
                showing: action.guests.sent,
                total: action.guests.totalGuests,
                pages: Math.ceil(action.guests.totalGuests/50),
                isFetching: false,
            });

        case 'REQUEST_GUESTS_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });

        case 'REQUEST_GUEST':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_GUEST_SUCCESS':
            return Object.assign({}, state, {
                info: action.guest,
                isFetching: false,
            });

        case 'REQUEST_GUEST_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });
        default:
            return state;
    }
};

export default guests;