import React from "react"
import Dropzone from "react-dropzone"
import parse from "csv-parse/lib/es5"
import url from "../../../url"
import axios from "axios"
import ReactFilestack, { client } from "filestack-react"
import { OutTable, ExcelRenderer } from "react-excel-renderer"

import excelToJson from "convert-excel-to-json"

const fileStackApiKey = "AF5u9vwYTKepOr5sGHkl1z"

const options = {
    maxFiles: 1,
    storeTo: {
        location: "s3",
    },
}

const COLUMNS = ["Fornavn", "Etternavn", "Telefonnummer", "E-post"]

class FileUploader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            guests: [],
            errors: [],
            showColumnPicker: false,
            showPosting: false,
            showPosted: false,
            headers: {
                firstname: 1,
                lastname: 2,
                number: 3,
                email: 4,
            },
        }
    }

    postGuests = () => {
        const token = window.localStorage.getItem("token")
        let userRows = this.state.rows.slice(0)
        console.log(userRows)
        let info = userRows.map(guest => {
            console.log(guest)
            return {
                firstname: guest[0],
                lastname: guest[1],
                number: guest[2].toString(),
                email: guest[3],
            }
        })
        console.log(info)
        axios
            .patch(
                url + "admin/groups/" + this.props.groupId + "/newmembers/",
                {
                    guests: info,
                },
                {
                    headers: { Authorization: token },
                },
            )
            .then(response => {
                this.props.onClose()
            })
            .catch(error => {
                this.setState({
                    errors: ["Noe sjedde under opplasting av fil"],
                })
            })
        this.setState({
            showPosting: false,
            showPosted: true,
        })
        this.props.onClose()
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            let result = reader.result
            this.setState({
                data: result,
                showColumnPicker: true,
            })
        }
        reader.readAsText(acceptedFiles[0], "UTF-8")
    }

    fileHandler = event => {
        let fileObj = event.target.files[0]
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err)
            } else {
                console.log(resp.rows)
                this.setState({
                    cols: resp.cols.map((col, i) => ({ ...col, name: "" })),
                    rows: resp.rows,
                })
            }
        })
    }

    render() {
        return (
            <div>
                <input type="file" onChange={this.fileHandler.bind(this)} style={{ padding: "10px" }} />
                {this.state.rows && this.state.cols && (
                    <div>
                        <div style={styles.exceltable}>
                            <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowClass="heading"
                            />
                        </div>
                        <button
                            onClick={this.postGuests}
                            style={{
                                ...styles.button,
                                alignSelf: "center",
                            }}
                            className="save"
                        >
                            Ok
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

const styles = {
    exceltable: {
        overflowY: "scroll",
        maxHeight: 150,
    },
    dropZoneContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 150,
        width: 150,
        border: "2px dotted grey",
        borderRadius: 20,
        marginBottom: 20,
    },
    dropZoneText: {
        textAlign: "center",
        margin: "0 20px 0",
    },
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
}

export default FileUploader
