import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import AdminContainer from "./store/containers/Admin.js"
import LoginLayout from "./ui/layout/loginLayout/LoginLayout.js"

const fouroffour = props => (
    <div style={{ position: "absolute", width: "100%", top: "50%", textAlign: "center" }}>
        Siden du ser etter finnes ikke.
    </div>
)

export default class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={LoginLayout} />
                <Route path="/admin" component={AdminContainer} />
                <Route component={fouroffour} />
            </Switch>
        )
    }
}
