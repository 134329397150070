import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import createHistory from "history/createBrowserHistory"
import { ConnectedRouter, routerMiddleware } from "react-router-redux"
import thunkMiddleware from "redux-thunk"
import "./index.css"
import initialState from "./store/initialState.js"
import App from "./App"
import admin from "./store/reducers/"
import registerServiceWorker from "./registerServiceWorker"
import validate from "./validate.js"
import "./index.css"
const history = createHistory()

const routerMid = routerMiddleware(history)

const store = createStore(admin, initialState, applyMiddleware(routerMid, thunkMiddleware))

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root"),
)
registerServiceWorker()
