import React, { Component } from "react"
import List from "../List"
import SearchBar from "../SearchBar"
import Modal from "../Modal"
import axios from "axios"
import { connect } from "react-redux"
import * as actions from "../../../store/actions/reservations.js"
import { push } from "react-router-redux"
import validate from "../../../validate.js"
import url from "../../../url.js"
import Switch from "../Switch.js"
import Mail from "../messageAndMail/mail.js"
import Message from "../messageAndMail/message.js"
import GuestsListContainer from "../guests/GuestsList"
import { getGuest } from "../../../store/actions/guests"

class NewReservation extends Component {
    constructor(props) {
        super(props)
        console.log(this.props.settings.contact.reservationConfirmation.message.bodyText)
        this.state = {
            guest: false,
            add: false,
            change: {
                totalGuests: "",
                freeEntry: "",
                comment: "",
                guest: {
                    name: "Gustav Dyngeseth",
                    number: "+4745083862",
                    email: "jonathan.s.linnestad@gmail.com",
                },
                email: {
                    send: false,
                    bodyHTML: this.props.settings.contact.reservationConfirmation.mail.bodyHTML,
                    bodyText: this.props.settings.contact.reservationConfirmation.mail.bodyText,
                    subject: this.props.settings.contact.reservationConfirmation.mail.subject,
                },
                message: {
                    send: false,
                    message: this.props.settings.contact.reservationConfirmation.message.bodyText,
                },
                hasSentMessage: false,
                confirmed: false,
                hasEntered: 0,
                hasUsedFreeEntry: 0,
                _id: "5b438bbd80e7ad675037d848",
            },
            changeSMS: false,
            changeMail: false,
        }
        this.newForm = this.newForm.bind(this)
        this.add = this.add.bind(this)
        this.newReservation = this.newReservation.bind(this)
        this.clickSwitchEmail = this.clickSwitchEmail.bind(this)
        this.clickSwitchMessage = this.clickSwitchMessage.bind(this)
    }

    changeValue(key, e) {
        e.preventDefault()
        const current = this.state.change
        if (
            key === "guestlistLimit" &&
            e.target.value !== "" &&
            validate(e.target.value, "number", { min: 0, max: 99 }).error
        ) {
        } else {
            current[key] = e.target.value
        }
        this.setState({ change: current })
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    checkChange() {
        if (this.props.selected.length < 1) {
            return true
        } else {
            return false
        }
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        }
    }

    newReservation(e) {
        e.preventDefault()
        let { day, month, year } = this.props
        day = day.length < 2 ? "0" + day : day
        month = month.length < 2 ? "0" + month : month
        let guestID = this.props.guest.info.guestID ? this.props.guest.info.guestID : this.props.guest.info._id
        if (this.state.change.comment.length > 0) {
            const info = {
                day,
                month,
                year,
                totalGuests: parseInt(this.state.change.totalGuests),
                freeEntry: parseInt(this.state.change.freeEntry),
                comment: this.state.change.comment,
                tables: this.props.selected,
                guest: {
                    guestID: guestID,
                },
                email: {
                    send: this.state.change.email.send,
                    bodyHTML: this.state.change.email.bodyHTML,
                    bodyText: this.state.change.email.bodyText,
                    subject: this.state.change.email.subject,
                },
                message: {
                    send: this.state.change.message.send,
                    message: this.state.change.message.message,
                },
            }

            this.setState({ fetching: true })
            const token = window.localStorage.getItem("token")
            axios
                .post(url + "admin/reservations", info, {
                    headers: { Authorization: token },
                })
                .then(response => {
                    this.setState({
                        add: false,
                        change: {
                            totalGuests: "",
                            freeEntry: "",
                            comment: "",
                            guest: {
                                name: "",
                                number: "",
                                email: "",
                            },
                            email: {
                                send: false,
                                bodyHTML: this.props.settings.contact.reservationConfirmation.mail.bodyHTML,
                                bodyText: this.props.settings.contact.reservationConfirmation.mail.bodyText,
                                subject: this.props.settings.contact.reservationConfirmation.mail.subject,
                            },
                            message: {
                                send: false,
                                message: this.props.settings.contact.reservationConfirmation.message.bodyText,
                            },
                            hasSentMessage: false,
                            confirmed: false,
                            hasEntered: 0,
                            hasUsedFreeEntry: 0,
                        },
                    })
                    this.props.onMount(this.props.day, this.props.month, this.props.year)
                    this.props.abort()
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        }
    }

    renderSelected() {
        console.log(this.props.selected)
        return this.props.selected.map(table => {
            return <div>{table}</div>
        })
    }

    renderDate() {
        return (
            <h3>
                {this.props.day} {this.props.month} {this.props.year}
            </h3>
        )
    }

    changeCom(type, e) {
        e.preventDefault()
        const x = {}
        x[type] = !this.state[type]
        this.setState(x)
    }

    clickSwitchEmail() {
        const current = this.state.change
        current.email.send = !current.email.send

        this.setState({
            change: current,
        })
    }

    clickSwitchMessage() {
        const current = this.state.change
        current.message.send = !current.message.send
        this.setState({
            change: current,
        })
    }

    exitModal(type) {
        const current = this.state.change
        current.email.bodyHTML = this.props.settings.contact.reservationConfirmation.mail.bodyHTML
        current.email.bodyText = this.props.settings.contact.reservationConfirmation.mail.bodyText
        current.message.message = this.props.settings.contact.reservationConfirmation.message.bodyText
        current[type] = false
        this.setState(current)
    }

    changeOk(type) {
        const o = {}
        o[type] = false
        this.setState(o)
    }

    set(obj, path, value) {
        let schema = obj // a moving reference to internal objects within obj
        const pList = path.split(".")
        const len = pList.length
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i]
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem]
        }

        if (typeof value === "object") {
            schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value)
        } else {
            schema[pList[len - 1]] = value
        }
    }

    changeComValues(key, value) {
        const current = this.state.change
        const set = this.set(current, key, value)
        this.setState({ change: current })
    }

    renderMail() {
        const emailValues = {
            subject: this.state.change.email.subject,
            message: this.state.change.email.bodyHTML,
            subjectChange: this.changeComValues.bind(this, "email.subject"),
            messageChange: this.changeComValues.bind(this, "email"),
        }
        return (
            <Mail
                subject={emailValues.subject}
                message={emailValues.message}
                subjectChange={emailValues.subjectChange}
                messageChange={emailValues.messageChange}
                edit={true}
            />
        )
    }

    renderSMS() {
        console.log(this.state.change)
        const smsValues = {
            message: this.state.change.message.message,
            messageChange: this.changeComValues.bind(this, "message.message"),
        }
        return <Message message={smsValues.message} messageChange={smsValues.messageChange} edit={true} />
    }

    renderComModal(openType, title, renderFunc) {
        return (
            <div>
                <Modal title={title} exit={this.exitModal.bind(this, openType)}>
                    {renderFunc()}
                    <button
                        onClick={this.changeOk.bind(this, openType)}
                        style={{
                            ...styles.button,
                            marginBottom: 84,
                        }}
                        className="save"
                    >
                        Ok
                    </button>
                </Modal>
            </div>
        )
    }

    renderComModals() {
        return (
            <div>
                {this.state.changeMail
                    ? this.renderComModal("changeMail", "Endre mail", this.renderMail.bind(this))
                    : ""}
                {this.state.changeSMS
                    ? this.renderComModal("changeSMS", "Endre melding/SMS", this.renderSMS.bind(this))
                    : ""}
            </div>
        )
    }

    guest(id) {
        this.props.onGuestSelect(id)
        this.setState({ guest: true, add: false })
    }

    exit() {
        this.setState({ add: false })
    }

    removeGuest() {
        this.setState({ guest: false })
    }

    renderGuest() {
        return (
            <div>
                {!this.props.guest.isFetching && this.state.guest ? (
                    <div>
                        <p>
                            {this.props.guest.info.firstname} {this.props.guest.info.lastname}
                        </p>
                        <p>{this.props.guest.info.number}</p>
                        <p>{this.props.guest.info.email}</p>
                        <button style={styles.button} className="change" onClick={this.removeGuest.bind(this)}>
                            Fjern gjest
                        </button>
                    </div>
                ) : (
                    <button style={styles.button} className="change" onClick={this.add}>
                        Legg til gjest
                    </button>
                )}
            </div>
        )
    }

    newForm() {
        return (
            <div>
                <div style={styles.delCard}>
                    <h2>Ny Reservasjon</h2>
                    {this.renderGuest()}
                    <div>
                        <div>{this.renderDate()}</div>
                        <form onSubmit={this.newReservation}>
                            <input
                                placeholder="Total Guests"
                                onChange={this.changeValue.bind(this, "totalGuests")}
                                type="number"
                                className="add-inputF"
                                value={this.state.change.totalGuests}
                                required
                            />
                            <input
                                placeholder="#Free Entry"
                                onChange={this.changeValue.bind(this, "freeEntry")}
                                type="number"
                                className="add-inputF"
                                min="0"
                                value={this.state.change.freeEntry}
                                required
                            />
                            <textarea
                                placeholder="Comment"
                                onChange={this.changeValue.bind(this, "comment")}
                                type="text"
                                className="add-inputText"
                                maxLength="250"
                                value={this.state.change.comment}
                                required
                            />
                            <div className="switchdiv">
                                <p>Send mail?</p>
                                <Switch click={this.clickSwitchEmail} checked={this.state.change.email.send} />
                            </div>
                            <div className="switchdiv">
                                <p>Send SMS?</p>

                                <Switch click={this.clickSwitchMessage} checked={this.state.change.message.send} />
                            </div>
                            <div style={styles.delCont}>
                                <button
                                    disabled={!this.state.change.email.send}
                                    onClick={this.changeCom.bind(this, "changeMail")}
                                    style={{
                                        ...styles.button,
                                        ...this.disabled(!this.state.change.email.send),
                                    }}
                                    className="change"
                                >
                                    Endre mail
                                </button>

                                <button
                                    disabled={!this.state.change.message.send}
                                    onClick={this.changeCom.bind(this, "changeSMS")}
                                    style={{
                                        ...styles.button,
                                        ...this.disabled(!this.state.change.message.send),
                                    }}
                                    className="change"
                                >
                                    Endre sms
                                </button>
                            </div>
                        </form>
                        <h3>Valgte bord:</h3>
                        <div>{this.renderSelected()}</div>

                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.props.abort()
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>

                            <button
                                disabled={this.checkChange()}
                                onClick={this.newReservation}
                                style={{
                                    ...styles.button,
                                    ...this.disabled(this.checkChange()),
                                }}
                                className="save"
                            >
                                Legg til
                            </button>
                        </div>
                    </div>
                </div>
                <div>{this.renderComModals()}</div>
                {this.state.add ? (
                    <GuestsListContainer
                        onClickRow={this.guest.bind(this)}
                        withModal
                        title={"Velg en gjest"}
                        guest={this.guest.bind(this)}
                        exit={this.exit.bind(this)}
                    />
                ) : (
                    ""
                )}
            </div>
        )
    }

    render() {
        return <div>{this.newForm()}</div>
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        width: "95%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}

const mapStateToProps = state => {
    return {
        reservations: state.reservations.data,
        error: state.reservations.error.active,
        loading: state.reservations.isFetching,
        message: state.reservations.error.message,
        settings: state.settings.info,
        guest: state.guests,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: (day, month, year) => {
            dispatch(actions.getReservations(day, month, year))
        },
        onGuestSelect(id) {
            dispatch(getGuest(id))
        },
        onReservationClick: id => {
            dispatch(push(`/admin/reservations/${id}`))
        },
    }
}

const NewReservationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewReservation)

export default NewReservationsContainer

/*<ul id="calendar">
  <Calendar />
</ul>*/
