import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import { push } from "react-router-redux"
import url from "../../../url.js"
import * as actions from "../../../store/actions/employees.js"
import Switch from "../Switch.js"
import "./employee.css"
import Validate from "../../../validate.js"

const permissions = [
    {
        key: "guestlist",
        name: "Gjesteliste",
    },
    {
        key: "guests",
        name: "Gjester",
    },
    {
        key: "groups",
        name: "Groups",
    },
    {
        key: "reservations",
        name: "Reservasjoner",
    },
    {
        key: "frontpage",
        name: "Forside",
    },
    {
        key: "products",
        name: "Produkter",
    },
    {
        key: "benefits",
        name: "Benefits",
    },
    {
        key: "settings",
        name: "Innstillinger",
    },
    {
        key: "statistics",
        name: "Statistikk",
    },
    {
        key: "employees",
        name: "Ansatte",
    },
]

class Employee extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            delete: false,
            change: {},
            patch: false,
            error: false,
            fetching: false,
            newpassword: false,
            password: "",
        }
        this.myRef = React.createRef(this)
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
        this.password = this.password.bind(this)
        this.patchChanges = this.patchChanges.bind(this)
        this.deleteChanges = this.deleteChanges.bind(this)
        this.patchPassword = this.patchPassword.bind(this)
    }

    componentDidMount() {
        console.log(this.myRef)
        this.props.onMount(this.props.match.params.id)
    }

    edit(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.employee))
        this.setState({ change: props, edit: !this.state.edit })
    }

    delete(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.employee))
        this.setState({ change: props, delete: true })
    }
    password(e) {
        this.setState({ newpassword: true })
    }

    getValue(key) {
        if (this.state.edit) {
            return this.state.change[key]
        }
        return this.props.employee[key]
    }

    getReadPermission(key) {
        if (this.state.edit) {
            return this.state.change.permissions[key].read
        }
        return this.props.employee.permissions[key].read
    }

    getWritePermission(key) {
        if (this.state.edit) {
            return this.state.change.permissions[key].write
        }
        return this.props.employee.permissions[key].write
    }

    clickPerm(key, type) {
        if (this.state.edit) {
            const current = this.state.change
            current.permissions[key][type] = !current.permissions[key][type]
            this.setState({ change: current })
        }
    }

    click(key) {
        if (this.state.edit) {
            const current = this.state.change
            current[key] = !current[key]
            this.setState({ change: current })
        }
    }

    changeValue(key, e) {
        e.preventDefault()
        if (this.state.edit) {
            const current = this.state.change
            console.log(Validate(e.target.value, "number", { min: 0, max: 2 }))
            if (
                key === "guestlistLimit" &&
                e.target.value !== "" &&
                Validate(e.target.value, "number", { min: 0, max: 99 }).error
            ) {
            } else {
                current[key] = e.target.value
            }
            this.setState({ change: current })
        }
    }

    changePassword(e) {
        e.preventDefault()
        this.setState({ password: e.target.value })
    }

    patchChanges(e) {
        e.preventDefault()
        console.log(this.props)
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        axios
            .patch(url + "admin/employees/" + this.props.employee._id, changes, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ patch: true, fetching: false, edit: false })
                this.props.onMount(this.props.match.params.id)
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    patchPassword(e) {
        e.preventDefault()
        this.setState({ fetching: true, newpassword: false })
        const token = window.localStorage.getItem("token")
        const pass = this.state.password
        axios
            .patch(
                url + "admin/employees/newpassword/" + this.props.employee._id,
                { password: pass },
                {
                    headers: { Authorization: token },
                },
            )
            .then(response => {
                this.setState({ patch: true, fetching: false, password: "" })
                this.props.onMount(this.props.match.params.id)
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    deleteChanges(e) {
        e.preventDefault()
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        axios
            .delete(url + "admin/employees/" + this.props.employee._id, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false, delete: false })
            })
    }

    renderPermissions() {
        return permissions.map((perm, index) => {
            return (
                <div className="switchcontainer" key={index}>
                    <h4 className="smaller">{perm.name}</h4>
                    <div className="">
                        <div className="switchdiv">
                            <p>Lese</p>
                            <Switch
                                disabled={!this.state.edit}
                                click={this.clickPerm.bind(this, perm.key, "read")}
                                checked={this.getReadPermission(perm.key)}
                            />
                        </div>
                        <div className="switchdiv">
                            <p>Skrive</p>
                            <Switch
                                disabled={!this.state.edit}
                                click={this.clickPerm.bind(this, perm.key, "write")}
                                checked={this.getWritePermission(perm.key)}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        } else {
            {
            }
        }
    }

    IsValidRead() {
        for (var i = 0, len = permissions.length; i < len; i++) {
            if (
                this.state.change.permissions[permissions[i].key].read !=
                this.props.employee.permissions[permissions[i].key].read
            ) {
                return true
            }
        }
        return false
    }

    IsValidWrite() {
        for (var i = 0, len = permissions.length; i < len; i++) {
            if (
                this.state.change.permissions[permissions[i].key].write !=
                this.props.employee.permissions[permissions[i].key].write
            ) {
                return true
            }
        }
        return false
    }

    checkChangePassword() {
        return this.state.password.length < 1
    }

    checkChange() {
        if (
            this.state.change.name != this.props.employee.name ||
            this.state.change.username != this.props.employee.username ||
            this.state.change.password != this.props.employee.password ||
            this.state.change.hasLimit != this.props.employee.hasLimit ||
            this.state.change.guestlistLimit != this.props.employee.guestlistLimit ||
            this.IsValidRead() ||
            this.IsValidWrite()
        ) {
            return false
        } else {
            return true
        }
    }

    renderInfo() {
        return (
            <div>
                <form>
                    <div className="lineunder">
                        <h3>Navn</h3>
                        <input
                            className={this.state.edit ? "add-input" : "showInput"}
                            type="text"
                            onChange={this.changeValue.bind(this, "name")}
                            value={this.getValue("name")}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Brukernavn</h3>
                        <input
                            className={this.state.edit ? "add-input" : "showInput"}
                            type="text"
                            onChange={this.changeValue.bind(this, "username")}
                            value={this.getValue("username")}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Gjesteliste</h3>
                        <p>Begrensning</p>
                        <Switch
                            disabled={!this.state.edit}
                            click={this.click.bind(this, "hasLimit")}
                            checked={this.getValue("hasLimit")}
                        />
                        {this.getValue("hasLimit") ? (
                            <div>
                                <p>Antall</p>
                                <input
                                    className={this.state.edit ? "add-input" : "showInputN"}
                                    onChange={this.changeValue.bind(this, "guestlistLimit")}
                                    type="number"
                                    value={this.getValue("guestlistLimit")}
                                    readOnly={!this.state.edit}
                                    required
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="lineunder">
                        <h3>Innstillinger</h3>
                        {this.renderPermissions()}
                    </div>
                </form>
            </div>
        )
    }

    status() {
        if (this.state.fetching) {
            return <p>Oppdaterer...</p>
        } else if (this.state.error) {
            return <p style={{ color: "red" }}>Error</p>
        } else if (this.state.patch) {
            return <p style={{ color: "green" }}>Oppdatert</p>
        } else {
            return <p>&nbsp;</p>
        }
    }

    deleteModal() {
        if (this.state.delete) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2>Sikker på at du vil slette {this.props.employee.name}?</h2>
                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({ delete: false })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button onClick={this.deleteChanges} style={styles.button} className="delete">
                                Fullfør
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    newPasswordModal() {
        if (this.state.newpassword) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2>Nytt passord {this.props.employee.name}?</h2>
                        <input
                            placeholder="Nytt passord"
                            onChange={this.changePassword.bind(this)}
                            type="password"
                            className="add-input"
                            value={this.state.change.password}
                            required
                        />
                        <div style={{ ...styles.delCont, ...{ height: "50%" } }}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({ newpassword: false })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button
                                onClick={this.patchPassword}
                                disabled={this.checkChangePassword()}
                                style={{ ...styles.button, ...this.disabled(this.checkChangePassword()) }}
                                className="save"
                            >
                                Endre
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    render() {
        return (
            <div ref={this.myRef}>
                {this.deleteModal()}
                {this.newPasswordModal()}
                <div>
                    <div className="fixed gap">
                        <h1 className="title">
                            {this.props.loading || !this.props.employee.permissions ? (
                                <p>Loading</p>
                            ) : (
                                this.props.employee.name
                            )}
                        </h1>
                    </div>
                    {this.status()}

                    <div className="gap">
                        <button
                            onClick={this.edit}
                            style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                            className={this.state.edit ? "delete" : "change"}
                        >
                            {this.state.edit ? "Avbryt" : "Endre"}
                        </button>
                        {this.state.edit ? (
                            ""
                        ) : (
                            <button onClick={this.password} style={styles.button} className="save">
                                Nytt passord
                            </button>
                        )}
                        {this.state.edit ? (
                            ""
                        ) : (
                            <button style={styles.button} onClick={this.delete} className="delete">
                                Slette
                            </button>
                        )}
                        {!this.state.edit ? (
                            ""
                        ) : (
                            <button
                                disabled={this.checkChange()}
                                onClick={this.patchChanges}
                                style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                                className="save"
                            >
                                Lagre
                            </button>
                        )}
                    </div>

                    <div className="gap">
                        {this.props.loading || !this.props.employee.permissions ? <p>Loading</p> : this.renderInfo()}
                    </div>
                    {!this.state.edit ? (
                        ""
                    ) : (
                        <button
                            disabled={this.checkChange()}
                            onClick={this.patchChanges}
                            style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                            className="save"
                        >
                            Lagre
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

const styles = {
    button: {
        width: "160px",
        marginBottom: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        height: "200px",
        width: "90%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}

const mapStateToProps = state => {
    return {
        employee: state.employees.info,
        error: state.employees.error.active,
        access: state.login.user.info.permissions,
        loading: state.employees.isFetching,
        message: state.employees.error.message,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: id => {
            dispatch(actions.getEmployee(id))
        },
        onDelete: () => {
            dispatch(push("/admin/employees"))
        },
    }
}

const EmployeeContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Employee)

export default EmployeeContainer
