import React, { Component } from 'react';
import { location } from '../../constants.js'
import { checkUrl } from '../../methods.js'

export default class PageTitle extends Component {
  render() {
    const { path } = this.props
    const { items } = location
    return(
      items.map((item) => checkUrl(path, item.path) ? <h1 id="main-title">{item.name}</h1>: null )
    )
  }
}
