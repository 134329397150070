import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import { push } from "react-router-redux"
import url from "../../../url.js"
import * as actions from "../../../store/actions/guests.js"
import * as groupsActions from "../../../store/actions/groups.js"
import * as benefitsActions from "../../../store/actions/benefits.js"
import Switch from "../Switch.js"
import "./guest.css"
import Validate from "../../../validate.js"
import DefaultList from "../DefaultList"
import FaCheckCircleO from "react-icons/lib/fa/check-circle-o"
import isEqual from "lodash/isEqual"

class Guest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            delete: false,
            change: {
                firstname: "",
                lastname: "",
                number: "+47",
                email: "",
                birthday: {
                    day: "",
                    month: "",
                    year: "",
                },
                groups: [],
                benefits: [],
                penaltyPoints: 0,
                comment: "",
            },
            selectors: {
                groups: {
                    active: false,
                    query: "",
                    added: [],
                },
                benefits: {
                    active: false,
                    query: "",
                    added: [],
                },
            },
        }
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
        this.patchChanges = this.patchChanges.bind(this)
        this.deleteChanges = this.deleteChanges.bind(this)
    }

    componentDidMount() {
        this.props.onMount(this.props.match.params.id)
    }

    edit(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.guest))
        console.log(props.groups)
        console.log(props.benefits)
        this.setState({
            change: props,
            edit: !this.state.edit,
            selectors: {
                groups: {
                    active: false,
                    query: "",
                    added: props.groups,
                },
                benefits: {
                    active: false,
                    query: "",
                    added: props.benefits,
                },
            },
        })
    }

    delete(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.guest))
        //props.groups = props.groups.map(group => group._id);
        //props.benefits = props.benefits.map(benefit => benefit._id);
        this.setState({ change: props, delete: true })
    }

    getValue(key) {
        console.log(this.props.guest)
        if (this.state.edit) {
            return this.state.change[key]
        }
        return this.props.guest[key]
    }

    clickSwitch(key) {
        if (this.state.edit) {
            const current = this.state.change
            current[key] = !current[key]
            this.setState({ change: current })
        }
    }

    changeValue(key, e) {
        e.preventDefault()
        const current = this.state.change
        if (this.state.edit) {
            if (key === "penaltyPoints") {
                current[key] = parseInt(e.target.value)
            } else {
                current[key] = e.target.value
            }
            this.setState({ change: current })
        }
    }

    patchChanges(e) {
        e.preventDefault()
        const current = this.state.change
        current.groups = this.state.selectors.groups.added
        current.benefits = this.state.selectors.benefits.added
        if (current.groups.length > 0) {
            current.groups = current.groups.map(group => group._id)
        }
        if (current.benefits.length > 0) {
            current.benefits = current.benefits.map(benefit => benefit._id)
        }
        console.log("current")
        console.log(current)
        this.setState({
            fetching: true,
        })
        const token = window.localStorage.getItem("token")
        delete current._id

        axios
            .patch(url + "admin/guests/" + this.props.guest._id, current, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ patch: true, fetching: false, edit: false })
                this.props.onMount(this.props.match.params.id)
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    deleteChanges(e) {
        e.preventDefault()
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        axios
            .delete(url + "admin/guests/" + this.props.guest._id, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false, delete: false })
            })
    }

    getElements(key, modal = false) {
        if (this.props.guest[key] && this.props[key]) {
            if (this.state.edit) {
                // on edit
                console.log("Now in edit")
                if (!this.state.selectors[key].active || !modal) {
                    // not modal
                    return this.state.selectors[key].added
                } else {
                    // modal
                    return this.props[key].filter(element => {
                        return element.name.toLowerCase().indexOf(this.state.selectors[key].query.toLowerCase()) >= 0
                    })
                }
            } else {
                // not edit
                return this.props.guest[key]
            }
        }
        return []
    }

    selectItem(key, row) {
        console.log(row)
        console.log(key)
        let selectors = this.state.selectors
        let index = this.state.selectors[key].added.findIndex(el => el._id === row._id)
        console.log(index)
        if (index >= 0) {
            console.log("remove" + row._id)
            selectors[key].added.splice(index, 1)
        } else {
            console.log("add" + row._id)
            selectors[key].added.push(row)
        }
        this.setState({
            selectors: selectors,
        })
    }

    onSelectorButtonClick(e) {
        e.preventDefault()
        let { name } = e.target
        if (this.state.edit) {
            const current = this.state.selectors
            current[name].active = true
            this.setState({
                selectors: current,
            })
        }
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        } else {
            {
            }
        }
    }

    checkChange() {
        return isEqual(this.state.change, this.props.guest)
    }

    getBirthdayValue() {
        let bday = this.getValue("birthday")
        if (bday) {
            return "yyyy-mm-dd"
                .replace("yyyy", bday.year)
                .replace("mm", bday.month)
                .replace("dd", bday.day)
        } else {
            return ""
        }
    }

    renderInfo() {
        return (
            <div>
                <form>
                    <div className="lineunder">
                        <h3>Navn</h3>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                {this.state.edit ? <p style={{ margin: 0 }}>Fornavn</p> : null}
                                <input
                                    className={this.state.edit ? "add-input" : "disabled-input"}
                                    type="text"
                                    onChange={this.changeValue.bind(this, "firstname")}
                                    value={
                                        this.state.edit
                                            ? this.getValue("firstname")
                                            : this.getValue("firstname") + " " + this.getValue("lastname")
                                    }
                                    readOnly={!this.state.edit}
                                    required
                                />
                            </div>
                            {this.state.edit ? (
                                <div>
                                    <div style={{ marginLeft: 30 }}>
                                        <p style={{ margin: 0 }}>Etternavn</p>
                                        <input
                                            className={this.state.edit ? "add-inputF" : "disabled-input"}
                                            type="text"
                                            onChange={this.changeValue.bind(this, "lastname")}
                                            value={this.getValue("lastname")}
                                            readOnly={!this.state.edit}
                                            required
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="lineunder">
                        <h3>Mail</h3>
                        <input
                            className={this.state.edit ? "add-inputF" : "disabled-input"}
                            type="email"
                            onChange={this.changeValue.bind(this, "email")}
                            value={this.getValue("email")}
                            readOnly={!this.state.edit}
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Telefon</h3>
                        <input
                            className={this.state.edit ? "add-inputG" : "disabled-input"}
                            type="tel"
                            onChange={this.changeValue.bind(this, "number")}
                            value={this.getValue("number")}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Fødselsdato</h3>
                        {(this.props.guest.birthday &&
                            (this.props.guest.birthday.day !== "" ||
                                this.props.guest.birthday.month !== "" ||
                                this.props.guest.birthday.year !== "")) ||
                        this.state.edit ? (
                            <input
                                className={this.state.edit ? "add-input" : "disabled-input"}
                                type="date"
                                onChange={e => {
                                    e.preventDefault()
                                    let current = this.state.change
                                    if (e.target.value.length <= 0) {
                                        current.birthday = {
                                            year: "",
                                            month: "",
                                            day: "",
                                        }
                                    } else {
                                        let bdayList = e.target.value.split("-")
                                        current.birthday = {
                                            year: bdayList[0],
                                            month: bdayList[1],
                                            day: bdayList[2],
                                        }
                                    }
                                    this.setState({
                                        change: current,
                                    })
                                }}
                                value={this.getBirthdayValue()}
                                readOnly={!this.state.edit}
                            />
                        ) : (
                            <p>Ikke satt</p>
                        )}
                    </div>
                    <div className="lineunder">
                        <h3>Prikker</h3>
                        <input
                            className={this.state.edit ? "add-inputG" : "disabled-input"}
                            type="number"
                            onChange={this.changeValue.bind(this, "penaltyPoints")}
                            value={this.getValue("penaltyPoints")}
                            readOnly={!this.state.edit}
                            min={0}
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Kommentar</h3>
                        <textarea
                            className={this.state.edit ? "add-inputText" : "showInputText"}
                            type="text"
                            onChange={this.changeValue.bind(this, "comment")}
                            value={this.getValue("comment") || ""}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Grupper</h3>
                        {this.state.edit ? (
                            <button
                                style={{
                                    ...styles.button,
                                    ...{ marginLeft: "0px", marginTop: "0px" },
                                }}
                                className="more"
                                name="groups"
                                onClick={this.onSelectorButtonClick.bind(this)}
                            >
                                Endre...
                            </button>
                        ) : null}
                        <div style={{ maxWidth: 600 }}>
                            <DefaultList
                                height={300}
                                data={this.getElements("groups")}
                                config={[
                                    { title: "Grupper", value: row => row.name },
                                    {
                                        title: this.state.edit ? "Slette" : "",
                                        value: row => {
                                            return (
                                                <button
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.selectItem("groups", row)
                                                    }}
                                                    style={
                                                        this.state.edit
                                                            ? {
                                                                  ...styles.smallButton,
                                                                  ...{ marginLeft: "7px" },
                                                              }
                                                            : { display: "none" }
                                                    }
                                                    className="delete"
                                                >
                                                    {this.state.edit ? "Slett" : ""}
                                                </button>
                                            )
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="lineunder">
                        <h3>Fordeler</h3>
                        {this.state.edit ? (
                            <button
                                style={{
                                    ...styles.button,
                                    ...{ marginLeft: "0px", marginTop: "0px" },
                                }}
                                className="more"
                                name="benefits"
                                onClick={this.onSelectorButtonClick.bind(this)}
                            >
                                Endre...
                            </button>
                        ) : null}
                        <div style={{ maxWidth: 600 }}>
                            <DefaultList
                                height={300}
                                data={this.getElements("benefits")}
                                config={[
                                    { title: "Fordeler", value: row => row.name },
                                    {
                                        title: this.state.edit ? "Slette" : "",
                                        value: row => {
                                            return (
                                                <button
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.selectItem("benefits", row)
                                                    }}
                                                    style={
                                                        this.state.edit
                                                            ? {
                                                                  ...styles.smallButton,
                                                                  ...{ marginLeft: "7px" },
                                                              }
                                                            : { display: "none" }
                                                    }
                                                    className="delete"
                                                >
                                                    {this.state.edit ? "Slett" : ""}
                                                </button>
                                            )
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="lineunder">
                        <h3>Innstillinger</h3>
                        <div className="switchcontainer">
                            <h4 className="smaller">Har verifisert identitet</h4>
                            <p>{this.getValue("verifiedIdentification") ? "Ja" : "Nei"}</p>
                            <div className="">
                                <div className="switchdiv">
                                    <Switch
                                        disabled={!this.state.edit}
                                        click={this.clickSwitch.bind(this, "verifiedIdentification")}
                                        checked={this.getValue("verifiedIdentification")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="switchcontainer">
                            <h4 className="smaller">Abboner på mail</h4>
                            <p>{this.getValue("subscribeEmail") ? "Ja" : "Nei"}</p>
                            <div className="">
                                <div className="switchdiv">
                                    <Switch
                                        disabled={!this.state.edit}
                                        click={this.clickSwitch.bind(this, "subscribeEmail")}
                                        checked={this.getValue("subscribeEmail")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="switchcontainer">
                            <h4 className="smaller">Abboner på tekstmelding</h4>
                            <p>{this.getValue("subscribeMessage") ? "Ja" : "Nei"}</p>
                            <div className="">
                                <div className="switchdiv">
                                    <Switch
                                        disabled={!this.state.edit}
                                        click={this.clickSwitch.bind(this, "subscribeMessage")}
                                        checked={this.getValue("subscribeMessage")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    status() {
        if (this.state.fetching) {
            return <p>Oppdaterer...</p>
        } else if (this.state.error) {
            return <p style={{ color: "red" }}>Error</p>
        } else if (this.state.patch) {
            return <p style={{ color: "green" }}>Oppdatert</p>
        } else {
            return <p>&nbsp;</p>
        }
    }

    isChosen(key, id) {
        let i = this.state.selectors[key].added.findIndex(el => el._id === id) >= 0
        return i
    }

    maybeRenderModal() {
        if (this.state.edit) {
            if (this.state.selectors.benefits.active) {
                return this.selectorModal("benefits")
            } else if (this.state.selectors.groups.active) {
                return this.selectorModal("groups")
            } else {
                return null
            }
        }
        return null
    }

    query(e) {
        e.preventDefault()
        let current = this.state.selectors
        current[e.target.name].query = e.target.value
        this.setState({
            selectors: current,
        })
    }

    deleteModal() {
        if (this.state.delete) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h2>Sikker på at du vil slette {this.props.guest.name}?</h2>
                        </div>
                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({ delete: false })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button onClick={this.deleteChanges} style={styles.button} className="delete">
                                Fullfør
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    selectorModal(key) {
        let config = [
            { title: key === "groups" ? "Gruppe" : "Fordel", value: obj => obj.name },
            {
                title: "Valgt",
                value: obj => {
                    return this.isChosen(key, obj._id) ? <FaCheckCircleO size={16} /> : ""
                },
            },
        ]
        let list = (
            <DefaultList
                height={200}
                data={this.getElements(key, true)}
                config={config}
                returnRowElement={true}
                onClickRow={row => this.selectItem(key, row)}
            />
        )
        return (
            <div style={styles.del}>
                <div style={styles.delCard}>
                    <h2 style={styles.addNew.title}>{key === "groups" ? "Velg grupper" : "Velg fordeler"}</h2>
                    <input
                        className="add-input"
                        placeholder={"Søk etter " + (key === "groups" ? "grupper" : "fordeler")}
                        name={key}
                        type="text"
                        onKeyUp={this.query.bind(this)}
                    />
                    <div>
                        {list}
                        <div style={styles.delCont}>
                            <button
                                name="abort-adding"
                                onClick={e => {
                                    e.preventDefault()
                                    let { selectors, change } = this.state
                                    selectors[key].active = false
                                    selectors[key].added = change[key].slice(0)
                                    selectors[key].query = ""
                                    this.setState({
                                        selectors: selectors,
                                    })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button
                                name="add"
                                onClick={e => {
                                    e.preventDefault()
                                    let { selectors, change } = this.state
                                    change[key] = selectors[key].added.slice(0)
                                    selectors[key].active = false
                                    selectors[key].query = ""
                                    this.setState({
                                        change: change,
                                        selectors: selectors,
                                    })
                                }}
                                style={styles.button}
                                className="save"
                            >
                                Lagre
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.deleteModal()}
                <div>
                    <div className="fixed gap">
                        <h1 className="title">{this.props.loading ? <p>Loading</p> : this.props.guest.name}</h1>
                    </div>
                    {this.status()}
                    <div className="gap">
                        {this.props.access && this.props.access.guests.write ? (
                            <div>
                                <button
                                    onClick={this.edit}
                                    style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                                    className={this.state.edit ? "delete" : "change"}
                                >
                                    {this.state.edit ? "Avbryt" : "Endre"}
                                </button>

                                {this.state.edit ? (
                                    <button
                                        disabled={this.checkChange()}
                                        onClick={this.patchChanges}
                                        style={{
                                            ...styles.button,
                                            ...this.disabled(this.checkChange()),
                                        }}
                                        className="save"
                                    >
                                        Lagre
                                    </button>
                                ) : (
                                    <button style={styles.button} onClick={this.delete} className="delete">
                                        Slette
                                    </button>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="gap">{this.props.loading ? <p>Loading</p> : this.renderInfo()}</div>
                    {!this.state.edit ? (
                        ""
                    ) : (
                        <button
                            disabled={this.checkChange()}
                            onClick={this.patchChanges}
                            style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                            className="save"
                        >
                            Lagre
                        </button>
                    )}
                </div>
                {this.maybeRenderModal()}
            </div>
        )
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: 1,
        width: "90%",
        maxWidth: 400,
        backgroundColor: "white",
        borderRadius: 8,
        padding: 8,
        alignItems: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },

    smallButton: {
        width: "120px",
        paddingTop: "2px",
        paddingBottom: "2px",
        paddingLeft: "2px",
        paddingRight: "2px",
        fontSize: "12px",
        borderRadius: "4px",
    },
    addNew: {
        title: {
            textAlign: "center",
        },
        form: {
            display: "flex",
            flexDirection: "column",
        },
        input: {
            borderRadius: 4,
        },
        sideBySide: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        selectorInput: {
            button: {
                flex: 1,
            },
        },
    },
}

const mapStateToProps = state => {
    return {
        guest: state.guests.info,
        error: state.guests.error.active,
        access: state.login.user.info.permissions,
        loading: state.guests.isFetching,
        message: state.guests.error.message,
        groups: state.groups.data,
        benefits: state.benefits.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: id => {
            dispatch(actions.getGuest(id))
            dispatch(groupsActions.getGroups())
            dispatch(benefitsActions.getBenefits())
        },
        onDelete: () => {
            dispatch(push("/admin/guests"))
        },
    }
}

const GuestContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Guest)

export default GuestContainer
