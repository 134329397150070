import Joi from "joi-browser"

const check = (query, checker) => Joi.validate(query, checker).error

function validateAlphabetic(value, schema) {
    return check(value, schema.alphabetic)
}

function validateText(value, schema) {
    return check(value, schema.text)
}

function validateAlphanumeric(value, schema) {
    return check(value, schema.alphanumeric)
}

function validateEmail(value, schema) {
    return check(value, schema.email)
}

function validateNumber(value, schema) {
    return check(value, schema.number)
}

function validateTelephone(value, schema) {
    return check(value, schema.telephone)
}

function validate(value, type, conditions = {}) {
    const cond = {
        min: conditions.min || 0,
        max: conditions.max || 65000000,
    }

    const schema = {
        alphabetic: Joi.string()
            .regex(/^([a-zA-ZÆØÅæøåÄÖäöñáéíóúüÑ-]+\s)*[a-zA-ZÆØÅæøåÄÖäöñáéíóúüÑ-]+$/)
            .min(cond.min)
            .max(cond.max),
        text: Joi.string()
            .min(cond.min)
            .max(cond.max),
        alphanumeric: Joi.string()
            .regex(/^([a-zA-Z0-9ÆØÅæøåÄÖäöñáéíóúüÑ-]+\s)*[a-zA-ZÆØÅ0-9æøåÄÖäöñáéíóúüÑ-]+$/)
            .min(cond.min)
            .max(cond.max),
        email: Joi.string().email(),
        number: Joi.number()
            .integer()
            .min(cond.min)
            .max(cond.max),
        telephone: Joi.string().regex(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/),
    }

    switch (type) {
        case "alphabetic":
            return {
                error: !!validateAlphabetic(value, schema),
                message:
                    validateAlphabetic(value, schema) &&
                    "Navnet kan kun bestå av bokstaver og møte kravene om min: " + cond.min + " og max " + cond.max,
            }
        case "text":
            return {
                error: !!validateText(value, schema),
                message:
                    validateText(value, schema) &&
                    "Teksten møtte ikke kravene om min: " + cond.min + " og max " + cond.max,
            }
        case "alphanumeric":
            return {
                error: !!validateAlphanumeric(value, schema),
                message:
                    validateAlphanumeric(value, schema).message &&
                    "Teksten kan kun bestå av tall eller bokstaver med min: " + cond.min + " og max " + cond.max,
            }
        case "email":
            return {
                error: !!validateEmail(value, schema),
                message: validateEmail(value, schema).message && "Skriv inn en gyldig e-postadresse",
            }
        case "number":
            return {
                error: !!validateNumber(value, schema),
                message:
                    validateNumber(value, schema) &&
                    "Antallet må være mellom " + cond.min + " og " + cond.max + " eller må være kun tall",
            }

        case "telephone":
            return {
                error: !!validateTelephone(value, schema),
                message: validateTelephone(value, schema) && "Skriv inn et gyldig telefonnummer",
            }
    }
}

/*

/*const testdatatext = {min: 0, max: 100, type: "text"};
const testtext = validate("abc", testdatatext.type, testdatatext);
console.log(testtext); */

/* const testdataalphabetic = {min: 0, max: 100, type: "alphabetic"};
const testalphabetic = validate("abcqwe-wqeqwr sdfasdf asdfasdf", testdataalphabetic.type, testdataalphabetic);
console.log(testalphabetic); */

/*const testdataalphanumeric = {min: 0, max: 100, type: "alphanumeric"};
const testalphanumeric = validate("abcde-asdas12f adfasfd adfasdf", testdataalphanumeric.type, testdataalphanumeric);
console.log(testalphanumeric); */

/* const testdatatext = {min: 0, max: 100, type: "text"};
const testtext = validate("abcde-asdas12f adfasfd adfasdf", testdatatext.type, testdatatext);
console.log(testtext);
*/
export default validate
