import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import axios from "axios"
import url from "../../../url.js"
import * as actions from "../../../store/actions/guests.js"
import * as groupsActions from "../../../store/actions/groups.js"
import * as benefitsActions from "../../../store/actions/benefits.js"
import "./guest.css"
import DefaultList from "../DefaultList"
import FaCheckCircleO from "react-icons/lib/fa/check-circle-o"
import benefits from "../../../store/reducers/benefits"
import Modal from "../Modal.js"

const initialState = {
    add: false,
    change: {
        firstname: "",
        lastname: "",
        number: "+47",
        email: "",
        benefits: [],
        groups: [],
    },
    selectors: {
        groups: {
            active: false,
            query: "",
            added: [],
        },
        benefits: {
            active: false,
            query: "",
            added: [],
        },
    },
}

class GuestsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            add: false,
            change: {
                firstname: "",
                lastname: "",
                number: "+47",
                email: "",
                benefits: [],
                groups: [],
            },
            selectors: {
                groups: {
                    active: false,
                    query: "",
                    added: [],
                },
                benefits: {
                    active: false,
                    query: "",
                    added: [],
                },
            },
        }
        this.isChosen = this.isChosen.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.add = this.add.bind(this)
        this.newUser = this.newUser.bind(this)
        this.onClickGuestRow = this.onClickGuestRow.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
    }

    handleSearchChange(e) {
        e.preventDefault()
        this.setState({ search: e.target.value })
        this.props.get(e.target.value, 0)
    }

    next(e) {
        e.preventDefault()
        if (this.props.page < this.props.pages) {
            this.props.get(e.target.value, parseInt(this.props.page) + 1)
        }
    }

    prev(e) {
        e.preventDefault()
        if (this.props.page > 0) {
            this.props.get(e.target.value, parseInt(this.props.page) - 1)
        }
    }

    componentDidMount() {
        this.props.onMount()
    }

    onClickGuestRow(id) {
        this.props.onGuestClick(id)
    }

    renderTable() {
        let config = [
            { title: "Navn", value: obj => obj.firstname + " " + obj.lastname },
            { title: "Telefonnummer", value: obj => obj.number },
            { title: "Mail", value: obj => obj.email },
            { title: "Har bruker", value: obj => (obj.user ? "Ja" : "") },
        ]

        return (
            <DefaultList
                data={this.props.guests}
                config={config}
                onClickRow={this.props.onClickRow ? this.props.onClickRow : this.onClickGuestRow}
            />
        )
    }

    changeValue(key, e) {
        e.preventDefault()
        if (this.state.add) {
            const current = this.state.change
            current[key] = e.target.value
            this.setState({ change: current })
        }
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    onSelectorButtonClick(e) {
        e.preventDefault()
        let { name } = e.target
        if (this.state.add) {
            const current = this.state.selectors
            current[name].active = true
            this.setState({
                selectors: current,
            })
        }
    }

    newUser(e) {
        e.preventDefault()
        if (
            this.state.change.firstname.length > 0 &&
            this.state.change.lastname.length > 0 &&
            this.state.change.number.length > 0
        ) {
            const info = {
                firstname: this.state.change.firstname,
                lastname: this.state.change.lastname,
                number: this.state.change.number,
                email: this.state.change.email,
                benefits: this.state.change.benefits,
                groups: this.state.change.groups,
            }

            this.setState({ fetching: true })
            const token = window.localStorage.getItem("token")
            const changes = this.state.change
            delete changes._id
            axios
                .post(url + "admin/guests/", info, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then(response => {
                    this.setState({
                        add: false,
                        change: {
                            firstname: "",
                            lastname: "",
                            number: "+47",
                            email: "",
                            benefits: [],
                            groups: [],
                        },
                        selectors: {
                            groups: {
                                active: false,
                                query: "",
                                added: [],
                            },
                            benefits: {
                                active: false,
                                query: "",
                                added: [],
                            },
                        },
                    })
                    this.props.onMount()
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                    console.log(error)
                })
        }
    }

    selectItem(key, id) {
        let selectors = this.state.selectors
        let index = this.state.selectors[key].added.indexOf(id)

        if (this.isChosen(key, id)) {
            selectors[key].added.splice(index, 1)
        } else {
            selectors[key].added.push(id)
        }
        this.setState({
            selectors: selectors,
        })
    }

    isChosen(key, id) {
        return this.state.selectors[key].added.indexOf(id) > -1
    }

    _renderSelector(key) {
        let groupsConfig = [
            { title: "Gruppe", value: obj => obj.name },
            {
                title: "Valgt",
                value: obj => {
                    return this.isChosen("groups", obj._id) ? <FaCheckCircleO size={16} /> : ""
                },
            },
        ]
        let benefitsConfig = [
            { title: "Fordel", value: obj => obj.name },
            {
                title: "Valgt",
                value: obj => {
                    return this.isChosen("benefits", obj._id) ? <FaCheckCircleO size={16} /> : ""
                },
            },
        ]
        let groups = (
            <DefaultList
                data={this.props.groups}
                config={groupsConfig}
                onClickRow={id => this.selectItem("groups", id)}
            />
        )
        let benefits = (
            <DefaultList
                data={this.props.benefits}
                config={benefitsConfig}
                onClickRow={id => this.selectItem("benefits", id)}
            />
        )
        return (
            <div style={styles.del}>
                <div style={styles.delCard}>
                    <h2 style={styles.addNew.title}>{key === "groups" ? "Velg grupper" : "Velg fordeler"}</h2>
                    <div>
                        {key === "groups" ? groups : benefits}
                        <div style={styles.delCont}>
                            <button
                                name="abort-adding"
                                onClick={e => {
                                    e.preventDefault()
                                    let { selectors, change } = this.state
                                    selectors[key].active = false
                                    selectors[key].added = change[key].slice(0)
                                    this.setState({
                                        selectors: selectors,
                                    })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button
                                name="add"
                                onClick={e => {
                                    e.preventDefault()
                                    let { selectors, change } = this.state
                                    change[key] = selectors[key].added.slice(0)
                                    selectors[key].active = false
                                    this.setState({
                                        change: change,
                                        selectors: selectors,
                                    })
                                }}
                                style={styles.button}
                                className="save"
                            >
                                Lagre
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    checkChange() {
        if (
            this.state.change.firstname.length < 1 ||
            this.state.change.lastname.length < 1 ||
            this.state.change.number.length < 4
        ) {
            return true
        } else {
            return false
        }
    }

    newForm() {
        let formInputs = [
            {
                placeHolder: "Fornavn",
                valueKey: "firstname",
                type: "text",
                value: this.state.change.firstname,
                minLength: 1,
            },
            {
                placeHolder: "Etternavn",
                valueKey: "lastname",
                type: "text",
                value: this.state.change.lastname,
                minLength: 1,
            },
            {
                placeHolder: "Telefonnummer",
                valueKey: "number",
                type: "tel",
                value: this.state.change.number,
                minLength: 4,
            },
            {
                placeHolder: "Mail",
                valueKey: "email",
                type: "email",
                value: this.state.change.email,
                minLength: 4,
            },
        ]

        if (this.state.add) {
            if (this.state.selectors.groups.active) {
                return this._renderSelector("groups")
            } else if (this.state.selectors.benefits.active) {
                return this._renderSelector("benefits")
            }

            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2 style={styles.addNew.title}>Ny gjest</h2>
                        <div>
                            <form onSubmit={this.newUser} style={styles.addNew.form}>
                                {formInputs.map(input => {
                                    return (
                                        <input
                                            style={styles.addNew.input}
                                            placeholder={input.placeHolder}
                                            onChange={this.changeValue.bind(this, input.valueKey)}
                                            type={input.type}
                                            className="add-input"
                                            value={input.value}
                                            minLength={input.minLength}
                                            required={input.type !== "email"}
                                        />
                                    )
                                })}

                                <div style={styles.delCont}>
                                    <button
                                        style={styles.button}
                                        className="more"
                                        name="benefits"
                                        onClick={this.onSelectorButtonClick.bind(this)}
                                    >
                                        Fordeler...
                                    </button>

                                    <button
                                        style={styles.button}
                                        className="more"
                                        name="groups"
                                        onClick={this.onSelectorButtonClick.bind(this)}
                                    >
                                        Grupper...
                                    </button>
                                </div>
                                <div style={styles.delCont}>
                                    <button
                                        onClick={e => {
                                            e.preventDefault()
                                            this.setState({
                                                add: false,
                                                change: {
                                                    firstname: "",
                                                    lastname: "",
                                                    number: "+47",
                                                    email: "",
                                                    benefits: [],
                                                    groups: [],
                                                },
                                                selectors: {
                                                    groups: {
                                                        active: false,
                                                        query: "",
                                                        added: [],
                                                    },
                                                    benefits: {
                                                        active: false,
                                                        query: "",
                                                        added: [],
                                                    },
                                                },
                                            })
                                        }}
                                        style={styles.button}
                                        className="change"
                                    >
                                        Avbryt
                                    </button>
                                    <button
                                        disabled={this.checkChange()}
                                        onClick={this.newUser}
                                        style={{
                                            ...styles.button,
                                            ...this.disabled(this.checkChange()),
                                        }}
                                        className="save"
                                    >
                                        Lagre
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        } else {
            return {}
        }
    }

    render() {
        let content = (
            <div>
                <h1>{this.props.title ? this.props.title : "Gjester"}</h1>
                <input
                    placeholder="Søk"
                    onChange={this.handleSearchChange}
                    type="text"
                    className="add-inputSearch"
                    value={this.state.search}
                />
                <p className="searchButton">
                    {this.props.access && this.props.access.guests.write ? (
                        <button
                            onClick={this.add}
                            style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                            className="save"
                        >
                            Legg til ny
                        </button>
                    ) : (
                        ""
                    )}

                    <a style={{ marginLeft: "10px" }}>{this.props.showing + " av " + this.props.total} </a>
                    {this.newForm()}
                </p>

                {this.props.loading ? <p>Loading</p> : this.renderTable()}
                <button
                    disabled={parseInt(this.props.page) === 0}
                    onClick={this.prev}
                    style={{
                        ...styles.button,
                        ...{ marginLeft: "0px" },
                        ...this.disabled(parseInt(this.props.page, 10) <= 0),
                    }}
                    className="save"
                >
                    Forrige
                </button>
                <button
                    disabled={parseInt(this.props.page) + 1 >= parseInt(this.props.pages)}
                    onClick={this.next}
                    style={{
                        ...styles.button,
                        ...this.disabled(parseInt(this.props.page) + 1 >= parseInt(this.props.pages)),
                    }}
                    className="save"
                >
                    Neste
                </button>
            </div>
        )

        if (this.props.withModal) {
            return (
                <Modal title={this.props.title || "Gjester"} exit={() => (this.props.exit ? this.props.exit() : null)}>
                    {content}
                </Modal>
            )
        }
        return content
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: 1,
        width: "90%",
        maxWidth: 400,
        backgroundColor: "white",
        borderRadius: 8,
        padding: 8,
        alignItems: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
    addNew: {
        title: {
            textAlign: "center",
        },
        form: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        input: {
            borderRadius: 4,
        },
        sideBySide: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        selectorInput: {
            button: {
                flex: 1,
            },
        },
    },
}

const mapStateToProps = state => {
    return {
        guests: state.guests.data,
        error: state.guests.error.active,
        access: state.login.user.info.permissions,
        loading: state.guests.isFetching,
        message: state.guests.error.message,
        groups: state.groups.data,
        benefits: state.benefits.data,
        page: state.guests.page,
        showing: state.guests.showing,
        total: state.guests.total,
        pages: state.guests.pages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: () => {
            dispatch(actions.getGuests())
            dispatch(groupsActions.getGroups())
            dispatch(benefitsActions.getBenefits())
        },
        onGuestClick: id => {
            dispatch(push(`/admin/guests/${id}`))
        },
        get: (search, page) => {
            dispatch(actions.getGuests(search, page))
        },
    }
}

const GuestsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GuestsList)

export default GuestsContainer
