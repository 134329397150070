import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import error from "./error.js";
import login from "./login.js";
import calendar from "./calendar.js";
import searchbar from "./searchbar";
import guestlists from "./guestlist";
import employees from "./employees";
import groups from "./groups";
import reservations from "./reservations";
import guests from "./guests";
import benefits from "./benefits";
import products from "./products";
import settings from "./settings";

const admin = combineReducers({
  error,
  login,
  calendar,
  searchbar,
  guestlists,
  employees,
  products,
  groups,
  guests,
  benefits,
  reservations,
  router: routerReducer,
  settings
});

export default admin;
