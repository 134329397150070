import { connect } from 'react-redux';
import * as actions from '../actions/login.js';
import LoginComponent from '../../ui/components/Login.js';

const mapStateToProps = (state) => {
  console.log(state);
  return {
    username: state.login.username,
    password: state.login.password,
    error: state.login.error.active,
    message: state.login.error.message,
    valid: !(state.login.username.length > 0 && state.login.password.length > 0),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordOnChange: e => {
      e.preventDefault();
      dispatch(actions.changePassword(e.target.value))
    },
    usernameOnChange: e => {
      e.preventDefault();
      dispatch(actions.changeUsername(e.target.value))
    },
    onSubmit: e => {
      e.preventDefault();
      dispatch(actions.getToken());
    }
  }
}

const Login = connect(
  mapStateToProps,
  mapDispatchToProps)(LoginComponent)


export default Login;
