import { connect } from 'react-redux';
import * as actions from '../actions/employees.js';
import Employees from '../../ui/pages/Employees.js';

const mapStateToProps = (state) => {
  return {
    employees: state.employees.data,
    error: state.employees.error.active,
    loading: state.employees.isFetching,
    message: state.employees.error.message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: ()=> {
      dispatch(actions.getEmployees());
    }
  }
}

const EmployeesContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Employees)


export default EmployeesContainer;
