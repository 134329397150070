import initialState from '../initialState.js';

const reservations = (state = initialState.reservations, action) => {
    switch (action.type) {
        case 'REQUEST_RESERVATIONS':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_RESERVATIONS_SUCCESS':
        console.log(action);
            return Object.assign({}, state, {
                data: action.reservations.reservations,
                isFetching: false,
            });

        case 'REQUEST_RESERVATIONS_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });

        case 'REQUEST_RESERVATION':
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });

        case 'REQUEST_RESERVATION_SUCCESS':
            return Object.assign({}, state, {
                info: action.reservation,
                isFetching: false,
            });

        case 'REQUEST_RESERVATION_FAILURE':
            return Object.assign({}, state, {
                error: {
                    active: true,
                    message: action.message,
                },
                isFetching: false,
            });
        default:
            return state;
    }
};

export default reservations;