import initalState from '../initialState.js'

const cal = (state = initalState.calendar, action) => {
  switch(action.type) {
    case 'CHANGE_DAY':
      return Object.assign({}, state, {
        day: action.day
      })
    case 'TOGGLE_CALENDAR':
    return Object.assign({}, state, {
      open: !state.open
    })
    case 'CHANGE_CALENDER':
    return Object.assign({}, state,
      action.calendar
    )
    default:
      return state
  }
}

export default cal;
