import axios from "axios";
import url from "../../url.js";

const requestBenefitsFailure = message => {
  return {
    type: "REQUEST_BENEFITS_FAILURE",
    message
  };
};

const requestBenefitsSuccess = benefits => {
  return {
    type: "REQUEST_BENEFITS_SUCCESS",
    benefits
  };
};

const requestBenefits = () => {
  return {
    type: "REQUEST_BENEFITS"
  };
};

const fetchBenefits = (token, search = "") => {
  return dispatch => {
    dispatch(requestBenefits());
    return axios
      .get(url + "admin/benefits?search=" + search, {
        headers: { Authorization: token }
      })
      .then(response => {
        dispatch(requestBenefitsSuccess(response.data.benefits));
      })
      .catch(error => {
        dispatch(requestBenefitsFailure(error.response.data));
      });
  };
};

export const getBenefits = (searchString = "") => {
  return (dispatch, getState) => {
    return dispatch(
      fetchBenefits(window.localStorage.getItem("token"), searchString)
    );
  };
};

const requestBenefitFailure = message => {
  return {
    type: "REQUEST_BENEFIT_FAILURE",
    message
  };
};

const requestBenefitSuccess = benefit => {
  console.log("rungvjgvhgs");
  return {
    type: "REQUEST_BENEFIT_SUCCESS",
    benefit
  };
};

const requestBenefit = () => {
  return {
    type: "REQUEST_BENEFIT"
  };
};

const fetchBenefit = (token, id) => {
  console.log("asdsss");
  return dispatch => {
    dispatch(requestBenefit());
    return axios
      .get(url + "admin/benefits/" + id, {
        headers: { Authorization: token }
      })
      .then(response => {
        console.log("asdsss");
        console.log(response);
        console.log("asd");
        dispatch(requestBenefitSuccess(response.data));
      })
      .catch(error => {
        dispatch(requestBenefitFailure(error.response.data));
      });
  };
};

export const getBenefit = id => {
  return (dispatch, getState) => {
    return dispatch(fetchBenefit(window.localStorage.getItem("token"), id));
  };
};
