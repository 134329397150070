import { connect } from 'react-redux';
import * as actions from '../actions/products.js';
import Products from '../../ui/pages/Products.js';

const mapStateToProps = (state) => {
  return {
    products: state.products.data,
    error: state.products.error.active,
    loading: state.products.isFetching,
    message: state.products.error.message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: ()=> {
      dispatch(actions.getProducts());
    }
  }
}

const ProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Products)


export default ProductsContainer;
