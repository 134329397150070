import initialState from "../initialState.js";

const benefits = (state = initialState.benefits, action) => {
  switch (action.type) {
    case "REQUEST_BENEFITS":
      return Object.assign({}, state, {
        isFetching: true,
        error: false
      });
    case "REQUEST_BENEFITS_SUCCESS":
      return Object.assign({}, state, {
        data: action.benefits,
        isFetching: false
      });
    case "REQUEST_BENEFITS_FAILURE":
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message
        },
        isFetching: false
      });
    case "REQUEST_BENEFIT_SUCCESS":
      return Object.assign({}, state, {
        info: action.benefit,
        isFetching: false
      });
    case "REQUEST_BENEFIT_FAILURE":
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message
        },
        isFetching: false
      });
    default:
      return state;
  }
};

export default benefits;
