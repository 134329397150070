import axios from 'axios';
import url from '../../url.js';


const requestGuestsFailure = (message) => {
    return {
        type: "REQUEST_GUESTS_FAILURE",
        message
    }
};


const requestGuestsSuccess = (guests) => {
    return {
        type: "REQUEST_GUESTS_SUCCESS",
        guests,
    }
};


const requestGuests = () => {
    return {
        type: "REQUEST_GUESTS",
    }
};


const fetchGuests = (token, searchString = '', page) => {
    return (dispatch) => {
        dispatch(requestGuests());
        return axios.get(url + "admin/guests?search=" + searchString + '&page=' + page,
            {
                headers: {
                    Authorization: token
                },
            })
            .then(response => {
                dispatch(requestGuestsSuccess(response.data));
            })
            .catch(error => {
                dispatch(requestGuestsFailure(error.response.data));
            })
    }
};


export const getGuests = (searchString = '', page = 0) => {
    return (dispatch, getState) => {
        return dispatch(fetchGuests(window.localStorage.getItem("token"), searchString, page))
    }
};


const requestGuestFailure = (message) => {
    return {
        type: "REQUEST_GUEST_FAILURE",
        message
    }
};


const requestGuestSuccess = (guest) => {
    return {
        type: "REQUEST_GUEST_SUCCESS",
        guest,
    }
};


const requestGuest = () => {
    return {
        type: "REQUEST_GUEST",
    }
};


const fetchGuest = (token, id) => {
    return dispatch => {
        dispatch(requestGuest());

        return axios.get(url + "admin/guests/" + id,
            {
                headers: {
                    Authorization: token
                },
            })
            .then(response => {
                dispatch(requestGuestSuccess(response.data));
            })
            .catch(error => {
                dispatch(requestGuestFailure(error.response));
            });
    }
};


export const getGuest = (id) => {
    return (dispatch) => {
        return dispatch(fetchGuest(window.localStorage.getItem("token"), id));
    }
};
