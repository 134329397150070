import axios from 'axios';
import url from '../../url.js';


const requestGuestlistsFailure = (message) => {
    return {
        type: "REQUEST_GUESTLISTS_FAILURE",
        message
    }
};


const requestGuestlistsSuccess = (guestlists) => {
    return {
        type: "REQUEST_GUESTLISTS_SUCCESS",
        guestlists,
    }
};


const requestGuestlists = () => {
    return {
        type: "REQUEST_GUESTLISTS",
    }
};


const fetchGuestlists = (token, day, month, year, searchString = '', page) => {
    console.log("fetching guestlists...");

    return (dispatch) => {
        const fetchday = day ? "&day=" + day : '';
        dispatch(requestGuestlists());
        return axios.get(url + `admin/guestlists?year=${year}&month=${month}${fetchday}&search=${searchString}&page=${page}`,
            {
                headers: {
                    Authorization: token
                },
            })
            .then(response => {
                dispatch(requestGuestlistsSuccess(response.data));
            })
            .catch(error => {
                dispatch(requestGuestlistsFailure(error.response.data));
            })
    }
};


export const getGuestlists = (day, month, year, searchString = '', page = 0) => {
    return (dispatch, getState) => {
        return dispatch(fetchGuestlists(window.localStorage.getItem("token"), day, month, year, searchString, page ))
    }
};


const requestGuestlistFailure = (message) => {
    return {
        type: "REQUEST_GUESTLIST_FAILURE",
        message
    }
};


const requestGuestlistSuccess = (guestlist) => {
    return {
        type: "REQUEST_GUESTLIST_SUCCESS",
        guestlist,
    }
};


const requestGuestlist = () => {
    return {
        type: "REQUEST_GUESTLIST",
    }
};


const fetchGuestlist = (token, id) => {
    return dispatch => {
        dispatch(requestGuestlist());
        return axios.get(url + "admin/guestlists/" + id,
            {
                headers: {
                    Authorization: token
                },
            })
            .then(response => {
                dispatch(requestGuestlistSuccess(response.data));
            })
            .catch(error => {
                dispatch(requestGuestlistFailure(error.response.data));
            })
    }
};


export const getGuestlist = (id) => {
    return (dispatch) => {
        return dispatch(fetchGuestlist(window.localStorage.getItem("token"), id));
    }
};