import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Employee from '../components/employees/Employee.js';
import EmployeesList from '../components/employees/EmployeesList.js';
import './Employees.css';


export default class Employees extends Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/employees/:id" component={Employee} />
        <Route path="/admin/employees" component={EmployeesList} />
      </Switch>
    );
  }
}
