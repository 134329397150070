import React from 'react';
import Login from '../../../store/containers/login';
import './LoginLayout.css'


const LoginLayout = () => {
  return (
    <div className="layout-container">
      <div className="login-item">
        <Login />
      </div>
    </div>
  );
}

export default LoginLayout;
