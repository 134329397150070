import React, { Component } from "react";
import { connect } from "react-redux";
import Mail from "../messageAndMail/mail.js";
import { getSettings } from "../../../store/actions/settings.js";
import { getInfo } from "../../../store/actions/login.js";
import Message from "../messageAndMail/message.js";

import "./settings.css";
import Axios from "axios";
import url from "../../../url.js";

class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
    this.toolbar = React.createRef();
    this.state = {
      edit: false,
      change: {}
    };
  }

  componentDidMount() {
    this.props.onMount();
  }

  edit(e) {
    e.preventDefault();
    const props = JSON.parse(JSON.stringify(this.props.settings));
    this.setState({ edit: true, change: props });
  }

  abort(e) {
    e.preventDefault();
    const props = JSON.parse(JSON.stringify(this.props.settings));
    this.setState({ edit: false, change: props });
  }

  save(e) {
    e.preventDefault();
    this.setState({ fetching: true });
    const token = window.localStorage.getItem("token");
    const changes = this.state.change;
    console.log(changes);

    delete changes._id;
    const parsed = JSON.parse(JSON.stringify(changes));
    console.log(parsed);
    Axios.patch(url + "admin/settings/", parsed, {
      headers: { Authorization: token }
    })
      .then(response => {
        console.log(response);
        this.setState({ patch: true, fetching: false, edit: false });
        this.props.onMount();
        this.props.requestNewInfo();
      })
      .catch(error => {
        this.setState({ error: true, fetching: false });
      });
  }

  set(obj, path, value) {
    let schema = obj; // a moving reference to internal objects within obj
    const pList = path.split(".");
    const len = pList.length;
    for (let i = 0; i < len - 1; i++) {
      const elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    if (typeof value === "object") {
      schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value);
    } else {
      schema[pList[len - 1]] = value;
    }
  }

  changeValue(key, value) {
    if (this.state.edit) {
      const current = this.state.change;
      this.set(current, key, value);
      console.log(current);
      this.setState({ change: current });
    }
  }

  close() {
    this.setState({
      open: false
    });
  }

  renderPossibleInserts(inserts) {
    return inserts.map((insert, index) => {
      return (
        <div key={index}>
          <p>
            <strong>Verdi: </strong>
            {insert.value} |
            <strong> Beskrivelse: </strong>
            {insert.description}
          </p>
        </div>
      );
    });
  }

  renderMail() {
    return Object.keys(this.props.settings.contact).map(key => {
      let emailValues;
      let messageValues;
      if (this.state.edit) {
        emailValues = {
          subject: this.state.change.contact[key].mail.subject,
          message: this.state.change.contact[key].mail.bodyHTML,
          subjectChange: this.changeValue.bind(
            this,
            `contact.${key}.mail.subject`
          ),
          messageChange: this.changeValue.bind(this, `contact.${key}.mail`),
          edit: true
        };
        messageValues = {
          message: this.state.change.contact[key].message.bodyText,
          messageChange: this.changeValue.bind(
            this,
            `contact.${key}.message.bodyText`
          ),
          edit: true
        };
      } else {
        emailValues = {
          subject: this.props.settings.contact[key].mail.subject,
          message: this.props.settings.contact[key].mail.bodyHTML,
          subjectChange: (x, y) => {},
          messageChange: (x, y) => {},
          edit: false
        };
        messageValues = {
          message: this.props.settings.contact[key].message.bodyText,
          messageChange: (x, y) => {},
          edit: false
        };
      }

      return (
        <div key={key} style={{ marginTop: "60px" }}>
          <h2>{this.props.settings.contact[key].title}</h2>
          <h3>Mail</h3>
          <Mail
            subject={emailValues.subject}
            message={emailValues.message}
            subjectChange={emailValues.subjectChange}
            messageChange={emailValues.messageChange}
            edit={emailValues.edit}
          />
          <h3>Melding/SMS</h3>
          <Message
            message={messageValues.message}
            messageChange={messageValues.messageChange}
            edit={messageValues.edit}
          />
          <h3>Mulige felter: </h3>
          {this.renderPossibleInserts(
            this.props.settings.contact[key].possibleInserts
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <div style={{ paddingTop: "100px" }}>
        <h1>Innstillinger</h1>
        {this.props.access && this.props.access.settings.write ?
        <div>
        {!this.state.edit ? (
          <button
            style={styles.button}
            className="change"
            onClick={this.edit.bind(this)}
          >
            Endre
          </button>
        ) : (
          ""
        )}
        </div>:("")}
        {this.state.edit ? (
          <button
            style={styles.button}
            className="change"
            onClick={this.abort.bind(this)}
          >
            Avbryt
          </button>
        ) : (
          ""
        )}
        {this.state.edit ? (
          <button
            style={styles.button}
            className="save"
            onClick={this.save.bind(this)}
          >
            Lagre
          </button>
        ) : (
          ""
        )}
        <h2>Mail ved reservasjon</h2>
        {this.props.settings.contactEmail ? (
          <div>
            <input
              disabled={!this.state.edit}
              type="text"
              onChange={(e => {
                e.preventDefault();
                this.changeValue("contactEmail", e.target.value);
              }).bind(this)}
              value={
                this.state.edit
                  ? this.state.change.contactEmail
                  : this.props.settings.contactEmail
              }
            />
          </div>
        ) : (
          ""
        )}
        {this.props.settings.contact ? this.renderMail() : "Loading"}
      </div>
    );
  }
}

const styles = {
  button: {
    width: "160px",
    marginBottom: "20px",
    marginRight: "10px",
    marginLeft: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: "18px",
    borderRadius: "8px"
  }
};

const mapStateToProps = state => {
  return {
    settings: state.settings.info,
    access: state.login.user.info.permissions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMount: () => {
      dispatch(getSettings());
    },
    requestNewInfo: () => {
      dispatch(getInfo(window.location.pathname));
    },
  };
};

const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsComponent);

export default Settings;
