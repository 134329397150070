import React from 'react';
import propTypes from 'prop-types';

import '../layout/loginLayout/LoginLayout.css';


const Login = props => (
  <div style={styles.container}>
    <img style={styles.img} src="imgs/logo.png" alt="Studio 26"/>
    <form className="style-5" style={styles.container} onSubmit={props.onSubmit}>
      <input style={{ ...styles.element, ...styles.input}} placeholder="Brukernavn" type="text" value={props.username} onChange={props.usernameOnChange} />
      <input style={{ ...styles.element, ...styles.input}} placeholder="Passord" type="password" value={props.password} onChange={props.passwordOnChange} />
      <input style={styles.element} onClick={props.onSubmit} type="submit" label="Logg inn" value="Logg inn" />
    </form>
    {props.error ? <h3>{props.message}</h3> : <h3>&nbsp;</h3>}
  </div>
)

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
    width: '100%',
    flexDirection: 'column',
    height: '100%',
  },
  element: {
    width: '90%',
    marginBottom: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '18px',
  },
  input: {
    background: '#F5F5F5',
  },
  img: {
    width: '70%',
    marginTop: '20px',
    marginBottom: '20px',
  },
}



Login.propTypes = {
  onSubmit: propTypes.func.isRequired,
  passwordOnChange: propTypes.func.isRequired,
  usernameOnChange: propTypes.func.isRequired,
  username: propTypes.string.isRequired,
  password: propTypes.string.isRequired,
  valid: propTypes.bool.isRequired,
  error: propTypes.bool.isRequired,
  message:propTypes.string.isRequired,
}

export default Login
