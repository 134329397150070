import initialState from '../initialState.js'

const search = (state = initialState.searchbar, action) => {
  switch(action.type) {
    case 'SEARCHING':
      return Object.assign({}, state, {
        searching: !action.search
      })
    default:
      return state
  }
}

export default search;
