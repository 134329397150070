import initialState from '../initialState.js';

const login = (state = initialState.login, action) => {
  switch(action.type) {
    case 'CHANGE_PASSWORD':
      return Object.assign({}, state, {
        password: action.password,
      })
    case 'CHANGE_USERNAME':
      return Object.assign({}, state, {
        username: action.username,
      })
    case 'REQUEST_TOKEN':
      return Object.assign({}, state, { request: true });
    case 'REQUEST_TOKEN_SUCCESS':
      return Object.assign({}, state, {
        user: { token: action.token, info: state.user.info },
        request: false,
      })
    case 'REQUEST_TOKEN_FAILURE':
      return Object.assign({}, state, {
        error: { message: action.message, active: true },
        request: false
      })
    case 'REQUEST_INFO':
      return Object.assign({}, state, {
        request: true,
      })
    case 'REQUEST_INFO_SUCCESS':
      return Object.assign({}, state, {
        user: {info: action.info, token: state.user.token},
        request: false,
      })
    case 'REQUEST_INFO_FAILURE':
      return Object.assign({}, state, {
        error: { message: action.message, active: true },
        request: false,
      })
    case 'LOG_OUT':
      return state
    default: return state;
  }
}

export default login;