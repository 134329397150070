import Months from "./calendarCalc.js";

const months = new Months();

const initialState = {
  error: {
    active: false,
    message: "No error"
  },
  login: {
    username: "",
    password: "",
    user: {
      info: {},
    },
    error: {
      active: false,
      message: "No error"
    },
    request: false
  },
  products: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },

  calendar: {
    day: months.today,
    month: months.name,
    year: months.year,
    days: months.days,
    monthstart: months.startIndex,
    startday: months.start,
    open: false,
    index: 0
  },
  searchbar: {
    searching: false
  },
  /*modal: {
      open: false
    },*/
  guestlists: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },
  employees: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },
  reservations: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },
  groups: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },
  guests: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {},
    page: 0,
    total: 0,
    showing: 0,
    pages: 0
  },
  benefits: {
    isFetching: false,
    data: [],
    error: {
      active: false,
      message: "No error"
    },
    info: {}
  },
  settings: {
    isFetching: false,
    info: {},
    error: {
      active: false,
      message: "No error"
    }
  }
};

export default initialState;
