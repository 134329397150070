import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Group from '../components/groups/Group';
import GroupList from '../components/groups/GroupList';
import Members from '../components/groups/Members';

export default class Groups extends Component {
    render() {
        return (
            <Switch>
                <Route path="/admin/groups/:id/members" component={Members}/>
                <Route path="/admin/groups/:id" component={Group} />
                <Route path="/admin/groups" component={GroupList} />
            </Switch>
        );
    }
}
