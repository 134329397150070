import { connect } from "react-redux";
import * as actions from "../actions/benefits.js";
import BenefitsPage from "../../ui/pages/Benefits.js";

const mapStateToProps = state => {
  return {
    benefits: state.benefits.data,
    error: state.benefits.error.active,
    loading: state.benefits.isFetching,
    message: state.benefits.error.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMount: () => {
      dispatch(actions.getBenefits());
    }
  };
};

const BenefitsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitsPage);

export default BenefitsContainer;
