import React, { Component } from 'react';
import Chart from "./Chart";
import './Statistics.css';
import Dropdown from "./Dropdown";
import axios from "axios";
import API_URL from "../../../url";

const currentDate = new Date()

const monthNames = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juni",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember"
]

const availableTypes = [
    {
        key: "checkins",
        name: "Antall sjekket inn"
    }
]

const availableYears = [{
    key: null,
    name: "Velg år"
}]

for (let i = 2018; i <= currentDate.getFullYear(); i++) {
    availableYears.push({
        key: i,
        name: i
    })
}


const availableMonths = [{
    key: 0,
    name: "Velg måned"
}]

for (let i = 1; i <= 12; i++) {
    availableMonths.push({
        key: i,
        name: monthNames[i - 1]
    })
}

class Statistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: availableTypes[0].key,
            year: null,
            month: null,
            data: []
        }
        this.fetchStatistics()
    }

    fetchStatistics() {
        const token = window.localStorage.getItem('token');
        const params = {
            year: this.state.year > 0 ? this.state.year : null,
            month: this.state.month > 0 ? this.state.month : null,
        }
        axios.get(API_URL + "admin/statistics/" + this.state.type, {
            headers: {
                Authorization: token,
            },
            params: {
                ...params
            }
        })
            .then(res => {
                let { boundaries, buckets } = res.data
                let data = boundaries.map(bound => ({name: bound, value: buckets.find(b => b._id === bound) ? buckets.find(b => b._id === bound).totalEntered : 0}))
                this.setState({
                    data: data
                })
            })
    }

    setParams(key, value) {
        this.setState({
            [key]: value
        })
        setTimeout(this.fetchStatistics.bind(this), 500)
    }

    render () {
        return (
            <div>
                <h1>Statistikk</h1>
                <Dropdown values={availableTypes} selectedKey={this.state.type} handleChange={(key) => this.setParams("type", key)}/>
                <Dropdown values={availableYears} selectedKey={availableYears[0]} handleChange={(key) => this.setParams("year", key)}/>
                {this.state.year ? <Dropdown values={availableMonths} selectedKey={this.state.month} handleChange={(key) => this.setParams("month", key)}/> : null}

                <Chart data={this.state.data}/>
            </div>
        );
    }
}

export default Statistics;