import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import { push } from "react-router-redux"
import url from "../../../url.js"
import * as actions from "../../../store/actions/groups.js"
import Dropdown from "../../pages/statistics/Dropdown"

class Group extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            delete: false,
            change: {},
            patch: false,
            error: false,
            fetching: false,
            showBenefitDropdown: false,
            selectedBenefit: null,
            allBenefits: [{ _id: null, name: "Velg fordel" }],
        }
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
        //this.toMembers = this.toMembers.bind(this);
        this.patchChanges = this.patchChanges.bind(this)
        this.deleteChanges = this.deleteChanges.bind(this)
        //this.patchPassword = this.patchPassword.bind(this);
        this.benefits = this.benefits.bind(this)
        this.toogleBenefitDropdown = this.toogleBenefitDropdown.bind(this)
        this.handleSelectedBenefit = this.handleSelectedBenefit.bind(this)
        this.getBenefitList = this.getBenefitList.bind(this)
        this.getAllBenefits = this.getAllBenefits.bind(this)
        this.deleteBenefit = this.deleteBenefit.bind(this)
    }

    componentDidMount() {
        this.props.onMount(this.props.match.params.id)
    }

    edit(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.group))
        this.setState({ change: props, edit: !this.state.edit })
    }

    delete(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.group))
        this.setState({ change: props, delete: true })
    }

    deleteBenefit(benefitID) {
        if (this.props.group) {
            const token = window.localStorage.getItem("token")
            axios
                .patch(
                    url + "admin/groups/" + this.props.group._id + "/removebenefit",
                    { id: benefitID },
                    {
                        headers: { Authorization: token },
                    },
                )
                .then(response => {
                    this.setState({ patch: true, fetching: false, edit: false })
                    this.props.onMount(this.props.match.params.id)
                    this.getBenefitList()
                    this.getAllBenefits()
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        }
    }

    getValue(key) {
        if (this.state.edit) {
            return this.state.change[key]
        }
        return this.props.group[key]
    }

    click(key) {
        if (this.state.edit) {
            const current = this.state.change
            current[key] = !current[key]
            this.setState({ change: current })
        }
    }

    changeValue(key, e) {
        e.preventDefault()
        if (this.state.edit) {
            const current = this.state.change
            current[key] = e.target.value
            this.setState({ change: current })
        }
    }

    /*
    changePassword(e) {
        e.preventDefault();
        this.setState({toMembers: e.target.value});
    }
    */
    patchChanges(e) {
        e.preventDefault()
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        axios
            .patch(url + "admin/groups/" + this.props.group._id, changes, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ patch: true, fetching: false, edit: false })
                this.props.onMount(this.props.match.params.id)
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    deleteChanges(e) {
        e.preventDefault()
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        console.log(this.props.group._id)
        axios
            .delete(url + "admin/groups/" + this.props.group._id, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false, delete: false })
            })
    }

    disabled(bool) {
        return bool ? { background: "#757575" } : {}
    }

    checkChange() {
        if (
            this.state.change.name !== this.props.group.name ||
            this.state.change.description !== this.props.group.description ||
            this.state.change.contactperson !== this.props.group.contactperson ||
            this.state.change.contactnumber !== this.props.group.contactnumber
        ) {
            return false
        } else {
            return true
        }
    }

    renderInfo() {
        return (
            <div>
                <form>
                    <div className="lineunder">
                        <h3>Gruppenavn</h3>
                        <input
                            className={this.state.edit ? "add-input" : "showInput"}
                            type="text"
                            onChange={this.changeValue.bind(this, "name")}
                            value={this.getValue("name")}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Beskrivelse</h3>
                        <textarea
                            className={this.state.edit ? "add-inputText" : "showInputText"}
                            type="text"
                            onChange={this.changeValue.bind(this, "description")}
                            value={this.getValue("description")}
                            readOnly={!this.state.edit}
                            required
                        />
                    </div>
                    <div className="lineunder">
                        <h3>Kontaktperson</h3>
                        <div>
                            <p>Navn</p>
                            <input
                                className={this.state.edit ? "add-input" : "showInputN"}
                                type="text"
                                onChange={this.changeValue.bind(this, "contactperson")}
                                value={this.getValue("contactperson")}
                                readOnly={!this.state.edit}
                                required
                            />
                        </div>
                        <div>
                            <p>Telefon</p>
                            <input
                                className={this.state.edit ? "add-input" : "showInputN"}
                                type="text"
                                onChange={this.changeValue.bind(this, "contactnumber")}
                                value={this.getValue("contactnumber")}
                                readOnly={!this.state.edit}
                                required
                            />
                        </div>
                    </div>
                    {!this.state.edit ? (
                        ""
                    ) : (
                        <button
                            disabled={this.checkChange()}
                            onClick={this.patchChanges}
                            style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                            className="save"
                        >
                            Lagre
                        </button>
                    )}

                    <div className="lineunderbold">{this.props.loading ? <p>Loading</p> : this.benefits()}</div>
                </form>
            </div>
        )
    }

    benefits() {
        return (
            <div>
                <h3>Fordeler</h3>
                {this.state.showBenefitDropdown ? (
                    <div style={{ display: "flex" }}>
                        <button style={styles.button} onClick={this.toogleBenefitDropdown} className="delete">
                            Avbryt
                        </button>
                        <Dropdown
                            selectedValue={this.state.selectedBenefit}
                            handleChange={this.handleSelectedBenefit}
                            values={
                                this.state.allBenefits
                                    ? this.state.allBenefits.map(benefit => ({ key: benefit._id, name: benefit.name }))
                                    : [{ key: null, name: "Velg" }]
                            }
                        />
                    </div>
                ) : (
                    <div>
                        {this.props.access && this.props.access.groups.write ? (
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.toogleBenefitDropdown()
                                }}
                                style={styles.button}
                                className="save"
                                disabled={this.state.edit}
                            >
                                Legg til fordel
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                {this.props.loading ? <p>Loading</p> : this.getBenefitList()}
            </div>
        )
    }

    getAllBenefits() {
        const token = window.localStorage.getItem("token")
        axios
            .get(url + "admin/benefits", {
                headers: { Authorization: token, "Content-Type": "application/json" },
            })
            .then(response => {
                let allBenefits = response.data.benefits
                let benefitsGroupDonthave = []
                allBenefits.push({ _id: null, name: "Velg fordel" })
                if (this.props.group.benefits) {
                    let benefitsId = []
                    // list of all benefits id this group have
                    this.props.group.benefits.map(benefit => benefitsId.push(benefit._id))

                    // remove benefits group already have
                    allBenefits
                        .filter(benefit => benefitsId.indexOf(benefit._id) < 0)
                        .map(benefit => benefitsGroupDonthave.push(benefit))
                }
                this.setState({ allBenefits: benefitsGroupDonthave })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getBenefitList() {
        return (
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <th>Navn</th>
                        <th>Beskrivelse</th>
                        <th>Fjern fordel</th>
                    </tr>
                    {this.props.loading || !this.props.group.benefits ? (
                        <tr>
                            <td colSpan={"2"}>Loading</td>
                        </tr>
                    ) : (
                        this.props.group.benefits.map(benefit => {
                            return (
                                <tr key={benefit._id}>
                                    <td>{benefit.name}</td>
                                    <td>{benefit.description}</td>
                                    <td>
                                        {this.props.access && this.props.access.groups.write ? (
                                            <button
                                                style={styles.button}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.deleteBenefit(benefit._id)
                                                }}
                                                className="delete"
                                            >
                                                Slett
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </table>
        )
    }

    handleSelectedBenefit(value) {
        if (value) {
            const token = window.localStorage.getItem("token")
            axios
                .patch(
                    url + "admin/groups/" + this.props.group._id + "/newbenefit",
                    { id: value },
                    {
                        headers: { Authorization: token },
                    },
                )
                .then(response => {
                    this.setState({ patch: true, fetching: false, edit: false })
                    this.props.onMount(this.props.match.params.id)
                    this.getBenefitList()
                    this.getAllBenefits()
                    this.toogleBenefitDropdown()
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        }
        this.setState({ selectedBenefit: null })
    }

    toogleBenefitDropdown() {
        this.getAllBenefits()
        this.setState({ showBenefitDropdown: !this.state.showBenefitDropdown })
    }

    status() {
        if (this.state.fetching) {
            return <p>Oppdaterer...</p>
        } else if (this.state.error) {
            return <p style={{ color: "red" }}>Error</p>
        } else if (this.state.patch) {
            return <p style={{ color: "green" }}>Oppdatert</p>
        } else {
            return <p>&nbsp;</p>
        }
    }

    deleteModal() {
        if (this.state.delete) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2>Sikker på at du vil slette {this.props.group.name}?</h2>
                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({ delete: false })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button onClick={this.deleteChanges} style={styles.button} className="delete">
                                Fullfør
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    render() {
        return (
            <div>
                {this.deleteModal()}
                {/*this.newPasswordModal()*/}
                <div>
                    <div className="fixed gap">
                        <h1 className="title">{this.props.loading ? <p>Loading</p> : this.props.group.name}</h1>
                    </div>
                    {this.status()}
                    <div className="gap">
                        {this.props.access && this.props.access.groups.write ? (
                            <button
                                onClick={this.edit}
                                style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                                className={this.state.edit ? "delete" : "change"}
                            >
                                {this.state.edit ? "Avbryt" : "Endre"}
                            </button>
                        ) : (
                            ""
                        )}
                        {this.state.edit ? (
                            ""
                        ) : (
                            <button
                                onClick={() => this.props.onToMembers(this.props.group._id)}
                                style={styles.button}
                                className="save"
                            >
                                Medlemmer
                            </button>
                        )}
                        {this.state.edit ? (
                            ""
                        ) : (
                            <span>
                                {this.props.access && this.props.access.groups.write ? (
                                    <button style={styles.button} onClick={this.delete} className="delete">
                                        Slette
                                    </button>
                                ) : (
                                    ""
                                )}
                            </span>
                        )}
                        {!this.state.edit ? (
                            ""
                        ) : (
                            <button
                                disabled={this.checkChange()}
                                onClick={this.patchChanges}
                                style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                                className="save"
                            >
                                Lagre
                            </button>
                        )}
                    </div>
                    <div className="gap">{this.props.loading ? <p>Loading</p> : this.renderInfo()}</div>
                </div>
            </div>
        )
    }
}

const styles = {
    button: {
        width: "160px",
        marginBottom: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        height: "200px",
        width: "90%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}

const mapStateToProps = state => {
    return {
        group: state.groups.info,
        error: state.groups.error.active,
        access: state.login.user.info.permissions,
        loading: state.groups.isFetching,
        message: state.groups.error.message,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: id => {
            dispatch(actions.getGroup(id))
        },
        onDelete: () => {
            dispatch(push("/admin/groups"))
        },
        onToMembers: id => {
            dispatch(push("/admin/groups/" + id + "/members"))
        },
    }
}

const GroupContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Group)

export default GroupContainer
