import React, { Component } from "react";
import { createPortal } from "react-dom";
import "./styles/modal.css";

const modalRoot = document.querySelector("#modal");

export default class Modal extends Component {


  exit(e) {
    this.props.exit();
  }

  outer(e) {
    if (e.target.id === "outer") {
      this.props.exit();
    }
  }

  render() {
    return createPortal(
      <div onClick={this.outer.bind(this)} style={styles.outer} id="outer">
        <div className="inner-modal">
          <div style={styles.header}>
            <h3>{this.props.title}</h3>
            <div
              className="exit"
              style={styles.exit}
              onClick={this.exit.bind(this)}
            >
              <i className="material-icons">close</i>
            </div>
          </div>
          <div className="modal-content">{this.props.children}</div>
        </div>
      </div>,
      modalRoot
    );
  }
}

const styles = {
  outer: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
    zIndex: "3000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0,0,0,0.5)"
  },
  header: {
    width: "100%",
    height: "70px",
    background: "#01579B",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  exit: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};
