import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import axios from "axios"
import Kart from "../Kart.js"
import * as actions from "../../../store/actions/reservations.js"
import { MakeCalendar } from "../../layout/adminLayout/AdminLayout.js"
import "./reservations.css"
import url from "../../../url.js"
import NewReservation from "./NewReservation.js"
import { getSettings } from "../../../store/actions/settings.js"
import queryString from "query-string"
import Mail from "../messageAndMail/mail"
import Message from "../messageAndMail/message"
import moment from "moment"
import Modal from "../Modal"
import "../communication/communication.css"
import nbLocale from "date-fns/locale/nb"

import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers"
import DateFnsUtils from "@date-io/date-fns"

class Reservations extends Component {
    constructor(props) {
        super(props)

        const queryParams = queryString.parse(this.props.location)
        const reservation = queryString.id !== null ? this.getReservationById(queryParams.id) : null

        console.log("Reservation: " + JSON.stringify(reservation)) //this.props.reservations);

        this.state = {
            addmail: false,
            addmessage: false,
            add: false,
            selected: reservation !== null ? [reservation.tables] : [], // queryParams.id ? [this.getReservationById(queryParams.id)] : [],
            newres: [],
            date: new Date(),
            active: true,
            kart: true,
            calendar: false,
            info: null,
            edit: reservation !== null, //reservation !== null, //queryParams.id !== null,
            editInput: false,
            new: false,
            delete: false,
            day: queryParams.day
                ? queryParams.day
                : moment()
                      .date()
                      .toString(),
            month: queryParams.month ? queryParams.month : (moment().month() + 1).toString(),
            year: queryParams.year
                ? queryParams.year
                : moment()
                      .year()
                      .toString(),
            change:
                reservation !== null
                    ? reservation
                    : {
                          totalGuests: "",
                          freeEntry: "",
                          comment: "",
                          guest: {
                              name: "",
                              number: "+47",
                              email: "",
                          },
                          email: {
                              send: false,
                              bodyHTML: "",
                              bodyText: "",
                              subject: "",
                          },
                          message: {
                              send: false,
                              message: "",
                          },
                          reciever: "",
                          hasSentMessage: false,
                          confirmed: false,
                          day: moment()
                              .date()
                              .toString(),
                          month: (moment().month() + 1).toString(),
                          year: moment()
                              .year()
                              .toString(),
                          hasEntered: "",
                          hasUsedFreeEntry: "",
                          _id: "",
                      },
            changeMessage: {
                totalGuests: "",
                freeEntry: "",
                comment: "",
                guest: {
                    name: "",
                    number: "+47",
                    email: "",
                },
                email: {
                    send: false,
                    bodyHTML: "",
                    bodyText: "",
                    subject: "",
                },
                message: {
                    send: false,
                    message: "",
                },
                reciever: "",
                day: "",
                month: "",
                year: "",
                hasSentMessage: false,
                confirmed: false,
                hasEntered: "",
                hasUsedFreeEntry: "",
                _id: "",
            },
        }
        this.edit = this.edit.bind(this)
        this.editReservation = this.editReservation.bind(this)
        this.deleteChanges = this.deleteChanges.bind(this)
        this.addmail = this.addmail.bind(this)
        this.addmessage = this.addmessage.bind(this)
        this.add = this.add.bind(this)
        this.click = this.click.bind(this)
        this.proceed = this.proceed.bind(this)
    }

    renderDate() {
        return (
            <span>
                <input
                    placeholder="Dag"
                    onChange={this.changeValue.bind(this, "day")}
                    type="number"
                    className={this.state.editInput ? "editDate" : "showDate"}
                    min="1"
                    value={this.state.change.day}
                    readOnly={!this.state.editInput}
                    required
                />
                <input
                    placeholder="Måned"
                    onChange={this.changeValue.bind(this, "moth")}
                    type="number"
                    className={this.state.editInput ? "editDate" : "showDate"}
                    value={this.state.change.month}
                    readOnly={!this.state.editInput}
                    required
                />
                <input
                    placeholder="År"
                    onChange={this.changeValue.bind(this, "year")}
                    type="number"
                    className={this.state.editInput ? "editDate" : "showDate"}
                    min="2018"
                    value={this.state.change.year}
                    readOnly={!this.state.editInput}
                    required
                />
            </span>
        )
    }

    edit(e) {
        e.preventDefault()
        const props = JSON.parse(JSON.stringify(this.props.reservation))

        this.setState({ change: props, edit: !this.state.edit })
    }

    changeDate = date => {
        let day = date.getDate().toString()
        let month = (date.getMonth() + 1).toString()
        let year = date.getFullYear().toString()
        day = day.length < 2 ? "0" + day : day
        month = month.length < 2 ? "0" + month : month
        this.setState({ day: day, month: month, year: year, change: { ...this.state.change, day, month, year } })
        this.props.onMount(day, month, year)
    }

    getValue(key) {
        if (this.state.edit) {
            return this.state.change[key]
        }
        return this.props.product[key]
    }

    componentDidMount() {
        // let date = new MakeCalendar(0)
        // const { month, day, year } = date
        this.props.onMount(this.state.change.day, this.state.change.month, this.state.change.year)
    }

    select(element) {
        if (!this.state.editInput && this.state.edit) {
            return
        }
        if (this.state.new || this.state.edit) {
            const current = this.state.selected
            if (current.indexOf(element) < 0) {
                current.push(element)
            } else {
                const index = current.indexOf(element)
                current.splice(index, 1)
            }
            this.setState({
                selected: current,
            })
        } else {
            if (this.state.selected[0] === element) {
                this.setState({ selected: [] })
            } else {
                this.setState({
                    selected: [element],
                })
            }
        }
    }

    selectEdit(element) {
        if (this.state.edit) {
            const current = this.state.selected
            const tab = this.state.info.tables
            tab.map(table => {
                if (current.indexOf(table)) {
                    return current.push(table)
                } else {
                    return
                }
            })
            if (current.indexOf(element) < 0) {
                current.push(element)
            } else {
                const index = current.indexOf(element)
                current.splice(index, 1)
            }
            this.setState({
                selected: current,
            })
        } else {
            this.setState({
                selected: [element],
            })
        }
    }

    calendar(e) {
        e.preventDefault()
        this.setState({
            calendar: !this.state.calendar,
        })
    }

    kartListe(e) {
        e.preventDefault()
        this.setState({
            kart: !this.state.kart,
        })
    }

    getReservation(table) {
        const resTable = []
        if (this.props.reservations.length > 0) {
            const res = this.props.reservations
            for (let i = 0; i < res.length; i += 1) {
                for (let j = 0; j < res[i].tables.length; j += 1) {
                    if (res[i].tables[j] === table) {
                        resTable.push(res[i])
                    }
                }
            }
        }
        return resTable
    }

    getReservationById(id) {
        const res = this.props.reservations.filter(res => {
            return res._id === id
        })
        return res.length > 0 ? res[0] : null
    }

    showReservations() {
        if (true) {
            let res
            if (this.state.selected.length > 0) {
                if (this.state.edit) {
                    res = this.getReservation(this.state.selected[0])
                } else {
                    res = this.props.reservations
                    if (res) {
                        res = res.filter(r => r.tables.some(table => this.state.selected.indexOf(table) > -1))
                    }
                }
            } else {
                res = this.props.reservations
            }
            if (!this.state.edit) {
                return res.map((_reservation, i) => {
                    let reservation = Object.assign({}, _reservation)
                    return (
                        <div
                            key={i}
                            onClick={
                                this.props.access && this.props.access.reservations.write
                                    ? (() => {
                                          this.setState({
                                              change: reservation,
                                              edit: true,
                                              selected: JSON.parse(JSON.stringify(reservation.tables)),
                                          })
                                      }).bind(this)
                                    : ""
                            }
                            style={{
                                width: "95%",
                                cursor: "pointer",
                            }}
                        >
                            <div className="card">
                                <h4>
                                    <span style={{ fontWeight: "bold" }}>Navn: </span>
                                    <div>{reservation.guest.name}</div>
                                </h4>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>Antall: </span>
                                    <div>{reservation.totalGuests}</div>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>Gratis Inngang: </span>
                                    <div>{reservation.freeEntry}</div>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>Reserverte bord: </span>
                                    <div>{this.renderTables(reservation.tables)}</div>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>Kommentar: </span>
                                    <div>{reservation.comment}</div>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>Bekreftet </span>
                                    <div>
                                        {reservation.confirmed ? (
                                            <i style={{ color: "#33691e" }} className="material-icons">
                                                check_circle
                                            </i>
                                        ) : (
                                            <i style={{ color: "#b71c1c" }} className="material-icons">
                                                highlight_off
                                            </i>
                                        )}
                                    </div>
                                </p>
                            </div>
                        </div>
                    )
                })
            } else {
                return (
                    <div style={{ width: "350px" }}>
                        <div>{this.deleteModal()}</div>
                        <h2>Reservasjon</h2>
                        <div>{this.renderComs()}</div>
                        <div>
                            <div>{this.renderDate()}</div>
                            <h4>
                                <span style={{ fontWeight: "bold" }}>Navn: </span>
                                <div style={{ fontWeight: "normal" }}>{this.state.change.guest.name}</div>
                            </h4>
                            <h4>
                                <span style={{ fontWeight: "bold" }}>Bord før endring: </span>
                                <div style={{ fontWeight: "normal" }}>
                                    {this.renderTables(this.state.change.tables)}
                                </div>
                            </h4>
                            <h4>
                                <span style={{ fontWeight: "bold" }}>Bord etter endring: </span>
                                <div style={{ fontWeight: "normal" }}>{this.renderSelected()}</div>
                            </h4>

                            <form>
                                <div>
                                    <div>
                                        <span style={{ fontWeight: "bold" }}>#Gjester: </span>
                                    </div>
                                    <input
                                        placeholder="Antall gjester"
                                        type="number"
                                        className={this.state.editInput ? "editNumber" : "showNumbers"}
                                        onChange={this.changeValue.bind(this, "totalGuests")}
                                        value={this.state.change.totalGuests}
                                        readOnly={!this.state.editInput}
                                        required
                                    />
                                </div>
                                <div>
                                    <div>
                                        <span style={{ fontWeight: "bold" }}>#Gratis inngang: </span>
                                    </div>
                                    <input
                                        placeholder="#Gratis inngang"
                                        onChange={this.changeValue.bind(this, "freeEntry")}
                                        type="number"
                                        className={this.state.editInput ? "editNumber" : "showNumbers"}
                                        min="0"
                                        value={this.state.change.freeEntry}
                                        readOnly={!this.state.editInput}
                                        required
                                    />
                                </div>
                                <div>
                                    <span style={{ fontWeight: "bold" }}>Kommentar: </span>
                                    <textarea
                                        placeholder="Comment"
                                        onChange={this.changeValue.bind(this, "comment")}
                                        type="text"
                                        className={this.state.editInput ? "add-inputF" : "showComment"}
                                        maxLength="250"
                                        value={this.state.change.comment}
                                        readOnly={!this.state.editInput}
                                        required
                                    />
                                </div>
                            </form>
                            {this.props.access && this.props.access.reservations.write ? (
                                <div>
                                    <button
                                        onClick={e => {
                                            e.preventDefault()
                                            this.setState({ editInput: !this.state.editInput })
                                        }}
                                        style={{
                                            ...styles.buttonSmaller,
                                            ...{ marginRight: "" },
                                        }}
                                        className="change"
                                    >
                                        {this.state.editInput ? "Avbryt" : "Endre"}
                                    </button>
                                    <button
                                        onClick={e => {
                                            e.preventDefault()
                                            this.setState({ delete: true })
                                        }}
                                        style={styles.buttonSmaller}
                                        className="delete"
                                    >
                                        Slette
                                    </button>
                                    {this.state.editInput ? (
                                        <button
                                            disabled={false}
                                            onClick={this.editReservation}
                                            style={styles.buttonSmaller}
                                            className="save"
                                        >
                                            Lagre
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )
                /* return <div> {this.state.info._id}</div>; */
            }
        }
        return ""
    }

    checkInTotal(e) {
        let value = e.target.value
        let current = this.state.change
        current.hasEntered = parseInt(value)
        this.setState({
            change: current,
        })
    }

    checkInFree(e) {
        let value = e.target.value
        let current = this.state.change
        current.hasUsedFreeEntry = parseInt(value)
        this.setState({
            change: current,
        })
    }

    deleteChanges(e) {
        e.preventDefault()
        const token = window.localStorage.getItem("token")
        axios
            .delete(url + "admin/reservations/" + this.state.change._id, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ delete: false, edit: false })
                this.props.onMount(this.state.day, this.state.month, this.state.year)
                // this.props.onDeleteReservation(this.state.day, this.state.month. this.state.year)
            })
            .catch(error => {
                this.setState({
                    error: true,
                    delete: false,
                    edit: false,
                })
            })
    }

    deleteModal() {
        if (this.state.delete) {
            return (
                <div style={styles.del}>
                    <div style={styles.delCard}>
                        <h2>Sikker på at du vil slette den valgte reservasjonen?</h2>
                        <div style={styles.delCont}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({ delete: false })
                                }}
                                style={styles.button}
                                className="change"
                            >
                                Avbryt
                            </button>
                            <button onClick={this.deleteChanges} style={styles.button} className="delete">
                                Fullfør
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    editReservation(e) {
        e.preventDefault()
        const selected = this.state.selected
        const current = this.state
        current.change.tables = selected
        this.setState(current)
        const info = {
            totalGuests: parseInt(this.state.change.totalGuests),
            freeEntry: parseInt(this.state.change.freeEntry),
            hasEntered: parseInt(this.state.change.hasEntered),
            hasUsedFreeEntry: parseInt(this.state.change.hasUsedFreeEntry),
            comment: this.state.change.comment,
            tables: this.state.selected,
            day: this.state.change.day,
            month: this.state.change.month,
            year: this.state.change.year,
        }

        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        axios
            .patch(url + "admin/reservations/" + this.state.change._id, info, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ editInput: false, selected: [] })
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
    }

    renderSelected() {
        let string = ""
        this.state.selected.map(table => {
            string += table + ", "
        })
        return string
    }

    renderTables(tables) {
        if (tables.length >= 1) {
            let string = ""
            string += tables[0]
            for (let j = 1; j < tables.length; j += 1) {
                string += ", " + tables[j]
            }
            return string
        } else {
            return ""
        }
    }

    findBooked() {
        const booked = []
        if (this.props.reservations.length > 0) {
            const res = this.props.reservations
            for (let i = 0; i < res.length; i += 1) {
                for (let j = 0; j < res[i].tables.length; j += 1) {
                    if (booked.indexOf(res[i].tables[j]) < 0) {
                        booked.push(res[i].tables[j])
                    }
                }
            }
        }
        return booked
    }

    goToTable(reservation) {
        this.setState({
            kart: true,
            change: reservation,
            edit: true,
            selected: JSON.parse(JSON.stringify(reservation.tables)),
        })
    }

    renderTable() {
        if (this.props.reservations.length > 0) {
            return this.props.reservations.map(reservation => {
                return (
                    <div
                        value={reservation._id}
                        key={reservation._id}
                        style={{ cursor: "pointer", padding: "5px" }}
                        onClick={
                            this.props.access && this.props.access.reservations.write
                                ? this.goToTable.bind(this, reservation)
                                : ""
                        }
                        className="cardres"
                    >
                        <p>
                            <span style={{ fontWeight: "bold" }}>Navn: </span>
                            {reservation.guest.info.firstname + " " + reservation.guest.info.lastname}
                        </p>

                        <p>
                            <span style={{ fontWeight: "bold" }}>Bord: </span>
                            {this.renderTables(reservation.tables)}
                        </p>
                        <p>
                            <span style={{ fontWeight: "bold" }}>Total antall gjester: </span>
                            {reservation.totalGuests}
                        </p>
                        <p>
                            <span style={{ fontWeight: "bold" }}>Gratis inngang: </span>
                            {reservation.freeEntry}
                        </p>
                        <p>
                            <span style={{ fontWeight: "bold" }}>Kommentar: </span>
                            {reservation.comment}
                        </p>
                    </div>
                )
            })
        }
        return <h2>Ingen reservasjoner for denne datoen</h2>
    }

    changeValue(key, e) {
        e.preventDefault()
        if (this.state.edit || this.state.addmail || this.state.addmessage) {
            const current = this.state.change
            current[key] = e.target.value
            this.setState({ change: current })
        }
    }

    changeValueMessage(key, e) {
        e.preventDefault()
        if (this.state.edit || this.state.addmail || this.state.addmessage) {
            const current = this.state.changeMessage
            current[key] = e.target.value
            this.setState({ changeMessage: current })
        }
    }

    abortNewReservation() {
        this.setState({ new: false, selected: [] })
    }

    abortEditReservation() {
        this.setState({ edit: false, selected: [] })
    }

    renderListeKart() {
        if (this.state.kart) {
            return (
                <div>
                    <div className="karts">
                        <Kart
                            click={this.select.bind(this)}
                            selected={this.state.selected}
                            booked={this.findBooked()}
                            newres={this.state.newres}
                        />
                    </div>
                    <div className="karts">
                        <div>
                            {!this.state.new ? (
                                <div>
                                    {this.props.access && this.props.access.reservations.write ? (
                                        <button
                                            onClick={
                                                this.state.edit || this.state.editInput
                                                    ? response => {
                                                          this.setState({ edit: false })
                                                          this.props.onDelete()
                                                      }
                                                    : (e => {
                                                          e.preventDefault()
                                                          this.setState({ new: true })
                                                      }).bind(this)
                                            }
                                            style={{
                                                ...styles.button,
                                                ...{},
                                            }}
                                            className="change"
                                        >
                                            {this.state.edit || this.state.editInput ? "Tilbake" : "Ny reservasjon"}
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    <div className={"bandP"}>{this.showReservations()}</div>
                                </div>
                            ) : (
                                <NewReservation
                                    abort={this.abortNewReservation.bind(this)}
                                    selected={this.state.selected}
                                    day={this.state.change.day}
                                    month={this.state.change.month}
                                    year={this.state.change.year}
                                    abortEdit={this.abortEditReservation.bind(this)}
                                />
                            )}{" "}
                        </div>
                    </div>
                </div>
            )
        } else {
            return this.renderTable()
        }
    }

    click() {
        const current = this.state.change
        current.requiresReservation = !current.requiresReservation
        this.setState({ change: current })
    }

    changeComValues(key, value) {
        const current = this.state.changeMessage
        this.set(current, key, value)
        this.setState({ changeMessage: current })
    }

    changeReciever(key, value) {
        const current = this.state.change.guest.email
        const set = this.set(current, key, value)
        this.setState({ change: current })
    }

    addmail() {
        this.setState({
            addmail: !this.state.addmail,
        })
    }

    addmessage() {
        this.setState({
            addmessage: !this.state.addmessage,
        })
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    reciever(rec) {
        this.setState({
            reciever: rec,
        })
    }

    exitModal(type) {
        const current = this.state.changeMessage
        current.email.bodyHTML = ""
        current.email.bodyText = ""
        current.message.message = ""
        current[type] = false
        this.setState(current)
    }

    renderMail() {
        const emailValues = {
            reciever: this.state.changeMessage.reciever,
            subject: this.state.changeMessage.email.subject,
            message: this.state.changeMessage.email.bodyHTML,
            recieverChange: this.changeComValues.bind(this, "reciever"),
            subjectChange: this.changeComValues.bind(this, "email.subject"),
            messageChange: this.changeComValues.bind(this, "email"),
        }
        return (
            <Mail
                subject={emailValues.subject}
                message={emailValues.message}
                subjectChange={emailValues.subjectChange}
                messageChange={emailValues.messageChange}
                edit={true}
            />
        )
    }

    renderSMS() {
        const smsValues = {
            message: this.state.changeMessage.message.message,
            messageChange: this.changeComValues.bind(this, "message.message"),
        }
        return <Message message={smsValues.message} messageChange={smsValues.messageChange} edit={true} />
    }

    changeOk(type) {
        const o = {}
        o[type] = false
        this.setState(o)
    }

    set(obj, path, value) {
        let schema = obj // a moving reference to internal objects within obj
        const pList = path.split(".")
        const len = pList.length
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i]
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem]
        }

        if (typeof value === "object") {
            schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value)
        } else {
            schema[pList[len - 1]] = value
        }
    }

    renderGuest() {
        return (
            <div>
                <p>
                    {this.state.change.guest.info.firstname} {this.state.change.guest.info.lastname}
                </p>
                <p>{this.state.change.guest.info.number}</p>
                <p>{this.state.change.guest.info.email}</p>
            </div>
        )
    }

    renderComModal(openType, title, renderFunc) {
        return (
            <div>
                <Modal title={title} exit={this.exitModal.bind(this, openType)}>
                    {this.renderGuest()}
                    <div>Mottaker:</div>
                    <input
                        placeholder={this.state.addmail ? "kari@nordmann.no" : "+4740019230"}
                        type="text"
                        className="add-inputF"
                        value={
                            this.state.addmail
                                ? this.state.change.guest.info.email
                                : this.state.change.guest.info.number
                        }
                        maxlength="60"
                        readOnly={true}
                        required
                    />
                    {renderFunc()}
                    <button
                        onClick={this.proceed}
                        style={{
                            ...styles.button,
                        }}
                        className="save"
                    >
                        Send
                    </button>
                </Modal>
            </div>
        )
    }

    renderComModals() {
        return (
            <div>
                {this.state.addmail ? this.renderComModal("addmail", "Send mail", this.renderMail.bind(this)) : ""}
                {this.state.addmessage ? this.renderComModal("addmessage", "Send SMS", this.renderSMS.bind(this)) : ""}
            </div>
        )
    }

    proceed(e) {
        e.preventDefault()
        var info = {}
        var postUrl = ""
        var reciever = ""
        if (this.state.addmail) {
            postUrl = "admin/com"
            info = {
                email: {
                    send: true,
                    bodyHTML: this.state.changeMessage.email.bodyHTML,
                    receiver: this.state.change.guest.info.email,
                    subject: this.state.changeMessage.email.subject,
                    bodyText: this.state.changeMessage.email.bodyText,
                },
                message: {
                    send: false,
                    receiver: this.state.change.guest.info.number,
                    message: "j",
                },
            }
        } else if (this.state.addmessage) {
            postUrl = "admin/com"
            info = {
                message: {
                    send: true,
                    receiver: this.state.change.guest.info.number,
                    message: this.state.changeMessage.message.message,
                },
                email: {
                    send: false,
                    bodyHTML: "j",
                    receiver: this.state.change.guest.info.email,
                    subject: "j",
                    bodyText: "j",
                },
            }
        }
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.changeMessage
        delete changes._id
        axios
            .post(url + postUrl, info, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ addmail: false })
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ error: true, fetching: false })
            })
            .finally(response => {
                this.setState({ addmessage: false })
                this.props.onDelete()
            })
    }

    removeGuest() {
        this.setState({ guest: false })
    }

    guest(id) {
        this.props.onGuestSelect(id)
        this.setState({ guest: true, add: false })
    }

    exit() {
        this.setState({ add: false })
    }

    renderComs() {
        return (
            <div>
                <button onClick={this.addmail} style={{ ...styles.button, ...{ marginLeft: "0px" } }} className="save">
                    {" "}
                    Send Mail{" "}
                </button>
                <button
                    onClick={this.addmessage}
                    style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                    className="save"
                >
                    {" "}
                    Send SMS{" "}
                </button>
                <div>{this.renderComModals()}</div>
            </div>
        )
    }

    render() {
        const { day, month, year } = this.state.change
        return (
            <div>
                <div style={{ marginBottom: "40px", display: "flex", flexDirection: "column" }}>
                    <h1>Reservasjoner</h1>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nbLocale}>
                        <InlineDatePicker
                            label={"Velg dato"}
                            onChange={this.changeDate}
                            value={moment([year, month, day].join("-"))}
                            allowKeyboardControl={true}
                            autoOk={true}
                            style={{ maxWidth: 200 }}
                        />
                    </MuiPickersUtilsProvider>
                    <button
                        onClick={this.kartListe.bind(this)}
                        style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                        className="change"
                    >
                        {this.state.kart ? "Liste" : "Kart"}
                    </button>
                </div>

                {this.renderListeKart()}
            </div>
        )
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    buttonSmaller: {
        width: "100px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "5px",
        marginLeft: "5px",
        paddingTop: "1px",
        paddingBottom: "1px",
        paddingLeft: "2px",
        paddingRight: "2px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: "",
        width: "98%",
        maxWidth: "800px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px 5px 5px 5px",
        textAlign: "center",
    },
    delCont: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        height: "90%",
    },
}

const mapStateToProps = (state, ownProps) => {
    return {
        reservations: state.reservations.data,
        error: state.reservations.error.active,
        access: state.login.user.info.permissions,
        loading: state.reservations.isFetching,
        message: state.reservations.error.message,
        settings: state.settings,
        location: ownProps.location.search,
        guest: state.guests,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: (day, month, year) => {
            dispatch(actions.getReservations(day, month, year))
            dispatch(getSettings())
        },
        onReservationClick: id => {
            dispatch(push(`/admin/reservations/${id}`))
        },
        onDelete: () => {
            dispatch(push("/admin/tableorder"))
        },
        onDeleteReservation: (day, month, year) => {
            console.log("...")
            window.location.reload() // Cheap hack to reload, dont have time to find out why it does not re-render
            // dispatch(push(`/admin/tableorder?day=${day}&month=${month}&year=${year}`));
            dispatch(actions.getReservations(day, month, year))
        },
    }
}

const ReservationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reservations)

export default ReservationsContainer
