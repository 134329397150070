import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import axios from "axios"
import url from "../../../url.js"
import * as guestsActions from "../../../store/actions/guests.js"
import * as groupsActions from "../../../store/actions/groups.js"
import "./members.css"
import DefaultList from "../DefaultList"
import FaCheckCircleO from "react-icons/lib/fa/check-circle-o"
import FileUploader from "./fileUploader"
import Mail from "../messageAndMail/mail"
import Message from "../messageAndMail/message"
import Modal from "../Modal"
import "../communication/communication.css"

class Members extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addmail: false,
            addmessage: false,
            add: false,
            edit: false,
            search: "",
            change: {
                added: [],
                removed: [],
            },
            changeMessage: {
                guest: {
                    name: "Gustav Dyngeseth",
                    number: "+4740019230",
                    email: "gustdyn@gmail.com",
                },
                email: {
                    send: false,
                    bodyHTML: "",
                    bodyText: "",
                    subject: "",
                },
                message: {
                    send: false,
                    message: "",
                },
                reciever: "",
                day: "",
                month: "",
                year: "",
                hasSentMessage: false,
                confirmed: false,
                hasEntered: "",
                hasUsedFreeEntry: "",
                _id: "",
            },
            showFileUploader: false,
        }
        this.isChosen = this.isChosen.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.edit = this.edit.bind(this)
        this.onClickMemberRow = this.onClickMemberRow.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.isChosen = this.isChosen.bind(this)
        this.patchChanges = this.patchChanges.bind(this)
        this.addmail = this.addmail.bind(this)
        this.addmessage = this.addmessage.bind(this)
        this.add = this.add.bind(this)
        this.proceed = this.proceed.bind(this)
        this.postSingle = this.postSingle.bind(this)
    }

    handleSearchChange(e) {
        e.preventDefault()
        this.setState({ search: e.target.value })
        if (this.state.edit) {
            this.props.get(e.target.value, 0)
        }
    }

    next(e) {
        e.preventDefault()
        if (this.props.page < this.props.pages) {
            this.props.get(e.target.value, this.props.page + 1)
        }
    }

    prev(e) {
        e.preventDefault()
        if (this.props.page > 0) {
            this.props.get(e.target.value, this.props.page - 1)
        }
    }

    componentDidMount() {
        this.props.onMount(this.props.match.params.id)
    }

    onClickMemberRow(id) {
        if (this.state.edit) {
            this.selectItem(id)
        } else {
            this.props.onMemberClick(id)
        }
    }

    renderTable() {
        if (this.state.edit) {
            let config = [
                { title: "Gjest", value: obj => obj.firstname + " " + obj.lastname },
                { title: "Medlem", value: obj => (this.isChosen(obj._id) ? <FaCheckCircleO size={16} /> : "") },
            ]
            let members = this.props.guests

            return <DefaultList data={members} config={config} onClickRow={this.onClickMemberRow} />
        } else {
            let config = [{ title: "Navn", value: obj => obj.firstname + " " + obj.lastname }]
            let members = this.props.group.members.filter(
                member =>
                    (member.firstname + " " + member.lastname).toLowerCase().indexOf(this.state.search.toLowerCase()) >=
                    0,
            )

            return <DefaultList data={members} config={config} onClickRow={this.onClickMemberRow} />
        }
    }

    patchChanges() {
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.change
        delete changes._id
        let info
        if (this.state.change.removed.length > 0) {
            info = { guests: this.state.change.removed }
            axios
                .patch(url + "admin/groups/" + this.props.match.params.id + "/removeMembers", info, {
                    headers: { Authorization: token },
                })
                .then(response => {
                    this.setState({
                        edit: false,
                        patch: true,
                        search: "",
                        change: {
                            added: [],
                            removed: [],
                        },
                    })
                    this.props.onMount(this.props.match.params.id)
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        }

        if (this.state.change.added.length > 0) {
            info = {
                guests: this.state.change.added.map(mem => {
                    return { id: mem }
                }),
                ids: true,
            }
            axios
                .patch(url + "admin/groups/" + this.props.match.params.id + "/newmembers", info, {
                    headers: { Authorization: token },
                })
                .then(response => {
                    this.setState({
                        fetching: false,
                        patch: true,
                        edit: false,
                        search: "",
                        change: {
                            added: [],
                            removed: [],
                        },
                    })
                    this.props.onMount(this.props.match.params.id)
                })
                .catch(error => {
                    this.setState({ error: true, fetching: false })
                })
        }
        if (this.state.change.removed.length <= 0 && this.state.change.added.length <= 0) {
            this.setState({
                fetching: false,
                patch: false,
                edit: false,
                search: "",
                change: {
                    added: [],
                    removed: [],
                },
            })
            this.props.onMount(this.props.match.params.id)
        }
    }

    status() {
        if (this.state.fetching || this.props.loading) {
            return <p>Oppdaterer...</p>
        } else if (this.state.error) {
            return <p style={{ color: "red" }}>Error</p>
        } else if (this.state.patch) {
            setTimeout(() => {
                this.setState({ patch: false })
            }, 3000)
            return <p style={{ color: "green" }}>Oppdatert</p>
        } else {
            return <p>&nbsp;</p>
        }
    }

    selectItem(id) {
        let change = this.state.change
        let members = this.props.group.members.map(member => member._id)
        let addedIndex = change.added.indexOf(id)
        let removedIndex = change.removed.indexOf(id)

        if (addedIndex >= 0) {
            change.added.splice(addedIndex, 1)
        } else if (removedIndex >= 0) {
            change.removed.splice(removedIndex)
        } else {
            let membersIndex = members.indexOf(id)
            if (membersIndex >= 0) {
                change.removed.push(id)
            } else {
                change.added.push(id)
            }
        }
        this.setState({
            change: change,
        })
    }

    isChosen(id) {
        if (this.state.change.added.indexOf(id) >= 0) {
            return true
        } else if (this.props.group.members.map(member => member._id).indexOf(id) >= 0) {
            if (this.state.edit) {
                return this.state.change.removed.indexOf(id) < 0
            }
            return true
        }
        return false
    }

    edit() {
        if (this.state.edit) {
            this.props.onMount(this.props.match.params.id)
        }
        this.setState({
            edit: !this.state.edit,
        })
    }

    arraysEqual(a, b) {
        b = b.map(object => object._id)
        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false
        }
        return true
    }

    disabled(bool) {
        if (bool) {
            return {
                background: "#757575",
            }
        } else {
            {
            }
        }
    }

    checkChange() {
        if (this.state.change.added.length > 0 || this.state.change.removed.length > 0) {
            return false
        } else {
            return true
        }
    }

    renderFileUploaderModal() {
        return (
            <div style={styles.del}>
                <div style={styles.delCard}>
                    <h2 style={{ borderBottom: "2px solid grey" }}>Last opp .csv-fil</h2>
                    <h4>Hver gjest (rad) må inneholde minst data i riktig rekkefølge:</h4>
                    <p>- Fornavn</p>
                    <p>- Etternavn</p>
                    <p>- Telefonnummer</p>
                    <p>- Mail</p>
                    <div style={styles.delCont}>
                        <FileUploader
                            groupId={this.props.group._id}
                            onClose={() => this.setState({ showFileUploader: false })}
                        />
                    </div>
                    <div style={styles.delCont}>
                        <button
                            onClick={() => this.setState({ showFileUploader: false })}
                            style={styles.button}
                            className="delete"
                        >
                            Lukk
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    changeValueMessage(key, e) {
        e.preventDefault()
        if (this.state.edit || this.state.addmail || this.state.addmessage) {
            const current = this.state.changeMessage
            current[key] = e.target.value
            this.setState({ changeMessage: current })
        }
    }

    changeComValues(key, value) {
        const current = this.state.changeMessage
        this.set(current, key, value)
        this.setState({ changeMessage: current })
    }

    addmail() {
        this.setState({
            addmail: !this.state.addmail,
        })
    }

    addmessage() {
        this.setState({
            addmessage: !this.state.addmessage,
        })
    }

    add() {
        this.setState({
            add: !this.state.add,
        })
    }

    reciever(rec) {
        this.setState({
            reciever: rec,
        })
    }

    exitModal(type) {
        const current = this.state.changeMessage
        current.email.bodyHTML = ""
        current.email.bodyText = ""
        current.message.message = ""
        current[type] = false
        this.setState(current)
    }

    renderMail() {
        const emailValues = {
            reciever: this.props.group.name,
            subject: this.state.changeMessage.email.subject,
            message: this.state.changeMessage.email.bodyHTML,
            recieverChange: this.changeComValues.bind(this, "reciever"),
            subjectChange: this.changeComValues.bind(this, "email.subject"),
            messageChange: this.changeComValues.bind(this, "email"),
        }
        return (
            <Mail
                subject={emailValues.subject}
                message={emailValues.message}
                subjectChange={emailValues.subjectChange}
                messageChange={emailValues.messageChange}
                edit={true}
            />
        )
    }

    renderSMS() {
        const smsValues = {
            message: this.state.changeMessage.message.message,
            messageChange: this.changeComValues.bind(this, "message.message"),
        }
        return <Message message={smsValues.message} messageChange={smsValues.messageChange} edit={true} />
    }
    changeOk(type) {
        const o = {}
        o[type] = false
        this.setState(o)
    }

    set(obj, path, value) {
        let schema = obj // a moving reference to internal objects within obj
        const pList = path.split(".")
        const len = pList.length
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i]
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem]
        }

        if (typeof value === "object") {
            schema[pList[len - 1]] = Object.assign(schema[pList[len - 1]], value)
        } else {
            schema[pList[len - 1]] = value
        }
    }

    renderGuest() {
        return (
            <div>
                <p>Du sender nå en beskjed til alle medlemmer av gruppen: {this.props.group.name}</p>
                <p>Kontaktperson: {this.props.group.contactperson}</p>
                <p>Telefon: {this.props.group.contactnumber}</p>
            </div>
        )
    }

    renderComModal(openType, title, renderFunc) {
        return (
            <div>
                <Modal title={title} exit={this.exitModal.bind(this, openType)}>
                    {this.renderGuest()}
                    <div>Mottaker:</div>
                    <input
                        placeholder={this.state.addmail ? "kari@nordmann.no" : "+4712345678"}
                        type="text"
                        className="add-inputF"
                        value={this.state.addmail ? this.props.group.name : this.props.group.name}
                        maxlength="60"
                        readOnly={true}
                        required
                    />
                    {renderFunc()}
                    <button
                        onClick={this.proceed}
                        style={{
                            ...styles.button,
                            marginBottom: 84,
                        }}
                        className="save"
                    >
                        Send
                    </button>
                </Modal>
            </div>
        )
    }

    renderComModals() {
        return (
            <div>
                {this.state.addmail ? this.renderComModal("addmail", "Send mail", this.renderMail.bind(this)) : ""}
                {this.state.addmessage ? this.renderComModal("addmessage", "Send SMS", this.renderSMS.bind(this)) : ""}
            </div>
        )
    }

    proceed(e) {
        e.preventDefault()
        var postUrl = ""
        var reciever = ""
        var sendTo = []
        const current = this.props.group.members
        if (this.state.addmail) {
            postUrl = "admin/com"
            for (var i = 0; i < current.length; i++) {
                var info = {}
                info = {
                    email: {
                        send: true,
                        bodyHTML: this.state.changeMessage.email.bodyHTML,
                        receiver: current[i].email,
                        subject: this.state.changeMessage.email.subject,
                        bodyText: this.state.changeMessage.email.bodyText,
                    },
                    message: {
                        send: false,
                        receiver: "j",
                        message: "j",
                    },
                }
                sendTo.push(info)
            }
        } else if (this.state.addmessage) {
            postUrl = "admin/com"
            for (var i = 0; i < current.length; i++) {
                var info = {}
                info = {
                    email: {
                        send: false,
                        bodyHTML: "j",
                        receiver: "j",
                        subject: "j",
                        bodyText: "j",
                    },
                    message: {
                        send: true,
                        receiver: current[i].number,
                        message: this.state.changeMessage.message.message,
                    },
                }
                sendTo.push(info)
            }
        }
        this.setState({ fetching: true })
        const token = window.localStorage.getItem("token")
        const changes = this.state.changeMessage
        delete changes._id
        //sendTo er en liste med alle info-objektene.
        Promise.all(sendTo.map(async post => this.postSingle(post)))
    }

    async postSingle(post) {
        var postUrl = "admin/com"
        const token = window.localStorage.getItem("token")
        const changes = this.state.changeMessage
        delete changes._id
        return axios
            .post(url + postUrl, post, {
                headers: { Authorization: token },
            })
            .then(response => {
                this.setState({ addmail: false, fetching: false })
                this.props.onDelete()
            })
            .catch(error => {
                this.setState({ fetching: false })
            })
            .finally(response => {
                this.setState({ addmessage: false })
                this.props.onDelete()
            })
    }

    renderComs() {
        return (
            <span>
                <button onClick={this.addmail} style={{ ...styles.button, ...{ marginLeft: "0px" } }} className="save">
                    {" "}
                    Send Mail{" "}
                </button>
                <button
                    onClick={this.addmessage}
                    style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                    className="save"
                >
                    {" "}
                    Send SMS{" "}
                </button>
                <div>{this.renderComModals()}</div>
            </span>
        )
    }

    render() {
        return (
            <div>
                {this.state.showFileUploader ? this.renderFileUploaderModal() : null}
                <div className="fixed gap">
                    <h1 className="title">{"Medlemmer i " + this.props.group.name}</h1>
                </div>
                {this.status()}
                <input
                    placeholder="Søk"
                    onChange={this.handleSearchChange}
                    type="text"
                    className="add-input"
                    value={this.state.search}
                />
                <div>
                    {this.props.access && this.props.access.groups.write ? (
                        <button
                            onClick={this.edit}
                            style={{ ...styles.button, ...{ marginLeft: "0px" } }}
                            className={this.state.edit ? "delete" : "change"}
                        >
                            {this.state.edit ? "Avbryt" : "Endre"}
                        </button>
                    ) : (
                        ""
                    )}

                    {!this.state.edit ? (
                        <button
                            onClick={() => this.props.back(this.props.match.params.id)}
                            style={styles.button}
                            className="save"
                        >
                            Tilbake
                        </button>
                    ) : (
                        <button
                            disabled={this.checkChange()}
                            onClick={this.patchChanges}
                            style={{ ...styles.button, ...this.disabled(this.checkChange()) }}
                            className="save"
                        >
                            Lagre
                        </button>
                    )}
                    <span>
                        {this.props.access && this.props.access.groups.write ? (
                            <button
                                onClick={() => this.setState({ showFileUploader: true })}
                                style={styles.button}
                                className="save"
                            >
                                Fra fil...
                            </button>
                        ) : (
                            ""
                        )}
                        {this.renderComs()}
                    </span>

                    {this.state.edit ? this.props.showing + " av " + this.props.total : null}
                </div>

                {this.props.loading || !this.props.group.members ? null : this.renderTable()}

                {this.state.edit ? (
                    <div>
                        <button
                            disabled={parseInt(this.props.page) === 0}
                            onClick={this.prev}
                            style={{
                                ...styles.button,
                                ...{ marginLeft: "0px" },
                                ...this.disabled(parseInt(this.props.page, 10) <= 0),
                            }}
                            className="save"
                        >
                            Forrige
                        </button>
                        <button
                            disabled={this.props.page + 1 >= this.props.pages}
                            onClick={this.next}
                            style={{
                                ...styles.button,
                                ...this.disabled(parseInt(this.props.page + 1) >= parseInt(this.props.pages)),
                            }}
                            className="save"
                        >
                            Neste
                        </button>
                    </div>
                ) : null}
            </div>
        )
    }
}

const styles = {
    button: {
        width: "120px",
        marginBottom: "20px",
        marginTop: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "18px",
        borderRadius: "8px",
    },
    del: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    delCard: {
        flex: 1,
        width: "90%",
        backgroundColor: "white",
        maxHeight: "90vh",
        borderRadius: 8,
        padding: 8,
        alignItems: "center",
        overflow: "auto",
    },
    delCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
    addNew: {
        title: {
            textAlign: "center",
        },
        form: {
            display: "flex",
            flexDirection: "column",
        },
        input: {
            borderRadius: 4,
        },
        sideBySide: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        selectorInput: {
            button: {
                flex: 1,
            },
        },
    },
}

const mapStateToProps = state => {
    return {
        group: state.groups.info,
        guests: state.guests.data,
        error: state.groups.error.active || state.guests.error.active,
        access: state.login.user.info.permissions,
        loading: state.groups.isFetching && state.guests.isFetching,
        message: state.groups.error.message,
        page: state.guests.page,
        showing: state.guests.showing,
        total: state.guests.total,
        pages: state.guests.pages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMount: id => {
            dispatch(groupsActions.getGroup(id))
            dispatch(guestsActions.getGuests("", 0))
        },
        onMemberClick: id => {
            dispatch(push(`/admin/guests/${id}`))
        },
        get: (search, page) => {
            dispatch(guestsActions.getGuests(search, page))
        },
        back: id => {
            dispatch(push(`/admin/groups/${id}`))
        },
        onDelete: id => {
            dispatch(push(`/admin/groups/${id}/members`))
        },
    }
}

const MembersContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Members)

export default MembersContainer
