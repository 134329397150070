import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/nb';
import ListItem from './ListItem'
import { location } from '../../constants.js'
import ButtonSelectAll from './ButtonSelectAll'
import ConnectSearch from '../../store/containers/searchbar.js'
moment.locale('nb')

const List = props => (
  <ul>
    {props.children}
  </ul>
)

export default List;

// export default class List extends Component {
//   render() {
//     const listName = location.items.map(item => '/admin' + item.path === window.location.pathname ? item.name : '').join('')
//     const { children, items } = this.props
//     return(
//       <div id="list">
//         <ConnectSearch />
//         {items ? <ButtonSelectAll /> : null}
//         {children ? children.map(item => item) : null}
//         <ul>{
//           items ? items.map(
//             item => <ListItem onClick={this.props.onClick} item={item} />
//           ) :
//           <li className="empty"><hr></hr>Listen{<span id="listName"> {listName}</span>} er tom.</li>
//         }</ul>
//       </div>
//     )
//   }
// }
