import React, { Component } from "react";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

export default class Message extends Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }

  messageChange(text) {
    this.props.messageChange(text);
  }

  componentDidMount() {
    this.editor = new Quill(this.editor.current, {
      modules: { toolbar: null },
      theme: "snow"
    });
    this.editor.root.innerHTML = this.props.message;

    this.editor.on(
      "text-change",
      ((delta, oldDelta, source) => {
        this.messageChange(this.editor.getText(0));
      }).bind(this)
    );
  }

  componentDidUpdate(oldProps, oldState) {
    this.editor.enable(this.props.edit);
    if (this.props.message !== oldProps.message && !this.props.edit) {
      this.editor.root.innerHTML = this.props.message;
    }
  }

  render() {
    return (
      <div>
        <p>Meldingstekst:</p>
        <div ref={this.editor} />
      </div>
    );
  }
}
