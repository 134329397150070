import React, { Component } from 'react';
import './styles/switch.css'

export default class Switch extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.click();
  }

  disabled() {
    return (this.props.disabled ? {backgroundColor: '#ccc'} : {})
  }

  render() {
    return(
      <label className="switch" onClick={this.handleClick}>
        <input type="checkbox" checked={this.props.checked} />
        <span className="slider" style={this.disabled()}></span>
      </label>
    )
  }
}