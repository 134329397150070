export const location = {
    items: [
        {
            name: "Forside",
            icon: "format_list_bulleted",
            path: "/main",
            perm: "frontpage",
        },
        {
            name: "Bordreservasjoner",
            icon: "insert_invitation",
            path: "/tableorder",
            perm: "reservations",
        },
        {
            name: "Gjesteliste",
            icon: "content_paste",
            path: "/guestlist",
            perm: "guestlist",
        },
        {
            name: "Gjester",
            icon: "people",
            path: "/guests",
            perm: "guests",
        },
        {
            name: "Mail & SMS",
            icon: "content_paste",
            path: "/communication",
            perm: "reservations",
        },
        {
            name: "Grupper",
            icon: "group_add",
            path: "/groups",
            perm: "groups",
        },
        {
            name: "Fordeler",
            icon: "content_paste",
            path: "/benefits",
            perm: "benefits",
        },
        {
            name: "Ansatte",
            icon: "domain",
            path: "/employees",
            perm: "employees",
        },
        {
            name: "Statistikk",
            icon: "statistikk",
            path: "/statistics",
            perm: "statistics",
        },
        {
            name: "Innstillinger",
            icon: "settings",
            path: "/settings",
            perm: "settings",
        },
    ],
}
