import initialState from "../initialState.js";

const settings = (state = initialState.settings, action) => {
  switch (action.type) {
    case "REQUEST_SETTINGS":
      return Object.assign({}, state, {
        isFetching: true,
        error: false
      });
    case "REQUEST_SETTINGS_SUCCESS":
      return Object.assign({}, state, {
        info: action.settings,
        isFetching: false
      });
    case "REQUEST_SETTINGS_FAILURE":
      return Object.assign({}, state, {
        error: {
          active: true,
          message: action.message
        },
        isFetching: false
      });
    default:
      return state;
  }
};

export default settings;
