import axios from "axios";
import { push } from "react-router-redux";
import url from "../../url.js";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const REQUEST_TOKEN = "REQUEST_TOKEN";
export const REQUEST_TOKEN_SUCCESS = "REQUEST_TOKEN_SUCCESS";
export const REQUEST_TOKEN_FAILURE = "REQUEST_TOKEN_FAILURE";

export const logOut = () => {
  return {
    type: "LOG_OUT"
  };
};

export const changePassword = password => {
  return {
    type: "CHANGE_PASSWORD",
    password
  };
};

export const changeUsername = username => {
  return {
    type: "CHANGE_USERNAME",
    username
  };
};

const requestTokenFailure = message => {
  return {
    type: "REQUEST_TOKEN_FAILURE",
    message
  };
};

const requestTokenSuccess = token => {
  return {
    type: "REQUEST_TOKEN_SUCCESS",
    token
  };
};

const requestToken = () => {
  return {
    type: "REQUEST_TOKEN"
  };
};

const fetchToken = (username, password) => {
  return dispatch => {
    dispatch(requestToken());
    return axios
      .post(url + "admin/employees/auth", {
        username,
        password
      })
      .then(response => {
        dispatch(requestTokenSuccess(`Bearer ${response.data.token}`));
        window.localStorage.setItem("token", `Bearer ${response.data.token}`);
        dispatch(getInfo());
      })
      .catch(error => {
        console.log(error);
        dispatch(requestTokenFailure(error.response.data.error));
      });
  };
};

export const getToken = () => {
  return (dispatch, getState) => {
    return dispatch(
      fetchToken(getState().login.username, getState().login.password)
    );
  };
};

const requestInfoFailure = message => {
  return {
    type: "REQUEST_INFO_FAILURE",
    message
  };
};

const requestInfoSuccess = info => {
  return {
    type: "REQUEST_INFO_SUCCESS",
    info
  };
};

const requestInfo = () => {
  return {
    type: "REQUEST_INFO"
  };
};

const fetchInfo = (token, path) => {
  return dispatch => {
    dispatch(requestInfo());
    return axios
      .get(url + "admin/employees/me", {
        headers: { Authorization: token }
      })
      .then(response => {
        dispatch(push(path));
        dispatch(requestInfoSuccess(response.data));
      })
      .catch(error => {
        dispatch(requestInfoFailure(error.response.data));
      });
  };
};

export const getInfo = (path = "/admin/main") => {
  return (dispatch, getState) => {
    return dispatch(fetchInfo(window.localStorage.getItem("token"), path));
  };
};
