import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { push } from 'react-router-redux';
import url from '../../../url.js';
import * as actions from '../../../store/actions/products.js';
import Switch from '../Switch.js';
import './Product.css';


const permissions = [
  {
    key: 'prouductslist',
    name: 'Produktliste'
  },
  {
    key: 'products',
    name: 'Produkter',
  }
];



class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      delete: false,
      change: {},
      patch: false,
      error: false,
      fetching: false,
    };
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);;
    this.patchChanges = this.patchChanges.bind(this);
    this.deleteChanges = this.deleteChanges.bind(this);
    this.resizedataURL = this.resizedataURL.bind(this);
    this.encodeImageFileAsURL = this.encodeImageFileAsURL.bind(this);
  }

  componentDidMount() {
    this.props.onMount(this.props.match.params.id);
  }

  edit(e) {
    e.preventDefault();
    const props = JSON.parse(JSON.stringify(this.props.product));
    delete props.mvaPrice;
    this.setState({ change: props, edit: !this.state.edit })
  }

  delete(e) {
    e.preventDefault();
    const props = JSON.parse(JSON.stringify(this.props.product));
    this.setState({ change: props, delete: true })
  }

  getValue(key) {
    if (this.state.edit) {
      return this.state.change[key];
    }
    return this.props.product[key];
  }




  click(key) {
    if(this.state.edit) {
      const current = this.state.change;
      current[key] = !current[key];
      this.setState({change: current});
    }
  }

  changeValue(key, e) {
    e.preventDefault();
    if(this.state.edit) {
      const current = this.state.change;
      current[key] = e.target.value;
      if(key === 'taxRate' || key === 'price') {
        current[key] = parseInt(current[key], 10);
      }
      this.setState({change: current});
    }
  }

  patchChanges(e) {
    e.preventDefault();
    console.log(this.props);
    this.setState({fetching: true});
    const token = window.localStorage.getItem('token');
    const changes = this.state.change;
    delete changes._id;
    axios.patch(url + 'admin/products/' + this.props.product._id, changes, {
      headers: {Authorization: token},
    }).then(response => {
      this.setState({ patch: true, fetching: false, edit: false });
      this.props.onMount(this.props.match.params.id)
    }
      )
      .catch(error => {
        this.setState({error: true, fetching: false })
      })
  }



  deleteChanges(e) {
    e.preventDefault();
    this.setState({fetching: true});
    const token = window.localStorage.getItem('token');
    const changes = this.state.change;
    delete changes._id;
    axios.delete(url + 'admin/products/' + this.props.product._id, {
      headers: {Authorization: token},
    }).then(response => {
      this.props.onDelete();
    }
      )
      .catch(error => {
        this.setState({error: true, fetching: false, delete: false })
      })
  }

  disabled(bool) {
      if(bool) {
          return {
              background: '#757575',
          }
      } else {
          {}
      }
  }


  checkChange() {
    if(this.state.change.name !== this.props.product.name ||
     this.state.change.description !== this.props.product.description ||
     this.state.change.price !== this.props.product.price ||
   this.state.change.taxRate !== this.props.product.taxRate ||
   this.state.change.picture !== this.props.product.picture ||
 this.state.change.requiresReservation !== this.props.product.requiresReservation) {
      return false;
    } else {
      return true;
    }
  }



  renderInfo() {
    return (
      <div>
        <form>
          <div className="lineunder">
          <h3>Produktnavn</h3>
          <textarea type="text" className={this.state.edit? "add-inputText" : "showInputText"} onChange={this.changeValue.bind(this, 'name')} value={this.getValue('name')} readOnly={!this.state.edit} required/>
          </div>
          <div className="lineunder">
          <h3>Produktbeskrivelse</h3>
          <textarea type="text" className={this.state.edit ? "add-inputText": "showInputText"} onChange={this.changeValue.bind(this, 'description')} value={this.getValue('description')} readOnly={!this.state.edit} required/>
          </div>
          <div className="lineunder">
          <h3>Skattesats (Taxrate)</h3>
          <input type="number" className={this.state.edit ? "add-input" : "showInputN"} onChange={this.changeValue.bind(this, 'taxRate')} value={this.getValue('taxRate')} readOnly={!this.state.edit} required/>
          </div>

          <div className="lineunder">
            <h3>Pris</h3>
            <input type="number" className={this.state.edit ? "add-input" : "showInputN"} onChange={this.changeValue.bind(this, 'price')} value={this.getValue('price')} readOnly={!this.state.edit} required/>
          </div>
          <div className="lineunder">

          <div className="switchdiv">
          <h3>Krever reservasjon?</h3>
          <Switch disabled={!this.state.edit} click={this.click.bind(this,'requiresReservation')} checked={this.getValue('requiresReservation')} />
          </div>
          </div>

          <div className="lineunder">
            <h3>Bilde</h3>
            <img className="picture" src={this.getValue('picture')} alt="avatar"/>
            { !this.state.edit ? '' : <input onChange={(e) => (this.encodeImageFileAsURL(e)) } type="file" className="add-input"  accept="image/*" />}
          </div>
        </form>
      </div>
    )
  }

  smaller(picture, wantedWidth, wantedHeight, img) {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;
      console.log(picture);
      // We resize the image with the canvas method drawImage();
      ctx.drawImage(picture, 0, 0, wantedWidth, wantedHeight);

      var dataURI = canvas.toDataURL("image/jpeg", 0.5);
      console.log(dataURI);
      /////////////////////////////////////////
      // Use and treat your Data URI here !! //
      /////////////////////////////////////////
      /*
      this.parent.setState({
          imagePreviewUrl: dataURI,
          image: dataURI,
      });
      */
      if(this.state.edit) {
        const current = this.state.change;
        current["picture"] = dataURI;
        this.setState({change: current});
      }
  }

  resizedataURL(datas, wantedWidth, wantedHeight) {
        // We create an image to receive the Data URI
        var img = document.createElement('img');

        img.parent = this;
        // When the event "onload" is triggered we can resize the image.
        img.onload = this.smaller.bind(this, img, wantedWidth, wantedHeight);

        // We put the Data URI in the image's src attribute
        img.src = datas;
    }

  encodeImageFileAsURL(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
        this.resizedataURL(reader.result, 350, 350);
    };
    reader.readAsDataURL(file);
}

  status() {
    if(this.state.fetching) {
      return (<p>Oppdaterer...</p>)
    } else if(this.state.error) {
      return (<p style={{color: 'red'}}>Error</p>)
    } else if(this.state.patch) {
      return (<p style={{color: 'green'}}>Oppdatert</p>)
    } else {
      return (<p>&nbsp;</p>)
    }
  }

  deleteModal() {
    if(this.state.delete) {
      return (
        <div style={styles.del}>
          <div style={styles.delCard}>
            <h2>Sikker på at du vil slette {this.props.product.name}?</h2>
            <div style={styles.delCont}>
              <button onClick={(e) =>{ e.preventDefault(); this.setState({delete: false})} } style={styles.button} className="change">Avbryt</button>
              <button onClick={this.deleteChanges} style={styles.button} className="delete">Fullfør</button>
            </div>
          </div>
        </div>
      )
    }
    return '';

  }


  render() {
    console.log(this.state);
    console.log(this.props);
    return (
      <div>
      {this.deleteModal()}
      <div>
      <div className="fixed gap">
        <h1 className="title" marginLeft="10px" >{this.props.product.name}</h1>
      </div>
      {this.status()} {this.props.access && this.props.access.products.write ?
      (<div className="gap">
      
      <button onClick={this.edit} style={{...styles.button, ...{marginLeft: '0px'}}} className={this.state.edit ? 'delete' : 'change'}>{this.state.edit ?'Avbryt' : 'Endre'}</button>
        {this.state.edit ? '': <button style={styles.button} onClick={this.delete} className="delete">Slette</button>}
        {!this.state.edit ? '': <button disabled={this.checkChange()} onClick={this.patchChanges} style={{...styles.button, ...this.disabled(this.checkChange())}} className="save">Lagre</button>}
    </div>) :("")}
      <div className="gap">
        {this.renderInfo()}
        {!this.state.edit ? '': <button disabled={this.checkChange()} onClick={this.patchChanges} style={{...styles.button, ...this.disabled(this.checkChange())}} className="save">Lagre</button>}

      </div>
      </div>
      </div>
    )
  }
}

const styles = {
  button: {
    width: '160px',
    marginBottom: '20px',
    marginRight: '10px',
    marginLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '18px',
    borderRadius: '8px',
  },
  del: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000070',
    top: 0,
    left: 0,
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  delCard: {
    flex: '',
    height: '200px',
    width: '90%',
    maxWidth: '400px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '5px 5px 5px 5px',
    textAlign: 'center',
  },
  delCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%'
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.products.info,
    error: state.products.error.active,
    access: state.login.user.info.permissions,
    loading: state.products.isFetching,
    message: state.products.error.message,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    onMount: (id)=> {
      dispatch(actions.getProduct(id));
    },
    onDelete: () => {
      dispatch(push('/admin/products'));
    }
  }
}

const ProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Product)


export default ProductContainer;
