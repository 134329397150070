import React, { Component } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import API_URL from "../../../url"
import axios from "axios";

class Chart extends Component {

    render () {
        return (
            <div id={"chartContainer"}>
                <ResponsiveContainer
                    fill="#becae0"
                    width="100%"
                >
                    <LineChart
                        data={this.props.data}
                               margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                >
                        <CartesianGrid strokeDasharray="3 3" fill="#d0dbef"/>
                        <XAxis dataKey="name" label={{ value: 'Tid', angle: 0, position: 'bottom' }} />
                        <YAxis label={{ value: 'Antall', angle: -90, position: "left" }}/>
                        <Tooltip/>
                        <Line type="monotone" dataKey="value" stroke="#1565C0" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default Chart;





